import React, { useEffect, useState, useRef, useContext } from 'react'
import './css/RegistrationPage.css'
import * as typeformEmbed from '@typeform/embed'
import CourseButton from '../CourseButton/CourseButton'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import { useStudentDataContext } from '../Context/Context'

export default function RegistrationPage () {
  const { userStore: { user: { studentId } = {} } = {} } =
  useContext(Auth0Context) || {}
  const { areAllCoursesProfCert } = useStudentDataContext()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isError, setIsError] = useState(false)
  const formRef = useRef(null)
  const footerRef = useRef(null)

  const formId = areAllCoursesProfCert ? 'YXTSsk6L' : 'M0uKKFR4'

  useEffect(() => {
    if (isFormSubmitted && footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isFormSubmitted])

  useEffect(() => {
    if (!formRef.current) return
    const embedForm = async () => {
      if (!studentId) setIsError(true)
      if (studentId) {
        const encodedStudentId = encodeURIComponent(studentId)
        typeformEmbed.makeWidget(
          formRef.current,
          `https://form.typeform.com/to/${formId}?student_id=${encodedStudentId}`,
          {
            transferableUrlParameters: ['student_id'],
            onSubmit: () => {
              setIsFormSubmitted(true)
            }
          }
        )
      }
    }

    embedForm()
  }, [formRef, studentId, formId])

  const handleOnNextClick = () => window.location.reload()

  if (isError) {
    return <p className='typeform-error'> The form didn't load correctly.
    Please refresh the page and try again.
    If the problem persists, reach out to us at <a href='mailto:success@outlier.org'> success@outlier.org.</a> </p>
  }

  return (
    <>
      <h2 className='registration-page__header'>Registration</h2>
      <div id='embedded-typeform' ref={formRef} />
      {
        isFormSubmitted && (
          <div id='footer' ref={footerRef}>
            <div id='footer-content'>
              <p id='footer-text'>
                Move onto the next form to continue with your Outlier onboarding.
              </p>
              <CourseButton
                className={{ 'btn-primary': true }}
                style={{ minWidth: '119px', lineHeight: '22px' }}
                onClick={handleOnNextClick}
              >
                Next
              </CourseButton>
            </div>
          </div>
        )
      }
    </>
  )
}
