import React, { createContext, useContext, useMemo, useEffect, useState } from 'react'
import {
  useAuthStore,
  useUserStore
} from '@outlier-org/lst-auth-react'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'

export const AuthUserDataContext = createContext()

export const AuthUserDataContextProvider = ({ children }) => {
  const userStore = useUserStore((state) => state)
  const authStore = useAuthStore((state) => state)

  const { isLoading } = authStore

  const [loading, setLoading] = useState(true) // Local loading state

  // Wait for loading to be false before rendering the children
  useEffect(() => {
    if (!isLoading) {
      setLoading(false)
    }
  }, [isLoading])

  const combinedValues = useMemo(
    () => ({
      userStore,
      authStore
    }),
    [userStore, authStore]
  )

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <AuthUserDataContext.Provider value={combinedValues}>
      {children}
    </AuthUserDataContext.Provider>
  )
}

export const useAuthUserDataContext = () => useContext(AuthUserDataContext)
