import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import App from './App'
import api from './api'

import NavigationComponent from './Components/NavigationComponent/NavigationComponent'
import HistoryManager, { history } from './Components/HistoryManager/HistoryManager'
import TooltipTextModalContainer from './Components/TooltipTextModalContainer/TooltipTextModalContainer'
import {
  CombinedContextProviderWrapper
} from './Components/Context/ContextProvider'
import PageLayout from './Components/PageLayout/PageLayout'
import ErrorBoundary from './Components/ErrorBoundary'
import { publicRoutes } from './utilities'
import { AuthProvider, getRumbaSsoServiceUrl } from '@outlier-org/lst-auth-react'
import { HashRouter as Router } from 'react-router-dom'
import AppGuard from './Components/AppGuard/AppGuard'
import config from './config'

(function () {
  const link = document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = process.env.PUBLIC_URL + 'images/icons/favicon.jpg'
  document.getElementsByTagName('head')[0].appendChild(link)
})()

const {
  REACT_APP_ID: appId,
  REACT_APP_SSO_AUTH_SAPI_BASE_URL: authGatewayBaseUrl,
  REACT_APP_SSO_AUTH_CONTEXT_ID: authContextId,
  REACT_APP_SSO_AUTH_GATEWAY_CLIENT_ID: authGatewayClientId,
  REACT_APP_SSO_BASE_URL: ssoBaseUrl,
  REACT_APP_SSO_RBS_TOKEN_GRANT_TYPE: rbsTokenGrantType
} = process.env

const isPreviewCourse = config && config.isPreviewCourse
export const lstAuthConfig = {
  appId: appId || 'calculus-static',
  authGatewayBaseUrl,
  authContextId, // Unique Id - from dashboard
  authGatewayClientId, // Need to check with devops
  useUserRoleAsRbsScope: true,
  ssoBaseUrl,
  rbsTokenGrantType,
  authCallbackRoute: '/',
  includeLicensedProducts: false,
  includePolicy: true,
  redirectToCustomError: true,
  serviceUrl: getRumbaSsoServiceUrl(),
  publicRoutes,
  onPostAuth: api.handlePostAuthCallback,
  noAuth: isPreviewCourse
}

ReactDOM.render(
  <ErrorBoundary>
    <Router history={history}>
      <AuthProvider initialConfig={lstAuthConfig}>
        <AppGuard>
          <CombinedContextProviderWrapper>
            <TooltipTextModalContainer>
              <HistoryManager>
                <PageLayout>
                  <NavigationComponent>
                    <App />
                  </NavigationComponent>
                </PageLayout>
              </HistoryManager>
            </TooltipTextModalContainer>
          </CombinedContextProviderWrapper>
        </AppGuard>
      </AuthProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
)

try {
  serviceWorker.unregister()
} catch (e) {
  console.error(`Error unregistering service worker: ${e}`)
}
