// Outlier Base Domain
export const OUTLIER_BASE_DOMAIN = process.env.REACT_APP_OUTLIER_BASE_DOMAIN || 'outlier.org'

// Outlier API URL
export const OUTLIER_API_BASE_URL = process.env.REACT_APP_OUTLIER_API_BASE_URL || 'https://api.outlier.org'

// Outlier Webapps URLs
export const OUTLIER_STUDENT_DASHBOARD_URL = process.env.REACT_APP_OUTLIER_STUDENT_DASHBOARD_URL || 'https://dashboard.outlier.org'
export const OUTLIER_ADMIN_URL = process.env.REACT_APP_OUTLIER_ADMIN_URL || 'https://admin.outlier.org'
export const OUTLIER_PARTNERS_URL = process.env.REACT_APP_OUTLIER_PARTNERS_URL || 'https://partners.outlier.org'

// Outlier Marketing Website URLs
export const MARKETING_HOMEPAGE_URL = process.env.REACT_APP_MARKETING_HOMEPAGE_URL || 'https://www.outlier.org'
export const TERMS_URL = process.env.REACT_APP_TERMS_URL || `${MARKETING_HOMEPAGE_URL}/pages/terms`
export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_URL || `${MARKETING_HOMEPAGE_URL}/pages/privacy`
