import React, { Component } from 'react'
import config from '../../config'
import { AuthUserDataContext } from '../Context/AuthUserDataContext'
import Context from '../Context/Context'
import { postMessage } from '../../utilities/tabsCommunication'
import { isAuthRequired } from '../../utilities'
import './css/StudentHeaderComponent.css'
import {
  ChecklistIcon,
  EmailContainer,
  Header,
  HeaderPlacementContainer,
  Nav
} from './style'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO, ON_TRACK_STUDENT_EVENT } from '../../Constants/emitterKeys'
import { skooliTutoringCourses } from '../../Constants/courseToolkit'
import UpdatedTermsNotificationBanner from './UpdatedTermsNotificationBanner'
import NeedsReviewBanner from '../NeedsReviewBanner/NeedsReviewBanner'
import DropDownMenuComponent from './DropDownMenuComponent'
import Notifications from './Notifications/index'
import NotificationsTool from '../Announcements/NotificationsTool'
import {
  getYellowdigUrl,
  getIsStudentWithdrawn,
  getIsVIPCohort,
  getPartnerName,
  getYellowdigCommunityLink,
  getValidDiscussionLinkType, isWelcomePsychologyCourse,
  isGGUCourse
} from '../../utilities/courseUtils'

import {
  CONTACT_STUDENT_SUCCESS_LINK,
  GGU_CONTACT_LINK,
  YELLOWDIG
} from '../../Constants'
import { OUTLIER_STUDENT_DASHBOARD_URL } from '../../Constants/domains'
import Checklist from '../Checklist/Checklist'
import { QUIZ } from '../../Constants/sectionType'
import api from '../../api'
import { getSkooliTutoringTexts } from '../../utilities/courseToolkit'
import DegreeRegistrationBanner from '../DegreeRegistrationBanner/DegreeRegistrationBanner'
import { isStudentAllowedToViewDiscussionLink } from '../../utilities/studentPermissionUtils'
import { ONCE } from '../../Constants/frequency'
import { SKOOLI_AUDIT_STUDENTS } from '../../Constants/eventTypes'
import { isGuardianPermissionPath } from '../GuardianPermission/GuardianPermission.utils'
import ServerSwitch from '../ServerSwitch/ServerSwitch'
import { getExternalLinkUrl, redirectUserToExternalLink } from '@outlier-org/lst-auth-react'

const handleSkooliTutoringLink = async ({
  cohortID, attemptId, studentId, isAuditor
}) => {
  const { data, error } = await api.getSkooliUrl(cohortID)
  if (error || !data) return

  if (isAuditor) {
    emitter.emit(
      ON_TRACK_STUDENT_EVENT,
      {
        event: SKOOLI_AUDIT_STUDENTS,
        properties: {
          attempt: attemptId,
          studentId: studentId
        },
        frequency: ONCE
      }
    )
  }

  window.open(data.launchUrl)
}

class StudentHeaderComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      prevScroll: window.pageYOffset,
      yellowdigUrl: '',
      showCheckList: false,
      showHeader: true
    }
    const { courseContext: { studentCourses } } = this.props
    this.discussionLink = studentCourses?.length
      ? getValidDiscussionLinkType(studentCourses) : null
  }

  handleScroll = () => {
    const { prevScroll } = this.state
    const currentScroll = window.pageYOffset
    if (prevScroll < 0 || currentScroll < 0) return
    const showHeader = prevScroll >= currentScroll

    this.setState({
      prevScroll: currentScroll,
      showHeader
    })
  }

  async componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
    const {
      courseContext: { cohortData }
    } = this.props || {}

    if (this.discussionLink !== YELLOWDIG) return

    const yellowdigUrl = await getYellowdigUrl(cohortData)
    this.setState({ yellowdigUrl })
  }

  resetConvertFlowUser = () => {
    localStorage.removeItem('cf_person')
    localStorage.removeItem('cf_28553_person')
    if (!window.convertflow) return

    window.convertflow.app.helpers.deleteCookie('cf_28553_id')
    for (const cta of Object.values(window.convertflow.app.website.ctas)) {
      if (cta.cta_type !== 'inline' && cta.cta_type !== 'page') {
        window.convertflow.app.helpers.deleteCookie('viewedCta' + cta.id)
      }
    }
  }

  componentDidUpdate () {
    const {
      courseContext: { openChecklist, updateContext }
    } = this.props || {}
    if (!openChecklist) return

    this.toggleCheckList(true)
    updateContext({ openChecklist: false })
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  userLogout (e) {
    const { authContext } = this.props
    const { authStore } = authContext
    const { logout } = authStore || {}

    this.resetConvertFlowUser()
    localStorage.removeItem('isMobileNotificationEnabled')
    postMessage()

    if (logout) {
      logout({
        returnTo: window.location.origin
      })
    }
  }

  redirectionHandler (href = '/') {
    const externalLink = href.includes(`${OUTLIER_STUDENT_DASHBOARD_URL}`)
      ? getExternalLinkUrl(href) : href

    if (isWelcomePsychologyCourse()) {
      redirectUserToExternalLink(`${OUTLIER_STUDENT_DASHBOARD_URL}/#/`)
      return
    }
    const { currentQuestionSet } = this.props.courseContext
    const isHome = externalLink === '/'

    if (!currentQuestionSet) {
      window.location.href = externalLink
      return
    }
    const { quest_type: questionType } = currentQuestionSet
    const isQuiz = questionType === QUIZ
    if (!isQuiz) {
      window.location.href = externalLink
      return
    }
    const isFlaggedQuiz = isQuiz
    // Navigate to '/' to initiate question wrapper unmount
    isFlaggedQuiz && emitter.emit(ON_NAVIGATE_TO, '/')
    isFlaggedQuiz && setTimeout(() => {
      const hashLength = window.location.hash.split('/').length
      if (hashLength < 3) {
        window.location.href = externalLink
        isHome && window.location.reload(true)
      }
    }, 100)
  }

  toggleCheckList = (flag) => {
    const { showCheckList } = this.state
    this.setState({
      showCheckList: flag === undefined ? !showCheckList : flag
    })
  }

  getCourseDiscussionUrl = () => {
    const { isCollegeSuccessCourse } = config

    const isCollegeSuccess = isCollegeSuccessCourse()
    if (isCollegeSuccess) return 'https://discord.gg/eTTBdaUkP7'

    return ''
  }

  renderMenu () {
    const {
      courseContext: {
        isStudioCohort,
        isActiveGGUStudent
      }
    } = this.props

    return <>
      <MenuItem
        className='student-dashboard-item'
        label='Student Dashboard'
        onClick={
          () => this.redirectionHandler(`${OUTLIER_STUDENT_DASHBOARD_URL}/#/`)
        }
      />
      <MenuItem
        className='account-item'
        label='Account'
        onClick={
          () => this.redirectionHandler(`${OUTLIER_STUDENT_DASHBOARD_URL}/#/account`)
        }
      />
      {!isStudioCohort && <MenuItem
        label='Contact'
        href={isActiveGGUStudent ? GGU_CONTACT_LINK : CONTACT_STUDENT_SUCCESS_LINK}
        target='_blank'
        rel='noreferrer noopener'
      />}
      <MenuItem label='Logout'
        onClick={e => this.userLogout(e)}
      />
    </>
  }

  render () {
    const {
      courseName,
      course,
      getLogoInfo,
      hasNotificationFlag,
      isPreviewCourse,
      isCollegeSuccessCourse,
      isPwa,
      isContentCourse
    } = config
    const { activePath, authContext, courseContext } = this.props
    const { userStore } = authContext || {}
    const { showHeader, showCheckList } = this.state
    const { user, studentId } = userStore || {}
    const {
      isAuditor,
      cohortData,
      latestCohort,
      isStudioCohort,
      isAssessmentQuizExam,
      isALRedesign,
      pwaOnboard,
      isAdmin,
      cohortData: {
        studentStatus,
        name,
        startDate,
        cohortID,
        courseDisplayName,
        isGGUCohort,
        creditGrantingInstitution
      }
    } = courseContext

    // eslint-disable-next-line
    const isGGU1_11_23Cohort = isGGUCohort && startDate === config.ggu1_11_23StartDate

    const isCollegeSuccess = isCollegeSuccessCourse()
    const courseDiscussionUrl = this.getCourseDiscussionUrl()
    const logoInfo = getLogoInfo({ courseName, isStudioCohort })
    const isAdminAndVipCohort = isAdmin && getIsVIPCohort(name)

    const isPreviewOrCollegeSuccessPwa = isCollegeSuccess && isPwa
      ? pwaOnboard === true
      : !isPreviewCourse && !isContentCourse

    const hasPermissionToViewDiscussionLink = isStudentAllowedToViewDiscussionLink(
      studentId
    )

    // Determines if the current course is a non-GGU course and "removeDiscussionLink" flag is enabled.
    const isCurrentCourseNonGGU = !(isGGUCourse() && isGGUCohort) && config.isHideDiscussionLinkFlag()

    const showHamburgerMenu = isAuthRequired(activePath) &&
      isPreviewOrCollegeSuccessPwa
    const isGuardianPermissionForm = isGuardianPermissionPath(activePath)
    const isStudentWithdrawnOrVipCohort =
      getIsStudentWithdrawn(studentStatus) || getIsVIPCohort(name)
    const shouldShowDiscussonLink = this.discussionLink &&
      !isStudioCohort && !isStudentWithdrawnOrVipCohort &&
      !isAdminAndVipCohort && hasPermissionToViewDiscussionLink &&
      !isCurrentCourseNonGGU

    const partnerName = getPartnerName(latestCohort)
    const yellowdigCommunityLink = getYellowdigCommunityLink(latestCohort)
    const shouldShowPartnerCommunityLink = !!yellowdigCommunityLink &&
      hasPermissionToViewDiscussionLink

    const useSkooliTutoringForCourse = config.shouldUseSkooliTutoringUsernsap({
      courseName: config.courseName,
      isGGUCohort,
      cohortStartDate: startDate,
      creditGrantingInstitution
    })

    const { isOutlierEngineer } = config
    const shouldShowSkooliTutoringLink = skooliTutoringCourses.includes(config.courseName) &&
      startDate >= config.skooliUpdateDate && useSkooliTutoringForCourse

    const showServerSwitch = isOutlierEngineer(user?.email) || localStorage.getItem('showServerSwitch') === 'true'

    return (
      <>
        <Header
          showHeader={showHeader}
          isAssessment={isAssessmentQuizExam}
          isALRedesign={isALRedesign}
        >
          <Nav
            className='container navbar navbar-expand navbar-dark justify-content-between align-items-center'
          >
            {/* eslint-disable-next-line */}
            <a className='navbar-brand d-flex' data-testid='outlier-logo'>
              <img
                src={logoInfo.logoSrc}
                className={logoInfo?.className || ''}
                alt='Logo'
                onClick={() => this.redirectionHandler()}
              />
            </a>
            {!isGuardianPermissionForm && <div
              className='ml-auto d-flex align-items-center'
              id='navbarSupportedContent'
            >
              {showServerSwitch && (
                <ServerSwitch />
              )}
              {user && (
                <EmailContainer
                  // fs-exclude is used to hide email from fullstory recordings.
                  className='navbar-email text-white d-flex fs-exclude'>
                  {user.email}
                </EmailContainer>
              )}
              {!isPreviewCourse && (
                <ChecklistIcon
                  onClick={() => this.toggleCheckList()}
                  alt='checklist'
                  data-testid='checklist-icon'
                  src='images/icons/icon-checklist-feature-updated.svg'
                />
              )}
              {config.hasAnnouncementsFlag() && (
                <NotificationsTool />
              )}
              <ul className='navbar-nav mr-auto d-flex align-items-center'>
                <li className='nav-item d-flex'>
                  {hasNotificationFlag && (
                    <DropDownMenuComponent
                      iconSrc='images/icons/icon-read-notification.svg'
                      showDropDownIcon={hasNotificationFlag}
                      dropDownOffset='18'
                      maxHeight='450'
                    >
                      <Notifications
                        courseContext={courseContext}
                      />
                    </DropDownMenuComponent>
                  )}
                  <DropDownMenuComponent
                    iconSrc={'images/icons/icon-new-hamburger.svg'}
                    iconWidth={'16'}
                    iconHeight={'13.33'}
                    showDropDownIcon={showHamburgerMenu}
                  >
                    <p className='text-uppercase mb-0 dropdown-header'>
                      My Course
                    </p>
                    {/* eslint-disable-next-line */}
                    <a
                      className='dropdown-item'
                      onClick={() => this.redirectionHandler()}
                    >
                      <span>{courseDisplayName} Home</span>
                    </a>
                    {shouldShowDiscussonLink
                      ? (
                        <a
                          data-testid='discussionLink'
                          className='dropdown-item'
                          {...(this.discussionLink === YELLOWDIG
                            ? {
                              onClick: async event => {
                                event.preventDefault()
                                const { yellowdigUrl } = this.state
                                window.open(yellowdigUrl)

                                // generate new url to be used for next click
                                const newUrl = await getYellowdigUrl(cohortData)
                                this.setState({ yellowdigUrl: newUrl })
                              }
                            }
                            : { href: courseDiscussionUrl })}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span>
                            {
                              // eslint-disable-next-line
                            }{isGGU1_11_23Cohort
                              ? 'Discussion via YellowDig'
                              : `${courseDisplayName} ${isCollegeSuccess ? 'Community' : 'Discussion'}`
                            }
                          </span>
                        </a>
                      ) : null
                    }

                    {shouldShowPartnerCommunityLink && (
                      <a
                        className='dropdown-item'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={yellowdigCommunityLink}
                        data-testid='partner-community-link'
                      >
                        {partnerName} Community
                      </a>
                    )}

                    {shouldShowSkooliTutoringLink && <a
                      className='dropdown-item'
                      // This is because of href is required in ancho
                      // (but it's not useful because we use onClick)
                      href='href'
                      onClick={(e) => {
                        e.preventDefault()
                        handleSkooliTutoringLink({
                          cohortID,
                          isAuditor,
                          attemptId: latestCohort?.attemptID || '',
                          studentId
                        })
                      }}
                      data-testid='skooli-tutoring-link'
                    >
                      {getSkooliTutoringTexts(config.courseName).title}
                    </a>}

                    {isGGUCohort && (
                      <a
                        className='dropdown-item'
                        href='#/resources?instructor'
                        rel='noopener noreferrer'
                      >
                        <span>Contact your Instructor</span>
                      </a>
                    )}
                    {(course.showTransferCreditMenuItem && !isStudioCohort) && (
                      <a
                        className='dropdown-item trans-my-credits'
                        href='https://www.outlier.org/pages/transfer-credit'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <span>Transfer Credits</span>
                      </a>
                    )}
                    <p className='text-uppercase mb-0 dropdown-header d-flex justify-content-between'>
                      <span>Outlier</span>
                    </p>
                    {this.renderMenu()}
                  </DropDownMenuComponent>
                </li>
              </ul>
            </div>}
          </Nav>
        </Header>
        <Checklist
          toggleCheckList={this.toggleCheckList}
          isOpen={showCheckList}
          isGGUCohort={isGGUCohort}
        />
        <HeaderPlacementContainer />
        <DegreeRegistrationBanner />
        <NeedsReviewBanner />
        <UpdatedTermsNotificationBanner />
      </>
    )
  }
}

class CombineAuth0AndCourseContexts extends React.Component {
  render () {
    return (
      <AuthUserDataContext.Consumer>
        {authContext => (
          <Context.Consumer>
            {courseContext => {
              const { cohortData: {
                cohortID
              } = {} } = courseContext
              return (
                <StudentHeaderComponent
                  key={`studentHeader_${cohortID}`}
                  courseContext={courseContext}
                  authContext={authContext}
                  {...this.props}
                />
              )
            }}
          </Context.Consumer>
        )}
      </AuthUserDataContext.Consumer>
    )
  }
}

export default CombineAuth0AndCourseContexts

function MenuItem (props) {
  const { label, ...rest } = props
  return (
    <a {...rest} className='d-flex align-items-center'>
      <img src='images/icons/icon-angle-arrow.svg#icon-angle-arrow'
        alt='right-arrow' className='icon-angle-arrow' />
      <span className='menu-item'>{label}</span>
    </a>
  )
}
