import { getExternalLinkUrl } from '@outlier-org/lst-auth-react'
import { CODING_ASSIGNMENT, WRITING_ASSIGNMENT } from './chapterType'
import { OUTLIER_BASE_DOMAIN, OUTLIER_STUDENT_DASHBOARD_URL } from './domains'

export const NODE_ENV = process.env.NODE_ENV

export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN
export const REACT_APP_COURSE_ID = process.env.REACT_APP_COURSE_ID
// eslint-disable-next-line no-useless-escape
export const COURSE_NAME_DOMAIN_REGEX = new RegExp(`(.+?)(?:-pwa)?(\.beta|\.firstedition)?\.${OUTLIER_BASE_DOMAIN.replace('.', '\\.')}`)
export const GGU_DOMAIN = `plus.${OUTLIER_BASE_DOMAIN}`
export const EXT_DOMAIN = `ext.${OUTLIER_BASE_DOMAIN}`
export const PROF_CERT_DOMAIN = `cert.${OUTLIER_BASE_DOMAIN}`
export const FIRST_EDITION_SUBDOMAIN = 'firstedition'
export const CALCULUS_COURSE_ID = 'b227c462-332c-40e0-8735-ea3af6f11661'
export const PSYCHOLOGY_COURSE_ID = '1e2f466d-049a-41e7-af53-74afbfa9d87b'
export const PRACTICE_EXAM_UUID = 'ckxh4deha00063f63mpvo1cce'
export const CONFETTI_CANVAS_DURATION = 0.01
export const CONFETTI_CANVAS_RIBBON_COUNT = 21
export const CONFETTI_CANVAS_COLORS = [['#78BCB8', '#FFFFFF'],
  ['#78BCB8', '#FFFFFF'],
  ['#78BCB8', '#FFFFFF']]
export const STAT_SECTION_11DOT2UUID = 'cki6a7pm000003h5or9zm3bkd'
export const BEFORE_EXAM_START = 'The exam will open on'
export const PIN_TEXT = 'Put a pin in it'
export const PINNED_TEXT = 'Pinned'
export const ADD_TO_CALENDAR_LABEL = 'Add To Calendar'
export const VIEW_TOOLKIT_LABEL = 'View Toolkit'
export const OUTLIER_AUTH_TOKEN = 'OUTLIER_AUTH_TOKEN'
export const DESMOS_SCRIPT_LINK = 'https://www.desmos.com/api/v1.5/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6'
export const MATHJAX_SCRIPT_LINK = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_SVG'
export const YELLOWDIG = 'Yellowdig'
export const DISCORD = 'Discord'
export const PERMISSION_REQUESTED_KEY = 'guardian-permission-requested'
export const PERMISSION_GRANTED_KEY = 'guardian-permission-granted'

// Guardian keys
export const PROF_CERT_GUARDIAN_PERMISSION_REQUESTED = 'prof-cert-guardian-permission-requested'
export const PROF_CERT_GUARDIAN_PERMISSION_GRANTED = 'prof-cert-guardian-permission-granted'

export const KALTURA_VIDEO_PROGRESS_TRACKER = 'kaltura_video_progress_tracker'
export const COURSE_FEATURE_KEY = 'deadline-extension-acknowledged'
export const SECTION = 'section'
export const STUDENT_ACCEPTED_NON_CERTIFICATE_WARNING = 'studentAcceptedNonCertificateWarning'
export const COHORT_START_DATE_2023_03_22 = 1679468400 // 2023-03-22T00:00:00 America/Los_Angeles time
export const CONTENT_USERS = [
  'katiec@outlier.org',
  'estelle@outlier.org',
  'julial@outlier.org',
  'carly@outlier.org',
  'kristen@outlier.org',
  'atalia@outlier.org',
  'kezia@outlier.org',
  'frances@outlier.org',
  'archana@outlier.org',
  'zuriel@outlier.org',
  'drew.zemke@gmail.com',
  'jnvalen@gmail.com',
  'maryam@outlier.org',
  'drew@outlier.org',
  'kenny@outlier.org'
]
export const TIME_MANAGEMENT = 'Time Management'
export const CONTACT_STUDENT_SUCCESS_LINK = `https://help.${OUTLIER_BASE_DOMAIN}/hc/en-us/requests/new`
export const GGU_CONTACT_LINK = getExternalLinkUrl(`${OUTLIER_STUDENT_DASHBOARD_URL}/#/contact`)
export const GGU_ORIENTATION_LINK = getExternalLinkUrl(`${OUTLIER_STUDENT_DASHBOARD_URL}/#/contact?section=orientation`)
export const GGU_CONTACT_INSTRUCTOR_PATH = '/#/resources?instructor'

export const STUDY_BLOCKS_URL = getExternalLinkUrl(`${OUTLIER_STUDENT_DASHBOARD_URL}/#/account/study-blocks`)

export const CODING_ASSIGNMENT_PATH = 'coding_assignment'
export const WRITING_ASSIGNMENT_PATH = 'writing_assignment'
export const PRACTICE_TERM_PATH = 'practice_term'
export const ASSIGNMENT_PATH_NAME = {
  [CODING_ASSIGNMENT]: CODING_ASSIGNMENT_PATH,
  [WRITING_ASSIGNMENT]: WRITING_ASSIGNMENT_PATH
}

export const ASSIGNMENT_PAGE_LAYOUTS = {
  GRADE_PENDING: 'GRADE_PENDING',
  SUBMIT: 'SUBMIT',
  RESUBMIT: 'RESUBMIT',
  PREVIEW: 'PREVIEW',
  REVIEW: 'REVIEW'
}

export const CONFETTI_PASSING_GRADE = 72.5
export const GUARDIAN_PERMISSION_PATH = '/guardian-permission'
export const PROF_CERT_GUARDIAN_PERMISSION_PATH = '/prof-cert-guardian-permission'

export const EXAM_STATUSES = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete'
}
