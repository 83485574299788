import React, { Component, createRef } from 'react'
// import ReactDOM from 'react-dom'
import CompletionModal from '../CompletionModal/CompletionModal'
import withAuthContext from '../../Hoc/withAuthContext'
import withStudentContext from '../../Hoc/withStudentContext'
import config from '../../config'
import { Footer, ListGroup, Link } from './styles'
import { getTimeFromSeconds } from '../../utilities/dateTimeUtils'
import { emitter } from '../Emitter/Emitter'
import { ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import { ACTIVE_LEARNING, QUIZ } from '../../Constants/sectionType'
import { KALTURA_VIDEO_PROGRESS_TRACKER } from '../../Constants'
import api from '../../api'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import $script from 'scriptjs'
import { PRIVACY_URL, TERMS_URL } from '../../Constants/domains'

export const CONVERTFLOW_SCRIPT = 'https://js.convertflow.co/production/websites/28553.js'
export const CONVERTFLOW_KEY = 'convertflow'
export const USERSNAP_SCRIPT = apiKey =>
  `https://widget.usersnap.com/load/${apiKey}?onload=onUsersnapCXLoad`
export const USERSNAP_KEY = 'usersnap'

class StudentFooterComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isBottom: false,
      isUsersnapLoaded: false
    }
    this.usersnapRefTimeout = createRef()
    $script(CONVERTFLOW_SCRIPT, CONVERTFLOW_KEY)
    $script.ready(CONVERTFLOW_KEY, () => {
      this.fetchConvertFlowUser()
    })
  }

  componentDidMount () {
    window.addEventListener('cfClose', this.fetchConvertFlowUser)
    const {
      authData: {
        user: { email = '' } = {}
      } = {}
    } = this.props || {}
    if (email && window.convertflow && window.convertflow.person) {
      try {
        window.convertflow.identify({ email, override: true })
      } catch (e) {
        console.error('$cf is not defined')
      }
    }
    window.addEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate (_prevProps, prevState) {
    const {
      studentData: { isStudentProgressLoaded }
    } = _prevProps

    const {
      studentData: { isStudentProgressLoaded: newIsStudentProgressLoaded }
    } = this.props

    const studentProgressLoaded = isStudentProgressLoaded !== newIsStudentProgressLoaded
    if (studentProgressLoaded) {
      this.loadUsersnap()
    }

    const { isUsersnapLoaded } = prevState

    const usersnapLoaded = isUsersnapLoaded !== this.state.isUsersnapLoaded
    if (usersnapLoaded) {
      this.handleUsersnapOpen()
    }
    if (!window.convertflow) return
    try {
      const propsEqual = !isEqual(_prevProps.activePath, this.props.activePath)
      const contextEqual = !isEqual(_prevProps.authData, this.props.authData)
      if (!get(window.convertflow, 'person.data.activecampaign.last_sync')) {
        window.convertflow.app.visitors.get()
      }
      if (propsEqual || contextEqual) {
        const { authData: { user: { email } = {} } = {} } = this.props
        const { person: { email: convertFlowPersonEmail } = {} } = window.convertflow || {}
        if (window.convertflow?.person && email && (convertFlowPersonEmail !== email)) {
        /* To make convertflow recognise the currently logged in user for automations   */
          window.convertflow.identify({ email, override: true })
        }
        window.convertflow.start()
      }
    } catch (e) {
      console.error('$cf is not defined')
    }
  }

  componentWillUnmount () {
    window.removeEventListener('cfClose', this.fetchConvertFlowUser)
  }

  fetchConvertFlowUser = async () => {
    const cfPersonDetails = window.convertflow?.person
    if (!cfPersonDetails) {
      return
    }
    try {
      if (cfPersonDetails?.email) {
      // eslint-disable-next-line camelcase
        const { email, website_id, visitor_token } = cfPersonDetails
        const result = await api.createUserConvertFlow({ email, website_id, visitor_token })
        if (result) {
          window.convertflow.app.visitors.get()
          window.convertflow.identify({ email, override: true })
        }
      } else {
        const { authData: { user: { email = '' } = {} } = {} } = this.props || {}
        email && window.convertflow.identify({ email, override: true })
        window.convertflow.start()
      }
    } catch (e) {
      console.error('$cf is not defined')
    }
  }

  // Check every second if usersnap is loaded
  checkUsersnapLoaded = () => {
    this.usersnapRefTimeout.current = setTimeout(() => {
      if (window.Usersnap) {
        this.setState({ isUsersnapLoaded: true })
        clearTimeout(this.usersnapRefTimeout.current)
        return
      }

      this.checkUsersnapLoaded()
    }, 1000)
  }

  handleUsersnapOpen = () => {
    window.Usersnap.on('open', event => {
      this.addUsersnapCustomData(event)
    })
  }

  addUsersnapCustomData = event => {
    const {
      authData: { studentId }
    } = this.props

    const videoTracker = document.getElementById(KALTURA_VIDEO_PROGRESS_TRACKER)

    let updatedCustomData = { studentID: studentId }
    if (videoTracker) {
      const { attributes: { kalturaid, progress } } = videoTracker
      const kalturaID = kalturaid.value
      const videoProgress = getTimeFromSeconds(parseFloat(progress.value || 0))

      updatedCustomData = {
        ...updatedCustomData,
        kalturaID,
        videoProgress
      }
    }

    event.api.setValue('custom', updatedCustomData)
  }

  loadUsersnap = () => {
    const {
      studentData: {
        isStudioCohort,
        latestCohort,
        cohortData: {
          startDate
        }
      }
    } = this.props

    if (window.Cypress || config.isPreviewCourse || config.isContentCourse) return

    const apiKey = this.getUsersnapAPIKey({
      usersnapMenuOverride: latestCohort?.usersnapMenuOverride,
      isStudioCohort,
      cohortStartDate: startDate
    })
    $script(USERSNAP_SCRIPT(apiKey), USERSNAP_KEY)
    $script.ready(USERSNAP_KEY, () => {
      this.checkUsersnapLoaded()
    })
  }

  getUsersnapAPIKey = ({ isStudioCohort, cohortStartDate, usersnapMenuOverride }) => {
    const isSkooliUpdate = cohortStartDate >= config.skooliUpdateDate

    const {
      context: {
        cohortData: {
          isGGUCohort,
          creditGrantingInstitution
        },
        courseData: { tutoring } = {}
      }
    } = this.props

    return config.getUsersnapAPIKey({
      isStudioCohort,
      isSkooliUpdate,
      usersnapMenuOverride,
      isGGUCohort,
      cohortStartDate,
      creditGrantingInstitution,
      tutoring
    })
  }

  handleScroll = () => {
    const {
      studentData: {
        isLastQuestionAnswered,
        showFinish
      } = {}
    } = this.props
    if (!isLastQuestionAnswered || !showFinish) return
    const bottom =
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - (showFinish ? 100 : 0)
    this.setState({ isBottom: Boolean(bottom) })
  };

  footerLinksRedirection = (href) => {
    const { currentQuestionSet } = this.props.context
    if (!currentQuestionSet) {
      window.location.href = href
      return
    }
    const { quest_type: questionType } = currentQuestionSet
    const isQuiz = questionType === QUIZ
    if (!isQuiz) {
      window.location.href = href
      return
    }
    const isFlaggedQuiz = isQuiz
    // Navigate to '/' to initiate question wrapper unmount
    isFlaggedQuiz && emitter.emit(ON_NAVIGATE_TO, '/')
    isFlaggedQuiz && setTimeout(() => {
      const hashLength = window.location.hash.split('/').length
      if (hashLength < 3) window.location.href = href
    }, 200)
  }

  render () {
    if (this.props.onlyConvertflow) return null

    const {
      studentData: {
        isLastQuestionAnswered,
        showFinish,
        isAssessmentQuizExam,
        reviewMode,
        currentQuestionSet,
        studentData
      } = {},
      context: {
        isSidePanelOpen,
        course: { currentInstructor },
        modalData: {
          isCompletionModalOpen,
          nextQuestionSetUUID
        } = {}
      } = {}
    } = this.props
    const { isCalculusCourse } = config
    const unsetPosition =
      isLastQuestionAnswered &&
      showFinish &&
      !reviewMode &&
      isAssessmentQuizExam
    const { isBottom, isUsersnapLoaded } = this.state
    const studentAnswers = studentData?.studentAnswers.map(({ uuid }) => uuid)
    const { quest_type: questType } = currentQuestionSet || {}
    const isActiveLearning = questType === ACTIVE_LEARNING
    const isCurrentCalculusCourse = isCalculusCourse()
    const currInstructorQuestionSet = currentQuestionSet?.data
      ?.filter(question => question.instructor?.['instructor_uuid'] === currentInstructor)
    const questionData = isCurrentCalculusCourse && isActiveLearning
      ? currInstructorQuestionSet : currentQuestionSet?.data
    const unAnsweredQuestions = questionData?.filter(
      q => !studentAnswers.includes(q.Question_uuid)
    )
    const unAnsweredCount = unAnsweredQuestions?.length || 0
    return (
      <React.Fragment>
        <Footer
          isAssessmentQuizExam={isAssessmentQuizExam}
          showfinish={unsetPosition}
          unAnsweredCount={unAnsweredCount > 0}
          stickToBottom={isBottom && unsetPosition}
          isSidePanelOpen={isSidePanelOpen}
          isCompletionModalOpen={isCompletionModalOpen || (unAnsweredCount === 0 && showFinish)}
          isTwoModalBtns={!!nextQuestionSetUUID}
          isBottom={isBottom}
          isLastQuestionAnswered={isLastQuestionAnswered}
        >
          <div className='d-flex position-relative'>
            <ListGroup>
              <li className='d-inline-block'>
                <Link
                  href={TERMS_URL}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span>{'Terms of Use'}</span>
                </Link>
              </li>
              <li className='d-inline-block'>
                <Link
                  href={PRIVACY_URL}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span>Privacy Policy</span>
                </Link>
              </li>
            </ListGroup>
          </div>
          {isCompletionModalOpen &&
            <CompletionModal />
          }
          {isUsersnapLoaded && (
            <img
              onClick={() => window.Usersnap.open()}
              src='/images/Feedback-icon.png'
              id='feedback_icon'
              className={'contact-icon'}
              alt='Contact Outlier'
            />
          )}
        </Footer>
      </React.Fragment>
    )
  }
}

StudentFooterComponent.displayName = 'StudentFooterComponent'

export default withAuthContext(withStudentContext(StudentFooterComponent))
