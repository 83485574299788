import { serializeError } from 'serialize-error'
import api from '../api'
import { emitter } from '../Components/Emitter/Emitter'
import config from '../config'
import { postMessage } from '../utilities/tabsCommunication'
import { ON_MIDTERM_EXAM_COMPLETE } from '../Constants/emitterKeys'
import { isObject } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import {
  ACTIVE_LEARNING_COMPLETE,
  CONCEPT_MAP_COMPLETE,
  CONCEPT_MAP_PROGRESS,
  EXAM_COMPLETE,
  GUESSWORK_COMPLETE,
  LAST_EXAM_UUID,
  LAST_GUESSWORK_UUID,
  LECTURE_COMPLETE,
  LECTURE_VIDEO_PROGRESS,
  ORIENTATION_ACTIVE_LEARNING_COMPLETE,
  ORIENTATION_LECTURE_COMPLETE,
  PRACTICE_EXERCISES_COMPLETE,
  PRACTICE_TERM_COMPLETE,
  QUIZ_COMPLETE,
  READINGS_COMPLETE,
  READINGS_PROGRESS,
  REVIEW_LECTURE_COMPLETE,
  STUDENT_ANSWERS
} from '../Constants/studentContext'

export const saveSectionProgress = sectionProgress

export function sanitizeSectionData (key, sectionData) {
  let dissalowedPropKeys = []

  if (key === STUDENT_ANSWERS) {
    dissalowedPropKeys = ['codegradeAssignmentId', 'questionType']
  } else {
    return sectionData
  }

  return Object.fromEntries(Object.entries(sectionData)
    .map(([id, questionData]) => {
      const filteredQuestionData = Object.fromEntries(Object.entries(questionData)
        .filter(([propKey]) => {
          return !dissalowedPropKeys.includes(propKey)
        }))
      return [id, filteredQuestionData]
    }))
}

function validateSectionData (progresskey, sectionData) {
  if (!sectionData) return false
  const kvPair = Object.entries(sectionData)
  const isValid = kvPair?.every(([key, value]) => {
    switch (progresskey) {
      // idStringPair
      case LAST_GUESSWORK_UUID:
      case LAST_EXAM_UUID:
        return typeof key === 'string' &&
          (key.length === 25 || key.length === 36) &&
          typeof value === 'string'
      // idBooleanPair
      case ORIENTATION_ACTIVE_LEARNING_COMPLETE:
      case ACTIVE_LEARNING_COMPLETE:
      case PRACTICE_TERM_COMPLETE:
      case PRACTICE_EXERCISES_COMPLETE:
      case GUESSWORK_COMPLETE:
      case QUIZ_COMPLETE:
      case EXAM_COMPLETE:
        return typeof key === 'string' &&
          (key.length === 25 || key.length === 36) &&
          typeof value === 'boolean'
      // stringBooleanPair
      case REVIEW_LECTURE_COMPLETE:
      case ORIENTATION_LECTURE_COMPLETE:
      case READINGS_COMPLETE:
      case READINGS_PROGRESS:
      case CONCEPT_MAP_COMPLETE:
      case CONCEPT_MAP_PROGRESS:
      case LECTURE_COMPLETE:
        return typeof key === 'string' && typeof value === 'boolean'
      // lectureVideoProgress
      case LECTURE_VIDEO_PROGRESS:
        return typeof key === 'string' && typeof value === 'number'
      // return true for other student progress endpoints
      default:
        return true
    }
  })
  return isValid
}

async function sectionProgress (sectionData,
  { key, warningType, isCohortEndedForStudent, title, sectionUUID, isMidExam }) {
  const cannotUpdateProgress = typeof isCohortEndedForStudent !== 'boolean' ||
    isCohortEndedForStudent
  if (config.isPreviewCourse || cannotUpdateProgress) return

  const isSectionDataEmpty = Object.values(sectionData || {}).every(
    (value) => {
      return typeof value !== 'boolean' &&
        (!value || (isObject(value) && isEmpty(value)))
    }
  )

  if (isSectionDataEmpty) return

  try {
    const isSectionDataValid = validateSectionData(key, sectionData)
    if (!isSectionDataValid) {
      throw new Error(`saveSectionProgress called to save ${key}
       with invalid data: ${JSON.stringify(sectionData)}`)
    }

    if (Array.isArray(sectionData)) {
      await api.setStudentExamProgress(key, sectionData)
    } else {
      const sanitizedSectionData = sanitizeSectionData(key, sectionData)
      await api.setStudentSectionProgress(key, sanitizedSectionData)
    }

    if (isMidExam) {
      emitter.emit(ON_MIDTERM_EXAM_COMPLETE, {
        examUUID: sectionUUID
      })
    }
    return postMessage({ progressUpdate: true }) // Publish changes to other tabs
  } catch (e) {
    // skip logging and throwing error in case of SEM user
    const auth0token = localStorage.getItem('auth0token')
    if (auth0token && auth0token !== 'null') return

    console.info('Error setting student section progress:')
    console.info(e)
    const { message: errorMessage } = e || {}
    if (errorMessage && errorMessage !== 'Network Error') {
      api.logError({
        message: 'Error setting student section progress',
        url: window.location.href,
        ...serializeError(e)
      })
    }
    const message = 'Uh oh! Your session timed out or there\'s been a ' +
    'communication error. \nYour data has NOT been saved to our servers.\n' +
    'Please copy down your most recent answer ' +
    '(it will not be saved) and refresh the page.\n' +
    'Click below to refresh the page when you have completed the above steps.'

    const loadConfirmBox = () => {
      const confirmBox = window.confirm(message)
      if (confirmBox) document.location.reload()
    }

    if (warningType === 'confirm' || e?.response?.status === 401) {
      return loadConfirmBox()
    }
  }
}
