import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import Context, { StudentData as initialContextData } from './Context'
import api from '../../api'
import config from './../../config'
import {
  EXAM_LOCK_STATUS,
  EXAM_STATUS,
  TERMS_AGREEMENT,
  DEGREE_REGISTRATION_INCOMPLETE,
  LAST_MOBILE_LOGIN,
  EMAIL_VERIFIED,
  ACTIVE_LEARNING_COMPLETE,
  READINGS_COMPLETE,
  READINGS_PROGRESS,
  ASSIGNMENT_PROGRESS,
  ASSIGNMENT_FILE_METADATA,
  LECTURE_COMPLETE,
  PRACTICE_EXERCISES_COMPLETE,
  PRACTICE_TERM_COMPLETE,
  GUESSWORK_COMPLETE,
  EXAM_COMPLETE,
  EXAM_SECTION,
  EXAM_PERCENTAGE,
  QUIZ_COMPLETE,
  QUIZ_SECTION,
  SECTION_PROGRESS,
  MINIMUM_SECTION_PROGRESS,
  LAST_ACTIVE_LEARNING_UUID,
  QUIZ_PERCENTAGE,
  RATINGS,
  LAST_GUESSWORK_UUID,
  LAST_QUIZ_UUID,
  LAST_EXAM_UUID,
  LECTURE_VIDEO_PROGRESS,
  CONCEPT_MAP_PROGRESS,
  CONCEPT_MAP_COMPLETE,
  REVIEW_LECTURE_VIDEO_PROGRESS,
  REVIEW_LECTURE_COMPLETE,
  REVIEW_SECTION_PROGRESS,
  ORIENTATION_ACTIVE_LEARNING_COMPLETE,
  ORIENTATION_LAST_ACTIVE_LEARNING_UUID,
  ORIENTATION_LECTURE_COMPLETE,
  ORIENTATION_LECTURE_VIDEO_PROGRESS,
  ORIENTATION_SECTION_PROGRESS,
  REVIEW_LAST_ACTIVE_LEARNING_UUID,
  REVIEW_ACTIVE_LEARNING_COMPLETE,
  REVIEW_STUDENT_ANSWERS,
  FREEZE_TABLE_VIEWED,
  ORIENTATION_STUDENT_ANSWERS
} from '../../Constants/studentContext'
import {
  isContentUser as isContentUserFunction,
  isUserAuditor,
  onboardingFormSubmitted,
  isVIPAccount,
  isVIPGradedContentAccount,
  isUserAdmin,
  overrideTOS,
  shouldLockAssessments,
  showTermsAgreement,
  needsGuardianPermission,
  isConsultant,
  isSavvasAccount
} from '../../utilities/userUtils'
import { onMessage } from '../../utilities/tabsCommunication'
import { emitter } from '../Emitter/Emitter'
import {
  ON_GET_STUDENT_COURSES
} from '../../Constants/emitterKeys'
import {
  NEEDS_REVIEWING_DEGREE,
  NO_ACCESS_STUDENT_STATUSES
} from '../../Constants/studentStatus'
import { NO_ACCESS_STATUS_NOTES } from '../../Constants/statusNote'
import {
  addTokensToActiveCourse,
  getLatestCohort,
  getActiveCourse,
  getCohortStartSecondsSinceEpoch,
  getCohortDuration,
  getCohortSpecialDays,
  getCohortCourseInfoUrl,
  getCohortExamDates,
  getCohortExamDateSecondsSinceEpoch,
  getOfficialCourseName,
  getCourseDisplayName,
  isStudioCohort as isStudioCohortFunction,
  isPartnerCohort as isPartnerCohortFunction,
  setChapterNumber,
  getCohortModifier,
  getIsVIPCohort,
  updateCourseData,
  getCourseDataWithSuffledExamQuestions,
  isInProgressBeforeCutOffDate,
  checkIs39WeekCohort,
  isProfessionalCertificateCourseFromCourseName,
  areAllCoursesProfessionalCertificate,
  isHighSchoolCourse,
  isProfessionalCertificateCourse,
  filterGoogleDataAnalyticsIChapters
} from '../../utilities/courseUtils'
import {
  getEventStatus,
  hasCourseProgress
} from '../../utilities/studentProgressUtils'
import { redirectToDashboard } from '../../utilities/domUtils'
import { secondsSinceEpoch } from '../../utilities/dateTimeUtils'
import {
  getCalendarEvents,
  getCourseResourcesSchedule
} from '../ResourcesSection/Utils'
import { ACTIVE } from '../../Constants/degreeStatus'
import { PITTS } from '../../Constants/creditGrantingInstitutions'
import { getScheduleWithSectionUUIDs } from '../../utilities/sectionUtils'
import { getCurrentGGUSemester } from '../../utilities/gguDegreeUtils'
import { COHORT_START_DATE_2023_03_22 } from '../../Constants'
import { removeQuizzesFromChapters } from '../../utilities/chapterUtils'
import { getUnlockedExams } from '../../utilities/examUtils'
import { AuthUserDataContext, AuthUserDataContextProvider } from './AuthUserDataContext'

let {
  courseId: courseID
} = config

const {
  isCollegeSuccessCourse,
  isPreviewCourse
} = config

const isCollegeSuccess = isCollegeSuccessCourse()

class ContextProvider extends Component {
  constructor (props) {
    super(props)
    this.getStudentAnswersArray = this.getStudentAnswersArray.bind(this)
    this.getStudentData = this.getStudentData.bind(this)
    this.state = {
      ...initialContextData,
      user: null,
      studentId: null
    }
  }

  static contextType = AuthUserDataContext;

  componentDidMount () {
    const { authStore, userStore: { user } } = this.context
    const { isAuthenticated } = authStore

    if (isPreviewCourse) {
      this.setState({ isStudentProgressLoaded: true, user })
      return this.getCourseData()
    }

    if (!isAuthenticated) {
      return
    }

    this.getStudentData()
    this.setUserRolesAndPermissions()
    onMessage(async ({ progressUpdate }) => {
      if (!progressUpdate) return

      const studentProgress = await api.getStudentProgress()
      this.setStudentProgress(studentProgress)
      this.setState(
        { fetchLocalProgress: true },
        () => this.setState({ fetchLocalProgress: false })
      )
    }) // Subscribe to changes in other tabs
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      termsAgreement
    } = this.state
    const {
      termsAgreement: prevTermsAgreement
    } = prevState

    if (termsAgreement && !prevTermsAgreement) {
      this.getStudentData()
    }
  }

  setCourseFeatures = (key, data) => {
    const { courseFeatures: currentCourseFeatures } = this.state
    const courseFeatures = {
      ...currentCourseFeatures,
      [key]: data
    }
    this.setState({
      courseFeatures
    })
  }

  async verifySavvasAdminAccess (email) {
    const adminAccessResponse = await api.verifyStudentAdminAccess()
    const hasAdminAccess = adminAccessResponse === true
    const isSavvasAndHasAdminAccess = isSavvasAccount(email) &&
        hasAdminAccess
    this.setState({
      isSavvasAndHasAdminAccess: isSavvasAndHasAdminAccess && hasAdminAccess
    })
  }

  async getCourseData () {
    this.setState({ isCourseDataLoading: true })
    const courseData = await api.getCourseData(courseID)
    this.setState({
      isCourseDataLoading: false,
      courseData: setChapterNumber(courseData)
    })
    return courseData
  }

  setUserRolesAndPermissions = async () => {
    try {
      const permissionData = await api.getCurrentUserPermissions()
      if (isEmpty(permissionData)) {
        return
      }

      this.setState({
        permissions: permissionData.permissions || [],
        roles: permissionData.roles || []
      })
    } catch (e) {}
  }

  async setStudentData () {
    const { userStore } = this.context
    const { user, setUser } = userStore
    let studentData = await api.getStudentData()

    if (studentData) {
      const userWithEmail = {
        ...user,
        email: user.email || studentData.email
      }
      setUser(userWithEmail)

      this.setState({ user: userWithEmail, studentId: user.studentId })
    } else {
      studentData = {}
    }

    return studentData
  }

  async getStudentData () {
    if (isPreviewCourse) return

    const { cohortData: { startDate } } = this.state

    if (isCollegeSuccess && !startDate) {
      await api.enrollToCollegeSuccessCourse()
    }

    const studentData = await this.setStudentData()
    const { userStore: { user } } = this.context
    const { email, email_verified: auth0EmailVerified } = user

    this.verifySavvasAdminAccess(email)
    const isAdmin = await isUserAdmin(email)
    courseID = await api.isStudentRegisteredForCourse(isAdmin)
    this.setState({
      isAdmin,
      courseID,
      registerStatus: Boolean(courseID)
    })
    if (!courseID) return

    const shouldFetchAllCourses = isProfessionalCertificateCourseFromCourseName()

    const [courses, tokens, datoCourseData, extensions] =
        await Promise.all([
          api.getStudentCourses(isConsultant(email), undefined, shouldFetchAllCourses),
          api.getStudentTokens(),
          this.getCourseData(),
          api.getExtensions()
        ])
    const { courses: apiCourses } = courses || {}
    const studentCourses = addTokensToActiveCourse(apiCourses, courseID, tokens)
    const areAllCoursesProfCert = areAllCoursesProfessionalCertificate(
      studentCourses
    )
    const activeCourse = getActiveCourse(studentCourses, courseID)
    const isProfCertCourse = isProfessionalCertificateCourse(activeCourse)
    const highSchoolCourse = isHighSchoolCourse(activeCourse)
    const latestCohort = getLatestCohort(activeCourse)
    const isAuditor = isUserAuditor(latestCohort)

    let courseData = datoCourseData
    let isCohortInProgress = false
    if (isAuditor) {
      const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
      isCohortInProgress = isInProgressBeforeCutOffDate({
        dateStart,
        finalExamEndTime,
        cohortEndTime,
        duration,
        officialCourseName: activeCourse?.name
      })
      if (!isCohortInProgress) courseData = removeQuizzesFromChapters(datoCourseData)
    }

    const cohortStartDate = getCohortStartSecondsSinceEpoch(latestCohort)
    const cohortDuration = getCohortDuration(latestCohort)
    const cohortSpecialDays = getCohortSpecialDays(latestCohort)
    const cohortCourseInfoUrl = getCohortCourseInfoUrl(activeCourse, isAdmin)
    const cohortModifier = getCohortModifier(courseData?.chapters, cohortDuration)
    const cohortExamDates = getCohortExamDates(latestCohort)
    const officialCourseName = getOfficialCourseName(activeCourse)
    const courseDisplayName = getCourseDisplayName(activeCourse)
    const isVIP = isVIPAccount(activeCourse) || getIsVIPCohort(latestCohort?.cohortName)
    const isVIPGradedContent = isVIPGradedContentAccount(activeCourse)
    const isVIPCourse = !!activeCourse?.vip
    const isPartnerCohort = isPartnerCohortFunction(latestCohort)
    const isStudioCohort = isStudioCohortFunction(latestCohort, isAdmin)

    if (
      config.isStatisticsCourse &&
      cohortStartDate < COHORT_START_DATE_2023_03_22 &&
      courseData?.chapters[15]?.sections[1]?.guessworkUUID
    ) {
      // removes guesswork uuid to unlock STATISTICS FINAL EXAM INFORMATIONAL SESSION
      // for cohorts before COHORT_START_DATE_2023_03_22
      courseData.chapters[15].sections[1].guessworkUUID = null
    }

    const {
      attemptID,
      name,
      duration,
      creditGrantingInstitution,
      finalExamEndTime,
      cohortEndTime,
      finalDropDate,
      finalWithdrawalDate,
      finalGradeDate,
      id: cohortID,
      isContentGatingEnabledCohort,
      isContentGatingEnabledStudent,
      milestones: cohortMilestones,
      statusNote,
      calendarKey,
      studentStatus,
      testAttempt,
      relationship,
      exam5EndTime
    } = latestCohort || {}

    if (!cohortID) {
      return this.setState({ registerStatus: false })
    }

    const isNeedsReviewingDegree = activeCourse?.statusData?.some(attempt => {
      return attempt.studentStatus === NEEDS_REVIEWING_DEGREE
    })
    const noAccessToCourse = isNeedsReviewingDegree ||
        NO_ACCESS_STATUS_NOTES.includes(statusNote) ||
        NO_ACCESS_STUDENT_STATUSES.includes(studentStatus)
    this.setState({ noAccessToCourse })
    if (noAccessToCourse) return

    const currentDate = secondsSinceEpoch()
    const endTimes = [
      getCohortExamDateSecondsSinceEpoch(exam5EndTime),
      getCohortExamDateSecondsSinceEpoch(finalExamEndTime),
      getCohortExamDateSecondsSinceEpoch(cohortEndTime)
    ].filter(time => time !== undefined)

    const latestEndTime = Math.max(...endTimes)
    const isCohortEnded = latestEndTime < currentDate

    const isContentGatingEnabled = !!(
      isContentGatingEnabledStudent ||
        isContentGatingEnabledCohort
    )

    const termsAgreementOverride = isPartnerCohort && overrideTOS(latestCohort)

    const isNoAssessments = shouldLockAssessments({
      isAdmin,
      course: activeCourse,
      isVIP
    })

    const [
      fetchedSchedule,
      isContentUser,
      originalCohortDeadlines,
      sectionCountData,
      courseFeatures,
      prospects,
      adjustedGradeData,
      adjustedAssignmentGradeData,
      studentProgress,
      examOverrideKeys,
      isInitialProgress
    ] = await Promise.all([
      api.getSyllabusDetails(cohortID),
      isContentUserFunction(email, studentStatus),
      api.getCohortDeadlines(cohortID),
      api.getSectionCountData(config.courseId),
      api.getCourseFeatures(),
      api.getProspectsData(),
      api.getSectionModificationsForStudent(),
      api.getAssignmentModificationsForStudent(),
      api.getStudentProgress(),
      api.getExamOverrideKeys(cohortID),
      getEventStatus(cohortID)
    ])

    let courseMetadata = sectionCountData
    if (isAuditor) {
      if (!isCohortInProgress) {
        courseMetadata = removeQuizzesFromChapters(sectionCountData, 'quizCount')
      }
    }

    if (!adjustedGradeData?.error) {
      this.setState({ adjustedGradeData })
    }

    if (!adjustedAssignmentGradeData?.error) {
      this.setState({ adjustedAssignmentGradeData })
    }

    const {
      applicationSubmitted,
      degreeStatus,
      gguSemesters,
      term: gguTerm
    } = prospects?.[0] || {}

    const isGGUStudent = applicationSubmitted
    const isActiveGGUStudent = isGGUStudent && degreeStatus === ACTIVE

    let currentSemester
    if (isActiveGGUStudent) {
      const allGGUSemesters = await api.getAllGGUSemesters()
      currentSemester = getCurrentGGUSemester({
        allGGUSemesters, studentAssignedSemesters: gguSemesters
      })
    }

    const fetchedScheduleWithSectionUUIDs = getScheduleWithSectionUUIDs(fetchedSchedule, courseData?.chapters)

    const isGGUCohort = name?.toLowerCase().includes('ggu')
    const is39WeekCohort = checkIs39WeekCohort({ officialCourseName, duration })

    const courseResourcesSchedule = getCourseResourcesSchedule({
      startDate: cohortStartDate,
      duration,
      fetchedSchedule: fetchedScheduleWithSectionUUIDs,
      finalDropDate,
      finalWithdrawalDate,
      finalGradeDate,
      cohortMilestones,
      cohortSpecialDays,
      cohortExamDates,
      courseData,
      isAuditor,
      isGGUCohort,
      liveProctoring: relationship?.fields?.liveProctoring
    })

    const calendarEvents = getCalendarEvents(courseResourcesSchedule)

    const cohortData = {
      attemptID,
      name,
      startDate: cohortStartDate,
      fetchedSchedule: fetchedScheduleWithSectionUUIDs,
      cohortSpecialDays,
      endDate: cohortExamDates?.courseEndDate,
      duration,
      cohortID,
      cohortModifier,
      cohortMilestones,
      cohortExamDates,
      studentStatus,
      testAttempt,
      isPartnerCohort,
      // assign PITTS as default for students without creditGrantingInstitution
      creditGrantingInstitution: creditGrantingInstitution || PITTS,
      termsAgreementOverride,
      courseDisplayName,
      calendarKey,
      courseResourcesSchedule,
      calendarEvents,
      officialCourseName,
      gguTerm,
      is39WeekCohort,
      isGGUCohort,
      relationship: relationship || {}
    }

    const courseDataWithSuffledExamQuestions = getCourseDataWithSuffledExamQuestions({
      courseData: updateCourseData(courseData, cohortExamDates),
      email,
      studentProgress,
      cohortId: cohortID
    })

    const filteredChapters = filterGoogleDataAnalyticsIChapters(
      courseDataWithSuffledExamQuestions?.chapters, courseID, cohortStartDate * 1000)

    const filteredCourseData = {
      ...courseDataWithSuffledExamQuestions,
      chapters: filteredChapters
    }

    const instructorId = window?.location?.href?.split('?instructor=')?.[1] || null
    const themeName = window?.location?.href?.split('theme_name=')?.[1] || ''
    this.setState({
      extensions,
      isAuditor,
      isProfessionalCertificateCourse: isProfCertCourse,
      isPartnerCohort,
      isStudioCohort,
      isContentUser,
      course: { currentInstructor: instructorId },
      currentActiveLearningTheme: themeName,
      // Content users should have site access similar to Admin users
      isAdmin: isAdmin || isContentUser,
      isInitialProgress,
      latestCohort,
      originalCohortDeadlines,
      isContentGatingEnabled,
      termsAgreementOverride,
      studentCourses,
      activeCourse,
      areAllCoursesProfCert,
      courseData: filteredCourseData,
      cohortData,
      cohortStartDate,
      cohortDuration,
      cohortSpecialDays,
      cohortCourseInfoUrl,
      cohortExamDates,
      isCohortEnded,
      isCohortEndedForStudent: isCohortEnded && !isAdmin,
      officialCourseName,
      courseDisplayName,
      isNoAssessments,
      isVIPGradedContent,
      courseMetadata,
      courseFeatures,
      prospects,
      overrideKeys: examOverrideKeys?.overrideKeys,
      isGGUStudent,
      isActiveGGUStudent,
      currentSemester,
      isVIP,
      isVIPCourse
    })

    const {
      firstName,
      lastName,
      dateOfBirth,
      enrollingStudentCertification,
      dateTOS,
      guardianEmail,
      guardianTOSDate,
      [TERMS_AGREEMENT]: termsAgreement,
      [DEGREE_REGISTRATION_INCOMPLETE]: degreeRegistrationIncomplete,
      [LAST_MOBILE_LOGIN]: lastMobileLogin,
      [EMAIL_VERIFIED]: studentDataEmailVerified,
      [FREEZE_TABLE_VIEWED]: freezeTableViewed,
      notStudent,
      existsInRegistration: isTypeFormRegistered,
      hasAIF,
      under13,
      contractorEmployee,
      dateToRemoveAccess,
      gguCertification1,
      gguCertification2,
      gguCertification3,
      isProfCertRegistered,
      gguParentCertification1,
      gguParentCertification2,
      gguParentCertification3,
      isPITStudent,
      zoomConsent,
      pwaOnboard,
      id,
      instrideId
    } = studentData || {}

    const bypassOnboarding = isAdmin || !!instrideId

    if (!bypassOnboarding) {
      const shouldOnboard = !hasCourseProgress(studentProgress)

      if (shouldOnboard) {
        const emailVerified = auth0EmailVerified || studentDataEmailVerified

        if (!emailVerified && !isCollegeSuccess && !isGGUStudent && !highSchoolCourse) {
          return redirectToDashboard()
        }

        const onboardingComplete = onboardingFormSubmitted({
          enrollingStudentCertification,
          firstName,
          lastName,
          isVIP,
          isVIPGradedContent,
          dateOfBirth
        })

        if (!onboardingComplete && !isCollegeSuccess && !isGGUStudent) {
          return redirectToDashboard()
        }
      }
    }

    const gguCertificationFormNotSubmitted = gguCertification1 === false ||
      gguCertification2 === false ||
      gguCertification3 === false

    const gguParentCertificationFormNotSubmitted = gguParentCertification1 === false ||
      gguParentCertification2 === false ||
      gguParentCertification3 === false

    const gguParentCertificationFormSubmitted = gguParentCertification1 === true &&
      gguParentCertification2 === true &&
      gguParentCertification3 === true

    if (config.hasUPittBlockerFlag) {
      const data = await api.getUPittOverideStatus()
      this.setState({ upittStudentData: data })
    }

    const needsPermission = needsGuardianPermission({ dateOfBirth, under13 })

    const {
      showGuardianPermission,
      showTermsModal,
      showProfCertGuardianPermission
    } = showTermsAgreement({
      dateOfBirth,
      under13,
      termsAgreementOverride,
      studentData: studentProgress,
      dateTOS,
      isProfCertCourse,
      isActiveGGUStudent,
      gguParentCertificationFormNotSubmitted,
      guardianTOSDate
    })

    this.setState(
      {
        showGuardianPermission,
        showTermsModal,
        showProfCertGuardianPermission,
        gguParentCertificationFormSubmitted,
        termsAgreement,
        degreeRegistrationIncomplete,
        lastMobileLogin,
        isNotStudent: notStudent,
        isTypeFormRegistered,
        hasAIF,
        under13,
        dateOfBirth,
        dateTOS,
        needsGuardianPermission: needsPermission,
        guardianEmail,
        guardianTOSDate,
        firstName,
        isContractorEmployee: contractorEmployee,
        gguCertificationFormNotSubmitted,
        isProfCertRegistered,
        dateToRemoveAccess,
        isPITStudent,
        zoomConsent: latestCohort?.zoomConsent || zoomConsent,
        pwaOnboard,
        freezeTableViewed,
        id
      },
      () => {
        const latestTermsAccepted = this.getToSStatusByUserType()
        if (!latestTermsAccepted) return

        emitter.emit(ON_GET_STUDENT_COURSES)
      }
    )

    this.setStudentProgress(studentProgress)
  }

  getToSStatusByUserType = () => {
    const {
      termsAgreement,
      termsAgreementOverride,
      isAdmin,
      isVIP,
      isVIPGradedContent,
      dateOfBirth,
      showGuardianPermission,
      showTermsModal,
      isGGUStudent
    } = this.state

    if (isAdmin || termsAgreementOverride) return true

    if (isVIP || isVIPGradedContent) return termsAgreement

    if (!dateOfBirth && !isCollegeSuccess && !isGGUStudent) return false

    return !(showGuardianPermission || showTermsModal)
  }

  setStudentProgress = (fetchedStudentProgress) => {
    const { studentData: initialStudentData } = this.state

    if (
      !fetchedStudentProgress || Object.keys(fetchedStudentProgress).length === 0
    ) return this.setState({ isStudentProgressLoaded: true, studentData: null })

    const {
      [ACTIVE_LEARNING_COMPLETE]: activeLearningComplete,
      [LECTURE_COMPLETE]: lectureComplete,
      [LECTURE_VIDEO_PROGRESS]: lectureVideoProgress,
      [PRACTICE_EXERCISES_COMPLETE]: practiceExercisesComplete,
      [GUESSWORK_COMPLETE]: guessworkComplete,
      [EXAM_COMPLETE]: examComplete,
      [EXAM_SECTION]: examSection,
      [EXAM_PERCENTAGE]: examPercentage,
      [QUIZ_COMPLETE]: quizComplete,
      [QUIZ_SECTION]: quizSection,
      [QUIZ_PERCENTAGE]: quizPercentage,
      [SECTION_PROGRESS]: sectionProgress,
      [MINIMUM_SECTION_PROGRESS]: minimumSectionProgress,
      [LAST_ACTIVE_LEARNING_UUID]: lastActiveLearningUUID,
      [RATINGS]: ratings,
      [LAST_GUESSWORK_UUID]: lastGuessworkUUID,
      [LAST_QUIZ_UUID]: lastQuizUUID,
      [LAST_EXAM_UUID]: lastExamUUID,
      [READINGS_PROGRESS]: readingsProgress,
      [READINGS_COMPLETE]: readingsComplete,
      [ASSIGNMENT_PROGRESS]: assignmentProgress,
      [ASSIGNMENT_FILE_METADATA]: assignmentFileMetadata,
      [PRACTICE_TERM_COMPLETE]: practiceTermComplete,
      [ORIENTATION_ACTIVE_LEARNING_COMPLETE]: orientationActiveLearningComplete,
      [ORIENTATION_LAST_ACTIVE_LEARNING_UUID]: orientationLastActiveLearningUUID,
      [ORIENTATION_LECTURE_COMPLETE]: orientationLectureComplete,
      [ORIENTATION_LECTURE_VIDEO_PROGRESS]: orientationLectureVideoProgress,
      [ORIENTATION_SECTION_PROGRESS]: orientationSectionProgress,
      [ORIENTATION_STUDENT_ANSWERS]: orientationStudentAnswers,
      [CONCEPT_MAP_PROGRESS]: conceptMapProgress,
      [CONCEPT_MAP_COMPLETE]: conceptMapComplete,
      [REVIEW_LECTURE_VIDEO_PROGRESS]: reviewLectureVideoProgress,
      [REVIEW_LECTURE_COMPLETE]: reviewLectureComplete,
      [REVIEW_SECTION_PROGRESS]: reviewSectionProgress,
      [REVIEW_LAST_ACTIVE_LEARNING_UUID]: reviewLastActiveLearningUUID,
      [REVIEW_ACTIVE_LEARNING_COMPLETE]: reviewActiveLearningComplete,
      [REVIEW_STUDENT_ANSWERS]: reviewStudentAnswers,
      [EXAM_LOCK_STATUS]: examLockStatus,
      [EXAM_STATUS]: examStatus,
      studentAnswers
    } = fetchedStudentProgress

    const isProgressEmpty = isEmpty(lastQuizUUID) && isEmpty(lastExamUUID)
    const studentAnswersArray = this.getStudentAnswersArray(studentAnswers)
    const orientationStudentAnswersArray = this.getStudentAnswersArray(orientationStudentAnswers)
    const reviewStudentAnswersArray = this.getStudentAnswersArray(reviewStudentAnswers)

    const updatedStudentData = {
      ...initialStudentData,
      [ACTIVE_LEARNING_COMPLETE]: activeLearningComplete || {},
      isProgressEmpty,
      [LECTURE_COMPLETE]: lectureComplete || {},
      [LECTURE_VIDEO_PROGRESS]: lectureVideoProgress || {},
      [PRACTICE_EXERCISES_COMPLETE]: practiceExercisesComplete || {},
      [GUESSWORK_COMPLETE]: guessworkComplete || {},
      [EXAM_COMPLETE]: examComplete || {},
      [EXAM_SECTION]: examSection || {},
      [EXAM_PERCENTAGE]: examPercentage || {},
      [QUIZ_COMPLETE]: quizComplete || {},
      [QUIZ_SECTION]: quizSection || {},
      [QUIZ_PERCENTAGE]: quizPercentage || {},
      [SECTION_PROGRESS]: sectionProgress || {},
      [MINIMUM_SECTION_PROGRESS]: minimumSectionProgress || {},
      [RATINGS]: ratings || {},
      [LAST_GUESSWORK_UUID]: lastGuessworkUUID || {},
      [LAST_QUIZ_UUID]: lastQuizUUID || {},
      [LAST_EXAM_UUID]: lastExamUUID || {},
      [LAST_ACTIVE_LEARNING_UUID]: lastActiveLearningUUID || {},
      [READINGS_PROGRESS]: readingsProgress || {},
      [READINGS_COMPLETE]: readingsComplete || {},
      [ASSIGNMENT_PROGRESS]: assignmentProgress || {},
      [PRACTICE_TERM_COMPLETE]: practiceTermComplete || {},
      [ASSIGNMENT_FILE_METADATA]: assignmentFileMetadata || {},
      [ORIENTATION_ACTIVE_LEARNING_COMPLETE]: orientationActiveLearningComplete || {},
      [ORIENTATION_LAST_ACTIVE_LEARNING_UUID]: orientationLastActiveLearningUUID || {},
      [ORIENTATION_LECTURE_COMPLETE]: orientationLectureComplete || {},
      [ORIENTATION_LECTURE_VIDEO_PROGRESS]: orientationLectureVideoProgress || {},
      [ORIENTATION_SECTION_PROGRESS]: orientationSectionProgress || {},
      [CONCEPT_MAP_PROGRESS]: conceptMapProgress || {},
      [CONCEPT_MAP_COMPLETE]: conceptMapComplete || {},
      [REVIEW_LECTURE_VIDEO_PROGRESS]: reviewLectureVideoProgress || {},
      [REVIEW_LECTURE_COMPLETE]: reviewLectureComplete || {},
      [REVIEW_SECTION_PROGRESS]: reviewSectionProgress || {},
      [REVIEW_LAST_ACTIVE_LEARNING_UUID]: reviewLastActiveLearningUUID || {},
      [REVIEW_ACTIVE_LEARNING_COMPLETE]: reviewActiveLearningComplete || {},
      [EXAM_LOCK_STATUS]: examLockStatus || {},
      [EXAM_STATUS]: examStatus || {},
      ...(reviewStudentAnswersArray ? { [REVIEW_STUDENT_ANSWERS]: reviewStudentAnswersArray } : {}),
      ...(orientationStudentAnswersArray ? { [ORIENTATION_STUDENT_ANSWERS]: orientationStudentAnswersArray } : {}),
      ...(studentAnswersArray ? { studentAnswers: studentAnswersArray } : {})
    }

    const examsUnlockedWithKeys = getUnlockedExams(examLockStatus)

    this.setState({
      examsUnlockedWithKeys,
      studentData: updatedStudentData,
      isStudentProgressLoaded: true
    })
  }

  getStudentAnswersArray (studentAnswers) {
    const studentAnswersArray = []
    if (!studentAnswers) return studentAnswersArray
    for (const uuid in studentAnswers) {
      studentAnswersArray.push({
        uuid,
        ...studentAnswers[uuid]
      })
    }
    return studentAnswersArray
  }

  render () {
    const { isFirstLogin, redirectURI } = this.props
    const {
      isStudentProgressLoaded,
      studentData,
      ...rest
    } = this.state

    if (isStudentProgressLoaded && isEmpty(studentData)) {
      throw new Error('Student progress cannot be fetched')
    }

    return (
      <Context.Provider value={{
        ...rest,
        isStudentProgressLoaded,
        studentData,
        courseID,
        isFirstLogin,
        redirectURI,
        setStudentProgress: this.setStudentProgress,
        setCourseFeatures: this.setCourseFeatures,
        updateContext: (context) => this.setState(context)
      }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

export const CombinedContextProviderWrapper = ({ children }) => {
  return (
    <AuthUserDataContextProvider>
      <ContextProvider>
        {children}
      </ContextProvider>
    </AuthUserDataContextProvider>
  )
}
