import React, { useEffect, useState, useContext } from 'react'
import ListView from './ListView'
import {
  SidePanelWrapper,
  SidePanelHead,
  SelectedView,
  SidePanelContainer,
  TextWrapper,
  CrossIcon,
  FullScreenIcon } from './styles'
import { isOutlierAccount } from '../../utilities/userUtils'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'

function getTotalAttempted (progressBubbleButtons) {
  return progressBubbleButtons.filter(progressBubbleButton =>
    progressBubbleButton.props.btnClass.includes('incorrect') ||
    progressBubbleButton.props.btnClass.includes('completed') ||
    progressBubbleButton.props.btnClass.includes('response-submitted')
  )
}

function SidePanelWithPin ({
  progressBubbleButtons,
  updateContext,
  QuizIntroPage,
  reviewMode,
  isLastQuestion,
  isALRedesign,
  sidePanelOpen,
  sidePanelOpenState,
  isNonReviewAndExam
}) {
  const isMobile = window.matchMedia('(max-width: 768px)').matches
  const sidePanelInitialState = sidePanelOpenState !== undefined
    ? sidePanelOpenState
    : isMobile ? 0 : 1
  const [selectedIcon, setSelectedIcon] = useState(sidePanelInitialState)
  const isFullScreen = selectedIcon === 0
  const isListView = selectedIcon === 1
  const totalQuestions = progressBubbleButtons.length
  const totalAttempted = getTotalAttempted(progressBubbleButtons).length
  const [height, setHeight] = useState(document.body.offsetHeight)
  const questionText = reviewMode?.percentage
    ? `${Math.round(reviewMode.percentage)}%`
    : `${totalAttempted}/${totalQuestions}`

  useEffect(() => {
    setTimeout(() => {
      setHeight(document.body.offsetHeight)
    }, 1000)
    const isSidePanelOpen = !!selectedIcon
    updateContext && updateContext({ isSidePanelOpen })
    if (
      !isFullScreen && window.innerWidth < 768
    ) return document.body.classList.add('stop-scrolling')
    document.body.classList.remove('stop-scrolling')
    // eslint-disable-next-line
  }, [selectedIcon,
    window.innerWidth,
    isFullScreen,
    document.body.offsetHeight])

  const { userStore: { user: { email } } } = useContext(Auth0Context)
  const isOutlierUser = isOutlierAccount(email)

  return (
    <SidePanelContainer
      isLastQuestion={isLastQuestion}
      isOutlierUser={isOutlierUser}
      sidePanelHeight={height}
      reviewMode={reviewMode}
      isNonReviewAndExam={isNonReviewAndExam}
      QuizIntroPage={QuizIntroPage}
      isALRedesign={isALRedesign}
      isSidePanelOpen={sidePanelOpen}
      selectedIcon={selectedIcon}><SidePanelHead selectedIcon={selectedIcon}>
        <TextWrapper
          selectedIcon={selectedIcon}
        >
          {questionText}
        </TextWrapper>
        {isFullScreen
          ? <FullScreenIcon>
            <img
              className='bottomSpace'
              alt='icon'
              src='/images/icons/icon-full-screen-white.svg' />
          </FullScreenIcon>
          : <FullScreenIcon
            onClick={() => {
              setSelectedIcon(0)
              updateContext({ sidePanelOpenState: 0 })
            }}>
            <img
              alt='icon'
              src='/images/icons/icon-full-screen.svg' />
          </FullScreenIcon>}

        {isListView
          ? <SelectedView selectedIcon={selectedIcon}>
            <img
              className='bottomSpace'
              alt='icon'
              src='/images/icons/icon-list-view-white.svg' />
          </SelectedView>
          : <SelectedView
            onClick={() => {
              setSelectedIcon(1)
              updateContext({ sidePanelOpenState: 1 })
            }}
            selectedIcon={selectedIcon}>
            <img
              alt='icon'
              src='/images/icons/icon-list-view.svg' />
          </SelectedView>}
        <SelectedView
          selectedIcon={selectedIcon}
        >
          <img
            alt='icon'
            src='/images/icons/icon-pin-view.svg' />
        </SelectedView>
        {!isFullScreen && <CrossIcon onClick={() => {
          setSelectedIcon(0)
          updateContext({ sidePanelOpenState: 0 })
        }}>
          <img
            alt='icon'
            src='/images/icons/icon-cross-white.svg' />
        </CrossIcon>}
      </SidePanelHead>
      {isListView &&
      <SidePanelWrapper
        isALRedesign={isALRedesign}
        selectedIcon={selectedIcon}
        isLastQuestion={isLastQuestion}
      >
        <ListView progressBubbleButtons={progressBubbleButtons} />
      </SidePanelWrapper>}
    </SidePanelContainer>
  )
}

export default SidePanelWithPin
