import styled from 'styled-components'
import mediaqueries, { BREAKPOINTS } from '../../../mediaQueries'

export const Footer = styled.footer`
  background-color: var(--dark-black);
  color: var(--white);
  /* position: ${props => props.isCompletionModalOpen ? 'fixed' : 'absolute'}; */
  position: absolute;
  margin-top: auto;
  bottom: 0;
  //z-index: ${props => (props.showfinish ? 'unset' : 1050)};
  display: flex;
  justify-content: space-between;
  &:before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.25);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
  }

  ${mediaqueries.tablet`
      bottom: ${props => props.isCompletionModalOpen && props.isTwoModalBtns &&
      '131px !important;'};
    `}

  ${mediaqueries.mobile`
    bottom: ${props => props.isCompletionModalOpen &&
    '0px !important'};
  `}

  .contact-icon {
    position: fixed;

    bottom: ${props => props.isBottom ? '143.5px !important'
    : props.unAnsweredCount && props.isLastQuestionAnswered ? '139px !important'
      : props.isCompletionModalOpen && '90.5px !important'};

    height: 42px;
    width: 42px;
    z-index: 9999;
    ${props =>
    props.stickToBottom && `
      margin-right: 12px;
      transform: translateY(-4px) !important;
    `};

    ${mediaqueries.tablet`
      bottom: ${props => props.isCompletionModalOpen && props.isTwoModalBtns &&
      '133.5px !important;'};
    `}

    ${mediaqueries.mobile`
      bottom: ${props => (props.unAnsweredCount || props.isCompletionModalOpen) && '4px !important'};
    `}
  }
  .position-relative{
    z-index: 9999;
    ${mediaqueries.mobile`
      z-index: 1;
    `}
  }
  @media only screen and (min-width: ${BREAKPOINTS.mobile}) and (max-width: ${
  BREAKPOINTS.tablet
}) {
    background-color: var(--dark-black);
    margin-bottom: ${props => (props.unAnsweredCount ? '-20px' : '1px')};
  }
  ${mediaqueries.tablet`
      margin-bottom: 0px;
      background-color: var(--dark-black);
`}
  ${mediaqueries.mobile`
      margin-bottom: 0px;
      background-color: var(--dark-black);
      z-index: unset;
      bottom: ${({ isSidePanelOpen }) =>
    isSidePanelOpen ? '0px'
      : '0px'};
`}
`

export const ListGroup = styled.ul`
  display: inline-flex;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
`
export const Link = styled.a`
  color: var(--teal);
  padding: 12px 0px;
  display: inline-block;
  border: 1px solid #727272;
  font-size: calc(0.5rem + var(--font-increase-rem));
  border: 1px solid transparent;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  span {
    padding-left: 15px;
    padding-right: 15px;
    text-decoration: underline;
  }
  &:disabled {
    color: #5FC4B8;
    opacity: 0.3;
  }
  &:not(:disabled) {
    &:active {
      opacity: 0.3;
    }
    &:hover {
      color: #5FC4B8 !important;
    }
  }
`
