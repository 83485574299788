import React, { useState, useEffect, useContext } from 'react'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import api from '../../api'
import { words } from 'lodash'
import DownloadLink from './DownloadLink'
import {
  secondsToFormattedDateTimeShort,
  getTimezoneShort
} from '../../utilities/dateTimeUtils'
import { Editor } from '../TextEditor/styles'
import {
  AnnotatedFeedbackArea,
  DownloadIcon,
  DownloadText,
  ExternalLink,
  ReviewContainer,
  ReviewItemsArea,
  ReviewItem,
  SubmissionClosedMessage, AssignmentFileLink
} from './styles'
import RichTextEditor from '../RichTextEditor/RichTextEditor'
import { downloadFile } from '../../utilities/file'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'

const AssignmentReviewPage = (props) => {
  const {
    userStore: { user: { nickname = '' } = {} } = {}
  } = useContext(Auth0Context) || {}
  const {
    assignmentUUID,
    assignment,
    content,
    lockTimeInSeconds,
    progressData,
    courseID,
    cohortID,
    fileMetadata,
    title,
    hasStarterFiles,
    answerKeyFile,
    hideTextEntryField
  } = props
  const { grade, feedback, status, submissionTime } = progressData || {}
  const {
    data: submissionData, headers: { 'content-type': contentType } = {}
  } = assignment || {}
  const {
    kami: {
      document_identifier: documentIdentifier,
      viewer_url: kamiViewSessionUrl
    } = {}
  } = fileMetadata || {}
  const isTextEntry = contentType === 'application/json'
  const isPdf = contentType === 'application/pdf'
  const isTextFile = contentType === 'text/plain'

  const [feedbackFile, setFeedbackFile] = useState({})
  const [annotatedDocUrl, setAnnotatedDocUrl] = useState('')
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false)

  const { url: feedbackURL } = feedbackFile

  useEffect(() => {
    const getFeedbackFile = async () => {
      try {
        setIsLoadingFeedback(true)
        const response = await api.getAssignmentFeedbackFile(courseID, cohortID, assignmentUUID)
        setIsLoadingFeedback(false)

        if (!(response instanceof Blob)) return

        const blob = new Blob([response], { type: 'application/pdf' })
        const objectURL = URL.createObjectURL(blob)
        setFeedbackFile({
          url: objectURL
        })
      } catch (error) {
        console.error(error.message)
      }
    }
    getFeedbackFile()
  }, [courseID, cohortID, assignmentUUID])

  useEffect(() => {
    if (kamiViewSessionUrl) return setAnnotatedDocUrl(kamiViewSessionUrl)

    const createKamiViewSession = async () => {
      setIsLoadingFeedback(true)
      const { data, message } = await api.createKamiViewSession({
        courseID, cohortID, assignmentUUID, documentIdentifier, nickname
      }) || {}
      setIsLoadingFeedback(false)

      if (message) return

      const { viewer_url: viewUrl = '' } = data || {}
      setAnnotatedDocUrl(viewUrl)
    }

    createKamiViewSession()
  // eslint-disable-next-line
  }, [])

  const handleDownloadSubmission = (e) => {
    e.preventDefault()

    let blob = new Blob([submissionData], { type: contentType })
    const fileName = `${title}_original-submission`

    if (isTextEntry) {
      const encodedData = new TextEncoder().encode(content.text)
      blob = new Blob([encodedData], { type: 'text/plain' })
    }

    downloadFile(null, { blob, fileName })
  }

  const assignmentLockDate =
    secondsToFormattedDateTimeShort(lockTimeInSeconds, 'short')
  const assignmentLockTimeZone = getTimezoneShort(lockTimeInSeconds)
  const hasNotSubmittedAssignment = status === 'draft' || !status
  const isPastLockTime = Date.now() > lockTimeInSeconds * 1000
  const isPassLockTimeWithoutSubmit = isPastLockTime && hasNotSubmittedAssignment

  let assignmentGrade = '0%'

  if (!hasNotSubmittedAssignment && isNaN(grade)) {
    assignmentGrade = 'Pending'
  } else if (grade) {
    assignmentGrade = `${grade}%`
  }

  const wordCount = !submissionTime || isPassLockTimeWithoutSubmit
    ? 'N/A'
    : content.text
      ? words(content.text).length
      : '--'

  const formattedSubmissionTime =
    secondsToFormattedDateTimeShort(submissionTime, 'short')
  const submissionTimeZone = getTimezoneShort(submissionTime)
  const submissionTimeText = !submissionTime || isPassLockTimeWithoutSubmit
    ? 'N/A'
    : `${formattedSubmissionTime} ${submissionTimeZone}`

  const submissionType = !submissionTime || isPassLockTimeWithoutSubmit
    ? 'N/A'
    : isTextEntry
      ? 'Text Entry'
      : 'File Upload'

  const showAnnotatedFeedbackArea = annotatedDocUrl && (feedback || grade)

  return (
    <ReviewContainer>
      {!submissionTime &&
      <SubmissionClosedMessage>
        Assignment submission closed on {assignmentLockDate} {assignmentLockTimeZone}.
        Please reach out to <a href='mailto:success@outlier.org'>success@outlier.org</a> with any questions.
      </SubmissionClosedMessage>}
      <ReviewItemsArea>
        <ReviewItem>
          <h4>score:</h4>
          <p>{assignmentGrade}</p>
        </ReviewItem>
        {!hasStarterFiles &&
          <ReviewItem data-testid='word-count'>
            <h4>word count:</h4>
            <p>{wordCount}</p>
          </ReviewItem>
        }
        <ReviewItem>
          <h4>submission time:</h4>
          <p>{submissionTimeText}</p>
        </ReviewItem>
        {!hasStarterFiles &&
        <ReviewItem data-testid='submission-type'>
          <h4>submission type:</h4>
          <p>{submissionType}</p>
        </ReviewItem>
        }
      </ReviewItemsArea>

      {submissionTime &&
      <ReviewItem
        style={{ marginBottom: showAnnotatedFeedbackArea ? '11px' : '35px' }}
      >
        <h4>Feedback:</h4>
        {isPassLockTimeWithoutSubmit &&
          <span style={{ fontSize: 'initial' }}>
            We did not receive your submission. If there’s been a mistake, please contact&nbsp;
            <a href='https://help.outlier.org/hc/en-us/requests/new'>
              Student Success.
            </a>
          </span>
        }
        {feedback && <RichTextEditor
          value={feedback}
          isDisabled
        />}
        {feedbackURL
          ? (
            <DownloadLink
              label={'View Additional Feedback'}
              link={feedbackURL} />
          )
          : <br />
        }
      </ReviewItem>}

      {answerKeyFile?.length > 0 &&
        <ReviewItem data-testid='answer-key-files'>
          <h4>Answer Key File:</h4>
          {answerKeyFile.map((file, index) => {
            const { title, url } = file || {}
            return (
              <AssignmentFileLink key={index} onClick={() => downloadFile(url)}>
                {title}
              </AssignmentFileLink>
            )
          })
          }
        </ReviewItem>
      }

      {isLoadingFeedback && (
        <div style={{ position: 'relative', width: '100%' }}>
          <LoadingSpinner />
        </div>
      )}

      {!showAnnotatedFeedbackArea && !isLoadingFeedback && !hideTextEntryField && !hasNotSubmittedAssignment && (
        <>
          {(isTextEntry || isTextFile) &&
          <Editor value={content.text} disabled /> }
          {isPdf &&
          <iframe
            src={`${content.url}#toolbar=0&navpanes=0`}
            title={title}
            data-testid='submission-view'
            style={{ width: '100%', height: '400px' }} />}
        </>
      )}

      {showAnnotatedFeedbackArea && (
        <>
          <ExternalLink
            style={{ marginBottom: '25px' }}
            onClick={handleDownloadSubmission}
          >
            <DownloadIcon />
            <DownloadText>
              download original submission
            </DownloadText>
          </ExternalLink>

          <AnnotatedFeedbackArea
            data-testid='annotated-feedback'
          >
            <p>Annotated Feedback</p>
            <iframe
              src={annotatedDocUrl}
              title={assignmentUUID}
              style={{ width: '100%', height: '594px' }}
            />
          </AnnotatedFeedbackArea>
        </>
      )}
    </ReviewContainer>
  )
}

export default AssignmentReviewPage
