import { diff } from 'just-diff'
import shuffle from 'lodash/shuffle'
import {
  changeTimezone,
  diffDays,
  dateToSecondsSinceEpoch,
  secondsSinceEpoch,
  addDaysToDateInSecs,
  subtractDaysFromDateInSecs,
  secondsToFormattedDay,
  secondsToFormattedDateShort
} from './dateTimeUtils'
import config from '../config'
import {
  PRACTICE_EXAM,
  SINGLE_MIDTERM_EXAM
} from '../Constants/examType'
import {
  AUDIT,
  WITHDRAW,
  NO_ACCESS_STUDENT_STATUSES,
  NEEDS_REVIEWING,
  NEEDS_REVIEWING_DEGREE,
  ACTIVE_STATUSES,
  COMPLETED
} from '../Constants/studentStatus'
import { NO_ACCESS_STATUS_NOTES } from '../Constants/statusNote'
import inRange from 'lodash/inRange'
import {
  STUDENT_ACCEPTED_NON_CERTIFICATE_WARNING,
  YELLOWDIG,
  GGU_DOMAIN,
  DISCORD,
  PROF_CERT_DOMAIN,
  EXT_DOMAIN
} from '../Constants'
import api from '../api'
import {
  CSAT_COURSE_START,
  CSAT_COURSE_START_39_WEEK,
  NPS_COURSE_END_V2
} from '../Constants/eventTypes'
import {
  INTENSIVE,
  INTENSIVE_COHORT_DURATION,
  MAX_INTENSIVE_COHORT_DURATION
} from '../Constants/cohortDuration'
import { VIP } from '../Constants/courseNames'
import { CHAPTER, EXAM, WRITING_ASSIGNMENT } from '../Constants/chapterType'
import { getPreviousSectionWithQuiz } from './sectionUtils'
import {
  getDynamicSchedules
} from '../Components/ResourcesSection/Utils'
import { AMAZON_RELATIONSHIP, GUILD_RELATIONSHIP } from '../Constants/voucher'
import { isRealExam, getExamDatesFromCohortExamDates } from './chapterUtils'
import moment from 'moment'

export {
  isInProgressBeforeCutOffDate,
  isCohortInProgress,
  isCohortEnded,
  isNewGoogleDataAnalyticsICohort,
  filterGoogleDataAnalyticsIChapters,
  shouldShowCurrentGrade,
  getNeedsReviewingAttempts,
  getAttemptToken,
  addTokensToActiveCourse,
  showNonCertificateWarning,
  sortByNearestExam,
  getNearestExam,
  getCoursesWithActiveAssignment,
  isAssignmentActive,
  getCohortStartSecondsSinceEpoch,
  getIsStudentWithdrawn,
  getIsVIPCohort,
  copyStudentProgress,
  getStudentProgressDiff,
  getLastRecommendedSectionId,
  getCohortDuration,
  courseResourcesScheduleFormatted,
  getCohortSpecialDays,
  getCohortCourseInfoUrl,
  getCohortModifier,
  getCohortExamDates,
  getCohortExamDateSecondsSinceEpoch,
  getCohortEndTimeSeconsSinceEpoch,
  getCohortStartEndDateShort,
  isPartnerCohort,
  getPartnerName,
  isStudioCohort,
  getTooltipText,
  getTooltipRecommededText,
  getTooltipRangeText,
  setChapterNumber,
  getFilteredChapters,
  getExamTitle,
  changeMidtermExamTitles,
  getOfficialCourseName,
  getCourseDisplayName,
  updateCourseData,
  getProjectedFinalGradeText,
  getExplanation,
  shouldShowFinalGrade,
  getValidDiscussionLinkType,
  getYellowdigUrl,
  getYellowdigCommunityLink,
  getCourseToolkitHeaderName,
  isAuditCohort,
  getNPSEvent,
  updateAssignmentFields,
  addPreviousSectionWithQuizForSections,
  getActiveCourse,
  getActiveCourses,
  isStandardCohort,
  hasCourseExam,
  getLatestCohort,
  getLatestCohortUpdate,
  getProgressBarColor,
  getFormattedSchedule,
  getRecommendedProgress,
  getIntensiveCohortWeeks,
  isStartDateEqualOrLaterThanDate,
  isHighSchoolCourse,
  isGGUCourse,
  getSyllabusUrl,
  getCohortSyllabus,
  getCourseSyllabusUrl,
  isWelcomePsychologyCourse,
  getCourseVersionByUrl,
  is39WHSCourse,
  checkIs39WeekCohort,
  addExamNumbersToCohortSyllabus,
  isProfessionalCertificateCourseFromCourseName,
  isProfessionalCertificateCourse,
  addExamNumbersToCourseData,
  areAllCoursesProfessionalCertificate,
  getCourseNameByHost
}

export const randomizeExamFromStrudentProgress = (
  questions,
  studentAnswers
) => {
  if (!studentAnswers || !questions?.length) return []

  const randomizedQuestions = []
  randomizedQuestions.length = questions.length

  questions.forEach(question => {
    const { Question_uuid: questionUUID } = question
    const { questionIndex } = studentAnswers?.[questionUUID] || {}

    if (questionIndex === undefined || questionIndex === null) return
    randomizedQuestions[questionIndex] = question
  })

  const questionsNotPositioned = questions.filter(question => (
    studentAnswers[question.Question_uuid]?.questionIndex === undefined ||
    studentAnswers[question.Question_uuid]?.questionIndex === null
  ))
  /**
   * in case student has not answered all the questions we need fill
   * the empty spaces in randomizedQuestions
   */
  questionsNotPositioned.forEach(question => {
    randomizedQuestions.find((q, index) => {
      if (!q) {
        randomizedQuestions[index] = question
        return true
      }
      return false
    })
  })

  return randomizedQuestions
}

export const randomizeExamFromLocalStorage = (questionOrder, questions) => {
  return questionOrder
  ?.map(questionUuid => {
    return questions.find(question => question.Question_uuid === questionUuid)
  })
}

export const saveRandomizedQuestionOrder = ({
  questionOrdersFromLocalStorage = {},
  randomizedQuestions = [],
  courseData = {},
  chapter = {},
  email,
  cohortId
}) => {
  const questionOrders = randomizedQuestions.map(question =>
      // eslint-disable-next-line
      question?.Question_uuid
  )

  let courseQuestionOrders = {}

  courseQuestionOrders = {
    ...questionOrdersFromLocalStorage,
    [courseData.course_uuid]: {
      ...questionOrdersFromLocalStorage?.[courseData.course_uuid],
      [cohortId]: {
        ...questionOrdersFromLocalStorage?.[courseData.course_uuid]?.[cohortId],
        [chapter.chapter_uuid]: questionOrders
      }
    }
  }

  localStorage.setItem(
    `questionOrders_${email}`,
    JSON.stringify(courseQuestionOrders)
  )
}

export const randomizeMultipleChoices = ({
  questions,
  courseData,
  email,
  cohortId,
  studentAnswers
} = {}) => {
  if (
    !questions?.length ||
    !courseData ||
    !email ||
    !cohortId
  ) return questions

  return questions?.map(question => {
    const {
      answer,
      options,
      question_type: type,
      Question_uuid: questionUUID,
      randomizeAnswers
    } = question || {}
    const courseUUID = courseData?.course_uuid ?? courseData?.uuid
    if (type !== 'Multiple Choice' || !randomizeAnswers) return question

    const correctAnswers = options.filter((option, index) =>
      answer.includes(String(index + 1))
    )

    const {
      choiceOrder,
      answer: studentAnswer
    } = studentAnswers?.[questionUUID] || {}

    let randomizedOptions

    if (studentAnswer) {
      if (!choiceOrder) return question
      randomizedOptions = choiceOrder.map(index => options[index])
      return {
        ...question,
        options: choiceOrder.map(index => options[index]),
        answer: correctAnswers.map(answer =>
          String(randomizedOptions.indexOf(answer) + 1)
        )
      }
    }

    const choiceOrdersFromLS = JSON.parse(
      localStorage.getItem(`choiceOrders_${email}`)
    )

    const randomOrder = choiceOrdersFromLS
      ?.[courseUUID]
      ?.[cohortId]
      ?.[questionUUID]

    if (randomOrder) {
      randomizedOptions = randomOrder.map(index => options[index])
      return {
        ...question,
        options: randomOrder.map(index => options[index]),
        answer: correctAnswers.map(answer =>
          String(randomizedOptions.indexOf(answer) + 1)
        )
      }
    }

    randomizedOptions = shuffle(options)

    localStorage.setItem(
      `choiceOrders_${email}`,
      JSON.stringify({
        ...choiceOrdersFromLS,
        [courseUUID]: {
          ...choiceOrdersFromLS?.[courseUUID],
          [cohortId]: {
            ...choiceOrdersFromLS?.[courseUUID]?.[cohortId],
            [questionUUID]: randomizedOptions.map(option => {
              return options.indexOf(option)
            })
          }
        }
      })
    )

    return {
      ...question,
      options: randomizedOptions,
      answer: correctAnswers.map(answer =>
        String(randomizedOptions.indexOf(answer) + 1)
      )
    }
  })
}

export const getCourseDataWithSuffledExamQuestions = ({
  courseData,
  email,
  studentProgress,
  cohortId
}) => {
  if (!courseData || !email || !cohortId) return courseData
  const { course_uuid: courseUUID } = courseData || {}

  const newCourseData = {
    ...courseData,
    chapters: courseData?.chapters?.map((chapter, index) => {
      if (chapter?.type !== 'exam') return chapter

      const { exams: { Question } = {} } = chapter
      const questionsWithRandomizedMultipleChoices = randomizeMultipleChoices({
        questions: Question,
        courseData,
        email,
        cohortId,
        studentAnswers: studentProgress?.studentAnswers
      })

      if (!chapter?.randomizeQuestionOrder) {
        return {
          ...chapter,
          exams: {
            Question: questionsWithRandomizedMultipleChoices
          }
        }
      }

      const { studentAnswers = {} } = studentProgress || {}
      const hasExamAnswers = questionsWithRandomizedMultipleChoices.some(question => (
        studentAnswers[question.Question_uuid]
      ))
      let randomizedQuestions
      /** 1. Check if there are questionIndex saved in the questions inside studentAnswers,
       * if there are, use the the values to shuffle the questions
         */
      if (hasExamAnswers) {
        randomizedQuestions = randomizeExamFromStrudentProgress(
          questionsWithRandomizedMultipleChoices,
          studentAnswers
        )
        /**
         * this will save the question order in case student has not completed the exam and there
         * are no questionIndex saved in the questions inside studentAnswers
         */
        saveRandomizedQuestionOrder({
          questionOrdersFromLocalStorage: {},
          randomizedQuestions,
          courseData,
          chapter,
          email,
          cohortId
        })

        return {
          ...chapter,
          exams: {
            Question: randomizedQuestions
          }
        }
      }

      const questionOrdersFromLocalStorage = JSON.parse(
        localStorage.getItem(`questionOrders_${email}`)
      ) || {}

      const storedChapterQuestionOrder = questionOrdersFromLocalStorage
        ?.[courseUUID]
        ?.[cohortId]
        ?.[chapter.chapter_uuid]
      /** 2. Check if there are ordered exam questions in local storage, if there are,
       use the order from local storage to shuffle the questions
        */
      if (storedChapterQuestionOrder) {
        randomizedQuestions = randomizeExamFromLocalStorage(storedChapterQuestionOrder, Question)

        return {
          ...chapter,
          exams: {
            Question: randomizedQuestions
          }
        }
      }
      /** 3. If there are no ordered exam questions in local storage, shuffle the questions
         and save the order to local storage
        */
      randomizedQuestions = shuffle(questionsWithRandomizedMultipleChoices)

      saveRandomizedQuestionOrder({
        questionOrdersFromLocalStorage,
        randomizedQuestions,
        courseData,
        chapter,
        email,
        cohortId
      })

      return {
        ...chapter,
        exams: {
          Question: randomizedQuestions
        }
      }
    })
  }

  return newCourseData
}

function isNewGoogleDataAnalyticsICohort (courseId, dateStart) {
  const isGoogleDataAnalyticsI = courseId === config.courseIds['dataanalytics-i.cert']
  const startsOnOrAfter14Jan2024 = new Date(dateStart) >= new Date('2024-01-14')

  return isGoogleDataAnalyticsI && startsOnOrAfter14Jan2024
}

function filterGoogleDataAnalyticsIChapters (chapters, courseId, dateStart) {
  if (!chapters?.length) return []

  const isNewGDAICohort = isNewGoogleDataAnalyticsICohort(courseId, dateStart)

  if (!isNewGDAICohort) return chapters

  return chapters.filter(({ type }) => type !== EXAM)
}

function getIntensiveCohortWeeks (schedules) {
  if (!schedules) return []
  const schedulesLength = schedules.length
  const scheduleBulkDays = []

  const scheduleWeeks = []
  let scheduleDayWeeks = []
  let nextScheduleDay = ''
  schedules.forEach((schedule, index) => {
    const nextIndex = index + 1
    if (nextIndex !== schedulesLength) {
      if (!schedules[nextIndex][0]) return
      nextScheduleDay = secondsToFormattedDay(schedules[nextIndex][0].startDateInSecs)
    }
    scheduleDayWeeks.push(schedule[0])
    if (nextScheduleDay === 'Mon') {
      scheduleBulkDays.push(scheduleDayWeeks)
      scheduleDayWeeks = []
    }
  })
  scheduleBulkDays.push(scheduleDayWeeks)
  scheduleBulkDays.forEach((scheduleDays, index) => {
    const lastIndex = scheduleDays.length - 1
    if (!scheduleDays[0]) return
    const scheduleWeek = {
      days: scheduleDays,
      week: index + 1,
      startDate: scheduleDays[0].startDate,
      startDateInSecs: scheduleDays[0].startDateInSecs,
      endDate: scheduleDays[lastIndex].endDate,
      endDateInSecs: scheduleDays[lastIndex].endDateInSecs,
      assignmentType: scheduleDays[0].assignmentType,
      title: scheduleDays[lastIndex].title
    }

    scheduleWeeks.push(scheduleWeek)
  })
  return scheduleWeeks
}

function getFilteredChapters (chapters, cohortExamDates = {}) {
  return chapters.filter((chapter) => {
    if (chapter.type !== 'exam') return true
    const hasQuestions = chapter?.exams?.Question?.length
    if (!hasQuestions) return false

    const examDates = getExamDatesFromCohortExamDates({
      exam: chapter,
      cohortExamDates
    })

    if (!examDates) return false
    const { startDate, endDate } = examDates

    // We don't want to display exam if there's no start date and end date from exam dates
    if (!startDate && !endDate) return false

    return true
  })
}

function updateCourseData (courseData, cohortExamDates) {
  const courseDataWithChapterNumber = setChapterNumber(courseData)

  const { chapters, course_uuid: courseId } = courseDataWithChapterNumber

  const updatedAssignmentChapters = updateAssignmentFields(chapters)
  const filteredChapters = getFilteredChapters(updatedAssignmentChapters, cohortExamDates)
  const updatedExamChapters = changeMidtermExamTitles(filteredChapters, courseId)
  const updatedChapters = addPreviousSectionWithQuizForSections(updatedExamChapters)
  return { ...courseDataWithChapterNumber, chapters: updatedChapters }
}

function getTooltipText (tooltipTextInfo) {
  let {
    cohortStartDate,
    isCohortStart,
    isCohortEnded,
    courseProgress,
    currentValueRP,
    lastWeekValueRP,
    lastTwoWeeksValueRP
  } = tooltipTextInfo
  cohortStartDate = secondsToFormattedDateShort(cohortStartDate)
  const tooltipText = [
    `Welcome! Your course starts ${cohortStartDate}. You can start Section 1 for a headstart!`,
    'Following our weekly work recommendation will keep you on track.',
    'Your workload is more than usual. Expect to spend a little extra time on coursework this week.',
    'Your workload has grown quite a bit. Start working through it, and contact us if you need help.',
    'You cleared out the old activities and are now on track!',
    'Great job! You’re all caught up. Keep going if you want to get ahead on next week’s activities.',
    'Look at you! You’re ahead of schedule.',
    'You’ve completed the final and all of your coursework!'
  ]

  if (!isCohortStart) return tooltipText[0]
  if (courseProgress > lastWeekValueRP && courseProgress < currentValueRP) return tooltipText[1]
  if (courseProgress <= lastTwoWeeksValueRP) return tooltipText[3]
  if (courseProgress > lastTwoWeeksValueRP && courseProgress <= lastWeekValueRP) return tooltipText[2]
  if (courseProgress < currentValueRP && courseProgress >= lastWeekValueRP) return tooltipText[4]
  if (!isCohortEnded && courseProgress === currentValueRP) return tooltipText[5]
  if (courseProgress > currentValueRP) return tooltipText[6]
  if (isCohortEnded && courseProgress === 100) return tooltipText[7]
}

function getTooltipRangeText (section, firstSection) {
  if (!section || !firstSection) return

  const { title: lastTitle, endDateInSecs } = section
  const { title: firstTitle, startDateInSecs } = firstSection

  const startDate = secondsToFormattedDateShort(startDateInSecs)
  const endDate = secondsToFormattedDateShort(endDateInSecs)

  const firstSectionTitle = firstTitle.split(' ')[0] + ' ' +
    firstTitle.split(' ')[1]
  const splitTitle = lastTitle.split(' ')
  const lastSectionTitle = splitTitle[splitTitle.length - 1]

  return `${startDate} - ${endDate}: ${firstSectionTitle} - ${lastSectionTitle}`
}

function getTooltipRecommededText (section) {
  if (!section) return
  const { title, startDateInSecs, endDateInSecs } = section
  const startDate = secondsToFormattedDateShort(startDateInSecs)
  let endDate = secondsToFormattedDateShort(endDateInSecs)
  const startDateSplited = startDate.split(' ')
  const endDateSplited = endDate.split(' ')
  if (startDateSplited[0] === endDateSplited[0]) endDate = endDateSplited[1]
  return `${startDate} - ${endDate}: ${title}`
}
function setChapterNumber (courseData) {
  if (!courseData) return {}
  const _courseData = JSON.parse(JSON.stringify(courseData))
  let chapterNumber = 0
  _courseData.chapters = _courseData.chapters.map((chapter) => {
    if (chapter.type === 'chapter') {
      chapterNumber = chapterNumber + 1
      chapter.chapterNumber = chapterNumber
      return chapter
    }
    return chapter
  })
  return _courseData
}

function getLastRecommendedSectionId (lastSection, chapters) {
  const { assignmentType } = lastSection
  const isExam = assignmentType === 'exam'
  const isEssay = assignmentType === 'essay'
  if (isExam) {
    const exams = chapters.filter(chapter => chapter.type === 'exam')
    return exams.find(exam => exam.examNumber === lastSection.examNumber).chapter_uuid
  } else if (isEssay) {
    let { title } = lastSection
    const assignments = chapters
      .filter(chapter => chapter.type === 'WritingAssignmentChapterRecord')

    const assignment = assignments.find(assignment => {
      // in some cases the title contains extra space or dashes
      title = title.replace(/\s/g, '').toLowerCase()
      assignment.title = assignment.title.replace(/-|\s/g, '').toLowerCase()
      return assignment.title.includes(title)
    })
    return assignment ? assignment.chapter_uuid : lastSection?.chapterUuid || ''
  } else {
    const sections = chapters
      .filter(chapter => chapter.type === 'chapter')
    const { title } = lastSection
    if (title.indexOf('.') === -1) return
    const sectionNumber = title.split(' ').slice(-1)[0].split('.')
    const chapter = parseInt(sectionNumber[0])
    const section = parseInt(sectionNumber[1])
    return sections?.[chapter - 1]?.sections?.[section - 1]?.['section_uuid']
  }
}

function getCohortStartSecondsSinceEpoch (latestCohort) {
  if (!latestCohort?.dateStart) return null

  // Use the string date part and set the time to 00:00 AM UTC
  const date = new Date(latestCohort.dateStart + 'T00:00:00')

  // Convert date to PST time.
  const datePST = changeTimezone(date, 'America/Los_Angeles')

  return Math.floor(datePST.getTime() / 1000)
}

// TODO: Tests to be added later. This is for the urgent student data loss PR.
function copyStudentProgress (studentData) {
  const studentStringify =
    JSON.stringify(studentData)
  return JSON.parse(studentStringify)
}

// TODO: Tests to be added later. This is for the urgent student data loss PR.
function getStudentProgressDiff (beforeStudentData, studentData) {
  const diffArray = diff(
    beforeStudentData,
    studentData
  )
  const diffString = JSON.stringify(diffArray)
  console.info(`studentData diff: ${diffString}`)
  return diffArray
}

function getCohortDuration (latestCohort) {
  if (!latestCohort?.duration) return

  // returns latest cohort duration from status data when available
  return latestCohort.duration
}

function courseResourcesScheduleFormatted (schedules = [], isIntensive = false) {
  const filterSchedule = schedule => schedule.week || schedule.day
  const filterIntensiveSchedule =
    schedule => schedule.week || schedule.day || schedule?.assignmentType === EXAM
  const weekDaySchedules = schedules
    .filter(isIntensive ? filterIntensiveSchedule : filterSchedule)
    .sort((a, b) => {
      return (a.week || a.day) - (b.week || b.day)
    })
  const uniqueWeekDaySchedules = []
  weekDaySchedules.forEach(schedule => {
    if (uniqueWeekDaySchedules[schedule.week - 1]) {
      uniqueWeekDaySchedules[schedule.week - 1].push(schedule)
    } else {
      uniqueWeekDaySchedules.push([schedule])
    }
  })
  return uniqueWeekDaySchedules
}

function getCohortSpecialDays (latestCohort = {}) {
  const { specialDaysDates, dateStart, cohortEndTime } = latestCohort || {}

  if (!specialDaysDates || !dateStart) return

  const cohortStartDate = dateToSecondsSinceEpoch(new Date(dateStart))
  const cohortEndDate = dateToSecondsSinceEpoch(new Date(cohortEndTime))

  const cohortSpecialDays = []
  specialDaysDates.forEach(({ dayStart, dayEnd, name }) => {
    const startDate = dateToSecondsSinceEpoch(new Date(dayStart))
    const endDate = dateToSecondsSinceEpoch(new Date(dayEnd))
    if (!inRange(startDate, cohortStartDate, cohortEndDate + 1)) return

    const specialDays = diffDays(cohortStartDate, startDate)
    const numberOfSpecialDays = diffDays(startDate, endDate)

    cohortSpecialDays.push({
      startDate,
      endDate,
      specialDays,
      numberOfSpecialDays,
      name
    })
  })

  return cohortSpecialDays
}

function getCohortCourseInfoUrl (course, isAdmin) {
  const latestCohort = getLatestCohort(course)
  const { courseInfoUrl } = latestCohort || {}
  if (!courseInfoUrl) return

  if (!isAdmin) return courseInfoUrl
  return getAdminCourseInfoUrl(courseInfoUrl)
}

/* For auth0 accounts that end with @outlier.org and the URL is of this format,
https://outlier.org/{courseName}-resources (including https://outlier.org/calculus-resources
and https://outlier.org/psychology-resources`), it updates those URLs to
https://www.resources.outlier.org/welcome. */
function getAdminCourseInfoUrl (courseInfoUrl) {
  if (courseInfoUrl === `https://outlier.org/${config.courseName}-resources`) {
    return 'https://www.resources.outlier.org/welcome'
  }
  return courseInfoUrl
}

// Returns true if cohort is partner.
function isPartnerCohort (latestCohort) {
  if (!latestCohort) return false

  const {
    isPartnerCohort: isPrivatePartnerCohort,
    relationship: { fields: { relationshipType = [] } = {} } = {}
  } = latestCohort
  return !!(isPrivatePartnerCohort || relationshipType.includes('partnership'))
}

function getPartnerName (latestCohort = {}) {
  return latestCohort?.relationship?.fields?.relationshipName
}

// Returns true if cohort is studio or feature flag is set
function isStudioCohort (latestCohort, isAdmin) {
  if (!latestCohort) return false

  const isStudioCohort = latestCohort.relationship?.fields?.accessType === 'studio'

  return !!(isStudioCohort || (config.isStudioCohort && isAdmin))
}

function getCohortModifier (chapters, cohortDuration) {
  if (!chapters || !cohortDuration) return
  const exams = chapters.filter((elem) => elem.type === 'exam')
  if (!exams.length) return
  const finalExam = exams[exams.length - 1]
  const { unlock_at_week: courseLength } = finalExam
  return courseLength ? cohortDuration / courseLength : cohortDuration
}

function addAssignmentLockUnlockDates (cohort) {
  const milestones = cohort.milestones
  const assignment = milestones && milestones.length &&
    milestones.find(milestone => milestone.finalAssignment)
  const { lockTime, unlockTime } = assignment || {}
  cohort.assignmentLockTime = lockTime
  cohort.assignmentUnlockTime = unlockTime
}

function getCohortExamDates (latestCohort) {
  const isInvalidCohort = !latestCohort?.dateStart
  if (isInvalidCohort) return {}

  addAssignmentLockUnlockDates(latestCohort)
  const {
    midTerm1StartTime,
    midTerm1EndTime,
    midTerm2StartTime,
    midTerm2EndTime,
    exam3StartTime,
    exam3EndTime,
    exam4StartTime,
    exam4EndTime,
    exam5StartTime,
    exam5EndTime,
    finalExamStartTime,
    assignmentLockTime,
    assignmentUnlockTime,
    // cohortEndTime is `finalExamEndTime` set in cohorts table
    cohortEndTime,
    /* finalExamEndTime is either `finalDeadline` set in `examExtensions`
      table or `finalExamEndTime` set in cohorts table */
    finalExamEndTime
  } = latestCohort

  return {
    midTerm1StartDate: getCohortExamDateSecondsSinceEpoch(midTerm1StartTime),
    midTerm1EndDate: getCohortExamDateSecondsSinceEpoch(midTerm1EndTime),
    // midterm2StartDate and midterm2EndDate will be used for midterm2Part1
    midTerm2StartDate: getCohortExamDateSecondsSinceEpoch(midTerm2StartTime),
    midTerm2EndDate: getCohortExamDateSecondsSinceEpoch(midTerm2EndTime),
    exam3StartDate: getCohortExamDateSecondsSinceEpoch(exam3StartTime),
    exam3EndDate: getCohortExamDateSecondsSinceEpoch(exam3EndTime),
    exam4StartDate: getCohortExamDateSecondsSinceEpoch(exam4StartTime),
    exam4EndDate: getCohortExamDateSecondsSinceEpoch(exam4EndTime),
    exam5StartDate: getCohortExamDateSecondsSinceEpoch(exam5StartTime),
    exam5EndDate: getCohortExamDateSecondsSinceEpoch(exam5EndTime),
    finalExamStartDate: getCohortExamDateSecondsSinceEpoch(finalExamStartTime),
    assignmentStartDate: getCohortExamDateSecondsSinceEpoch(assignmentUnlockTime),
    assignmentEndDate: getCohortExamDateSecondsSinceEpoch(assignmentLockTime),
    cohortEndDate: getCohortEndTimeSeconsSinceEpoch(cohortEndTime, latestCohort),
    courseEndDate: getCohortEndTimeSeconsSinceEpoch(finalExamEndTime, latestCohort)
  }
}

function getCohortEndTimeSeconsSinceEpoch (endTime, cohort) {
  if (
    endTime
  ) return getCohortExamDateSecondsSinceEpoch(endTime)

  // If `finalExamEndTime` is not set in the airtable,
  // use `cohortStartDate + duration`
  return getDefaultCourseEndTime(cohort)
}

function getCohortStartEndDateShort (cohort) {
  const { cohortEndTime, finalExamEndTime } = cohort || {}
  const cohortStartSeconds = getCohortStartSecondsSinceEpoch(cohort)
  const cohortEndSeconds = getCohortEndTimeSeconsSinceEpoch(
    cohortEndTime || finalExamEndTime, cohort)

  const formattedStartDate = secondsToFormattedDateShort(cohortStartSeconds)
  const formattedEndDate = secondsToFormattedDateShort(cohortEndSeconds)

  return `${formattedStartDate} - ${formattedEndDate}`
}

function getDefaultCourseEndTime (cohort) {
  const { dateStart, duration = 0 } = cohort

  const cohortDate = new Date(dateStart + 'T00:00:00')
  cohortDate.setDate(cohortDate.getDate() + 7 * duration)

  // Convert date to PST time.
  const cohortDatePST = changeTimezone(cohortDate, 'America/Los_Angeles')
  return dateToSecondsSinceEpoch(cohortDatePST)
}

function getCohortExamDateSecondsSinceEpoch (date) {
  return date ? dateToSecondsSinceEpoch(new Date(date)) : undefined
}

function getLatestCohort (course) {
  if (config.hasUseAttempt) return getLatestCohortUpdate(course)

  if (!course) return null

  const { statusData, cohort, id } = course
  if (!statusData || !statusData.length) {
    return cohort ? { ...cohort, courseId: id } : cohort
  }

  const assignedCohort = statusData.find(cohorts => cohorts.id === cohort.id)
  if (assignedCohort) {
    const { studentStatus, statusNote } = assignedCohort
    const hasAccessToTheCourse = !(
      NO_ACCESS_STATUS_NOTES.includes(statusNote) ||
      NO_ACCESS_STUDENT_STATUSES.includes(studentStatus)
    )
    if (
      hasAccessToTheCourse
    ) return { ...assignedCohort, name: assignedCohort.cohortName, courseId: id }
  }

  const sortedCohorts = statusData.sort((a, b) => {
    return new Date(b.dateStart) - new Date(a.dateStart)
  })

  const latest = sortedCohorts[0]
  return { ...latest, name: latest.cohortName, courseId: id }
}

function getLatestCohortUpdate (course) {
  if (!course) return null

  const { statusData, cohort, vip, id } = course || {}
  const isVIP = vip || cohort?.name.includes('VIP')
  const noAttempts = !statusData || !statusData.length
  if (noAttempts) return isVIP ? { ...cohort, courseId: id } : {}

  const sortedCohorts = statusData.filter(hasAccessToTheCourse).sort((a, b) => {
    return new Date(b.dateStart) - new Date(a.dateStart)
  })
  if (sortedCohorts.length === 1) return createCohortObject(sortedCohorts[0], id)

  const inProgressCohort = getInProgressCohort(sortedCohorts)
  if (inProgressCohort) return createCohortObject(inProgressCohort, id)

  const completedCohorts = getCompletedAttempts(sortedCohorts)
  const recentlyGradedCohort = getRecentlyGradedCohort(completedCohorts)
  if (recentlyGradedCohort) return createCohortObject(recentlyGradedCohort, id)

  const upcomingCohort = getUpcomingCohort(sortedCohorts)
  if (upcomingCohort) return createCohortObject(upcomingCohort, id)

  const completedCohort = getCompletedCohort(completedCohorts)
  if (completedCohort) return createCohortObject(completedCohort, id)

  return {}
}

export const getInProgressCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts.find(cohort => {
    const { dateStart, finalExamEndTime } = cohort
    const startDate = dateToSecondsSinceEpoch(new Date(dateStart))
    const endDate = dateToSecondsSinceEpoch(new Date(finalExamEndTime))

    return startDate <= today && today <= endDate
  })
}

export const getRecentlyGradedCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts?.find(cohort => {
    const { dateGradesSubmitted } = cohort
    const gradedDate =
      dateGradesSubmitted &&
      dateToSecondsSinceEpoch(new Date(dateGradesSubmitted))
    const gradedInLastThreeDays = diffDays(today, gradedDate, false) <= 3

    return !dateGradesSubmitted || gradedInLastThreeDays
  })
}

export const getUpcomingCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts
    .sort((a, b) => {
      return new Date(a.dateStart) - new Date(b.dateStart)
    })
    .find((cohort) => {
      const { dateStart } = cohort
      const startDate = dateToSecondsSinceEpoch(new Date(dateStart))
      return startDate > today
    })
}

export const getCompletedCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts?.find(cohort => {
    const { dateGradesSubmitted } = cohort
    const gradedDate = dateToSecondsSinceEpoch(new Date(dateGradesSubmitted))
    return diffDays(today, gradedDate, false) > 3
  })
}

const createCohortObject = (cohort, courseId) => {
  return {
    ...cohort,
    courseId,
    name: cohort.cohortName
  }
}

const getCompletedAttempts = (statusData) => {
  return statusData.filter(cohort => {
    const { studentStatus } = cohort
    return studentStatus === COMPLETED
  })
}

export const hasAccessToTheCourse = (cohort) => {
  const { studentStatus, statusNote } = cohort

  const hasAccessToTheCourse = !(
    NO_ACCESS_STATUS_NOTES.includes(statusNote) ||
    NO_ACCESS_STUDENT_STATUSES.includes(studentStatus)
  )
  return hasAccessToTheCourse
}

function getExamTitle (courseId, examTitle, isPracticeExam) {
  const isSingleMidtermExamCourse = config.isCourseWithSingleMidtermExam(courseId)
  if (isSingleMidtermExamCourse && !isPracticeExam) {
    return SINGLE_MIDTERM_EXAM
  }
  return examTitle
}

function changeMidtermExamTitles (chapters, courseId) {
  const modifiedChapters = chapters.map((chapter) => {
    const { type, title } = chapter
    if (type === 'exam') {
      const isPracticeExam = title === PRACTICE_EXAM
      const examTitle = getExamTitle(courseId, title, isPracticeExam)
      chapter.title = examTitle
    }
    return chapter
  })
  return modifiedChapters
}

function updateAssignmentFields (chapters) {
  const filteredChapters = []

  chapters.forEach(chapter => {
    const { type } = chapter

    if (type !== WRITING_ASSIGNMENT) return filteredChapters.push(chapter)

    const isPhilosophy = ['philosophy', 'philosophy.plus'].includes(config.courseName)

    if (!isPhilosophy) return filteredChapters.push(chapter)

    const {
      title,
      description,
      updateddescription,
      updateddisplaytitle
    } = chapter

    const updatedChapter = {
      ...chapter,
      title: updateddisplaytitle || title,
      description: updateddescription || description
    }

    filteredChapters.push(updatedChapter)
  })

  return filteredChapters
}

function addPreviousSectionWithQuizForSections (chapters) {
  return chapters.map(chapter => {
    const { type } = chapter

    if (type !== CHAPTER) return chapter

    const { sections } = chapter
    if (!sections.length) return chapter

    const sectionsWithPreviousSection = sections.map(section => {
      return {
        ...section,
        previousSectionWithQuiz: getPreviousSectionWithQuiz(
          section.section_uuid, chapters
        )
      }
    })

    return {
      ...chapter,
      sections: sectionsWithPreviousSection
    }
  })
}

function getOfficialCourseName (course) {
  if (!course) return ''

  return course.name
}

function getCourseDisplayName (course) {
  if (!course) return ''

  return course.displayName
}

//                         cohort ended                cohort not ended
//                         ------------                 ---------------
// in-progress cohorts     Final Grade: score%         ''
// upcoming cohorts        Final Grade: score%         Projected Grade: score%
// ended cohrots           ''                          ''
//
//

function getProjectedFinalGradeText (
  { cohortStartDate, courseEndDate, cohortEndDate }) {
  // isAlreadyEnded is relative to projected grade reference date.
  const isAlreadyEnded = isProjectedGradeEndedCohort(courseEndDate)
  if (isAlreadyEnded) return ''

  const showFinalGrade = shouldShowFinalGrade(courseEndDate, cohortEndDate)

  if (showFinalGrade) return 'Final Grade'
  return ''
}

function shouldShowFinalGrade (finalExamEndTime, cohortEndTime) {
  const cohortEndDate = new Date(cohortEndTime * 1000)
  cohortEndDate.setDate(cohortEndDate.getDate() + 7)
  const cohortEndTimeAfterAweek = dateToSecondsSinceEpoch(cohortEndDate)

  // finalExamEndTime === cohortEndTime means no final deadline
  // so final grade should be shown after a week from cohort end time
  if (
    finalExamEndTime === cohortEndTime
  ) return secondsSinceEpoch() >= cohortEndTimeAfterAweek

  const maxTime = Math.max(finalExamEndTime, cohortEndTimeAfterAweek)

  return secondsSinceEpoch() >= maxTime
}

function shouldShowCurrentGrade (finalExamEndTime, cohortEndTime) {
  const currentDate = secondsSinceEpoch()
  const cohortEndDate = new Date(cohortEndTime * 1000)
  cohortEndDate.setDate(cohortEndDate.getDate() + 7)
  const cohortEndTimeAfterAweek = dateToSecondsSinceEpoch(cohortEndDate)

  const maxTime = Math.max(finalExamEndTime, cohortEndTimeAfterAweek)

  return currentDate < maxTime
}

function isCohortEnded (finalExamEndTime, cohortEndTime) {
  const currentDate = secondsSinceEpoch()
  const maxTime = Math.max(finalExamEndTime, cohortEndTime)

  return currentDate > maxTime
}

// we want to hide Final grade for already ended cohorts relative to
// projected grade reference date.
function isProjectedGradeEndedCohort (cohortEndDate) {
  return cohortEndDate <= config.projectedGradeReferenceDate
}

function getExplanation (specificExplanation, generalExplanation) {
  if (!specificExplanation && !generalExplanation) return null
  return specificExplanation || generalExplanation
}

function getValidDiscussionLinkType (studentCourses) {
  const { courseId, isCollegeSuccessCourse } = config

  const isCollegeSuccess = isCollegeSuccessCourse()
  if (isCollegeSuccess) return DISCORD

  const currentDate = secondsSinceEpoch()
  const activeCourse = getActiveCourse(studentCourses, courseId)
  const latestCohort = getLatestCohort(activeCourse)
  const cohortStartDate = getCohortStartSecondsSinceEpoch(latestCohort)

  const cohortStartTime = cohortStartDate + (9 * 60 * 60)
  return currentDate >= cohortStartTime ? YELLOWDIG : null
}

async function getYellowdigUrl (cohortData) {
  const { cohortID, name } = cohortData
  let nonAuditCohort

  if (name.toLowerCase().includes('audit')) {
    nonAuditCohort = await api.getStandardCohort(cohortID)
  }

  const cohortIdForYellowdig = nonAuditCohort?.id || cohortID

  const {
    launchUrl,
    message
  } = await api.getYellowdigUrl(config.courseId, cohortIdForYellowdig)

  if (launchUrl) return launchUrl

  console.error('Error in getting yellowdig URL: ', message)
  return null
}

function getYellowdigCommunityLink (latestCohort = {}) {
  return latestCohort?.relationship?.fields?.yellowdigCommunityLink
}

function getNPSEvent (cohortData) {
  if (!cohortData) return

  const currentDate = secondsSinceEpoch()

  const {
    startDate: cohortStartDate,
    cohortExamDates,
    is39WeekCohort
  } = cohortData

  if (!cohortStartDate || !cohortExamDates) return

  const { courseEndDate } = cohortExamDates
  if (!courseEndDate) return

  const startDateAfter6Days = addDaysToDateInSecs(cohortStartDate, 6)
  const examEndDateBefore10Days = subtractDaysFromDateInSecs(courseEndDate, 10)
  const fiveWeeksAfterStartDate = addDaysToDateInSecs(cohortStartDate, 35)

  const shouldSendCSAT = !is39WeekCohort &&
    currentDate >= startDateAfter6Days &&
    currentDate < examEndDateBefore10Days

  const shouldSendCSATFor39WeekCohort = is39WeekCohort &&
    currentDate >= fiveWeeksAfterStartDate &&
    currentDate < examEndDateBefore10Days

  if (shouldSendCSAT) return CSAT_COURSE_START
  if (shouldSendCSATFor39WeekCohort) return CSAT_COURSE_START_39_WEEK

  if (currentDate >= examEndDateBefore10Days) return NPS_COURSE_END_V2
}

function isAuditCohort (cohortName) {
  if (!cohortName) return false

  const AUDIT_REGEX = /Audit/gi
  return AUDIT_REGEX.test(cohortName)
}

function isStandardCohort (duration) {
  return duration > 9
}

function getCourseToolkitHeaderName ({ courseName, cohortName, duration, isAuditor }) {
  if (!courseName) return ''

  let toolkitCourseName = courseName

  const isIntensiveCohort = duration <= INTENSIVE_COHORT_DURATION
  if (isIntensiveCohort) toolkitCourseName += ` (${INTENSIVE})`

  const isCohortAudit = isAuditCohort(cohortName)

  const isAudit = isAuditor || isCohortAudit
  if (isAudit) toolkitCourseName += ` (${AUDIT})`

  return toolkitCourseName
}

function getActiveCourse (studentCourses, courseId) {
  if (!studentCourses || !Array.isArray(studentCourses)) return undefined

  const course = studentCourses.find(elem => elem.id === courseId)

  return course
}

function getActiveCourses (courses = []) {
  const filteredCourses = courses.filter(
    course => !config.isCollegeSuccessCourse(course?.id)
  )

  const activeCourses = filteredCourses.filter(course => {
    const latestCohort = getLatestCohort(course) || {}
    const {
      dateStart, cohortEndTime, finalExamEndTime, studentStatus = ''
    } = latestCohort

    const currentDate = Date.now()
    const startDate = new Date(dateStart).getTime()
    const endDate = new Date(cohortEndTime || finalExamEndTime).getTime()

    const isActiveCohort = currentDate >= startDate && currentDate <= endDate
    const isActiveStudent = ACTIVE_STATUSES.includes(studentStatus)

    return isActiveCohort && isActiveStudent
  })

  return activeCourses
}

function hasCourseExam (chapters) {
  if (!chapters?.length) return false

  return chapters.some(chapter => {
    const { type } = chapter
    return type === EXAM
  })
}

function getCertificateCourses (certificate) {
  if (!certificate) return []

  const properties = [
    'requiredCourses',
    'mathElectives',
    'liberalArtsElectives',
    'businessElectives'
  ]
  const certificateCourses = properties.flatMap(
    property => Array.isArray(certificate[property])
      ? certificate[property]
      : []
  )
  return certificateCourses
}

function showNonCertificateWarning (latestCohort) {
  if (!latestCohort) return false

  const partnerName = getPartnerName(latestCohort)
  if (partnerName === AMAZON_RELATIONSHIP) return false

  const { courseId, originalCertificate, certificate, token } = latestCohort

  const cohortPurchase = token?.certificate || certificate
  const certificatePurchase = token?.originalCertificate ||
    originalCertificate

  const noCertificatePurchase =
    (!certificatePurchase || certificatePurchase.length === 0) &&
    (!cohortPurchase || cohortPurchase.length === 0)

  if (noCertificatePurchase) return false

  const studentAcceptedWarning = localStorage.getItem(
    STUDENT_ACCEPTED_NON_CERTIFICATE_WARNING
  ) || ''
  if (studentAcceptedWarning === 'true') return false

  const { giftCardProduced, name: certificateName } = certificatePurchase || {}
  const { giftCardUsed, name } = cohortPurchase || {}
  const cohortHasCerificateGiftCard = certificateName === name &&
    giftCardProduced === giftCardUsed
  if (!cohortHasCerificateGiftCard && partnerName !== GUILD_RELATIONSHIP) {
    return true
  }

  const certificateCourses = getCertificateCourses(
    certificatePurchase || cohortPurchase
  )
  const isCertificateCourse = certificateCourses.some(course => {
    return course?.uuid === courseId
  })
  return !isCertificateCourse
}

const examDateFields = {
  midTerm1StartDate: 'midTerm1EndDate',
  midTerm2StartDate: 'midTerm2EndDate',
  exam3StartDate: 'exam3EndDate',
  exam4StartDate: 'exam4EndDate',
  exam5StartDate: 'exam5EndDate',
  finalExamStartDate: 'courseEndDate',
  assignmentStartDate: 'assignmentEndDate'
}

function isAssignmentActive (startDate, endDate) {
  const currentDate = secondsSinceEpoch()
  return currentDate < endDate && currentDate > startDate
}

function getCoursesWithActiveAssignment (courses) {
  return courses.filter(node => {
    const latestCohort = getLatestCohort(node)
    const examDates = getCohortExamDates(latestCohort)
    return Object.keys(examDateFields).some(key => {
      const startDate = examDates[key]
      const endDate = examDates[examDateFields[key]]
      return isAssignmentActive(startDate, endDate)
    })
  })
}

function sortByNearestExam (courses) {
  return courses.sort((a, b) => {
    const nearestExamA = getNearestExam(a)
    const nearestExamB = getNearestExam(b)
    return nearestExamA - nearestExamB
  })
}

function getNearestExam (course) {
  const latestCohort = getLatestCohort(course)
  const examDates = getCohortExamDates(latestCohort)
  const date = Object.values(examDateFields)
    .map(key => examDates[key])
    .sort((a, b) => a - b)[0]
  return date
}

function getIsStudentWithdrawn (studentStatus) {
  return studentStatus === WITHDRAW
}

function getIsVIPCohort (cohortName) {
  return cohortName && cohortName.includes(VIP)
}

function getFormattedSchedule (courseResourcesSchedule, isIntensive = false) {
  const formattedSchedules = courseResourcesScheduleFormatted(
    courseResourcesSchedule, isIntensive
  )
  if (formattedSchedules.length === 0) return []
  const isStandard = formattedSchedules[0][0].week !== undefined
  if (isStandard) {
    return formattedSchedules.map(formattedSchedule => formattedSchedule[0])
  }
  return isStandard
    ? formattedSchedules.map(formattedSchedule => formattedSchedule[0])
    : getIntensiveCohortWeeks(formattedSchedules)
}

function getRecommendedProgress (isCohortEnded, courseResourcesSchedule) {
  const formattedSchedules = getFormattedSchedule(courseResourcesSchedule)
  const cohortSchedulesLength = formattedSchedules.length
  let recommendedProgressWeek = getDynamicSchedules(formattedSchedules)[0]
  let lastWeek = formattedSchedules[cohortSchedulesLength - 1]
  if (!recommendedProgressWeek || isCohortEnded) {
    recommendedProgressWeek = lastWeek
  }

  const indexOfRP = recommendedProgressWeek?.week - 1
  const recommendedProgressLastWeek = formattedSchedules[indexOfRP - 1]
  const recommendedProgressLastTwoWeeks = formattedSchedules[indexOfRP - 2]

  lastWeek = lastWeek?.week
  const recommendedProgressWeekValue =
    (recommendedProgressWeek?.week / lastWeek) * 100
  const oneWeekRecommendedProgress =
    (recommendedProgressLastWeek?.week / lastWeek) * 100 || 0
  const twoWeekRecommendedProgress =
    (recommendedProgressLastTwoWeeks?.week / lastWeek) * 100 || 0

  return {
    currentRP: recommendedProgressWeek,
    lastWeekRP: recommendedProgressLastWeek,
    lastTwoWeeksRP: recommendedProgressLastTwoWeeks,
    values: {
      currentValueRP: Math.round(recommendedProgressWeekValue),
      lastWeekValueRP: Math.round(oneWeekRecommendedProgress),
      lastTwoWeeksValueRP: Math.round(twoWeekRecommendedProgress)
    }
  }
}

function getProgressBarColor (
  courseProgress,
  isCohortEnded,
  courseResourcesSchedule,
  showDefaultColor = false
) {
  const {
    values: { currentValueRP, lastWeekValueRP, lastTwoWeeksValueRP }
  } = getRecommendedProgress(isCohortEnded, courseResourcesSchedule)
  const isGreaterThanOneWeek = courseProgress >= lastWeekValueRP
  const isGreaterThanTwoWeeks = courseProgress >= lastTwoWeeksValueRP
  const isCompleted = courseProgress >= currentValueRP && isCohortEnded
  return isCompleted || showDefaultColor
    ? '#FFFFFF'
    : courseProgress >= currentValueRP
      ? '#969BFF'
      : isGreaterThanOneWeek
        ? '#FFFFFF'
        : isGreaterThanTwoWeeks
          ? '#FFB800'
          : '#F2765A'
}

function isStartDateEqualOrLaterThanDate (dateToCompare, dateToCompareWith) {
  if (!dateToCompare || !dateToCompareWith) return false

  const startDateInLAtime = getCohortStartSecondsSinceEpoch(
    { dateStart: dateToCompareWith })

  return dateToCompare >= startDateInLAtime
}

function getAttemptToken (tokens, attempt) {
  return tokens?.find(token => {
    return attempt?.id === token?.attemptCohort?.id
  })
}

function addTokensToActiveCourse (courses, currentCourseId, tokens) {
  if (!tokens?.length) return courses

  return courses?.map((course) => {
    if (course.id !== currentCourseId) return course

    const { cohort, statusData } = course
    course.cohort = {
      ...course.cohort,
      token: { ...getAttemptToken(tokens, cohort) }
    }
    course.statusData = statusData?.map(attempt => {
      return {
        ...attempt,
        token: { ...getAttemptToken(tokens, attempt) }
      }
    })
    return course
  })
}

function getNeedsReviewingAttempts (attempts) {
  if (!attempts?.length) return []

  return attempts.filter(attempt => {
    const { Name: status } = attempt.fields?.studentStatus?.fields || {}
    return status === NEEDS_REVIEWING || status === NEEDS_REVIEWING_DEGREE
  })
}

function isHighSchoolCourse (activeCourse) {
  return activeCourse?.statusData?.some(
    attempt => attempt?.relationship?.fields?.partnerType === 'high school')
}

function isWelcomePsychologyCourse () {
  return ['welcome.psychology'].includes(config.courseName)
}

function isGGUCourse () {
  return window?.location?.hostname?.endsWith(`.${GGU_DOMAIN}`) ||
    config.courseName.endsWith('.plus')
}

function getSyllabusUrl (syllabusItem) {
  return syllabusItem?.file?.url ?? ''
}

function getCohortSyllabus (syllabus, cohortDuration, cohortStartDate) {
  if (!syllabus?.length) return null

  const matchedCohortSyllabus = findCohortSyllabusByType(syllabus, cohortDuration)
  const syllabusesWithDate = getSyllabusWithDate(syllabus)

  if (syllabusesWithDate.length) {
    const syllabusesWithDateSorted = syllabusesWithDate.sort((a, b) => {
      return getSyllabusDateInSeconds(b) - getSyllabusDateInSeconds(a)
    }) || []

    const matchedSyllabusWithDate = syllabusesWithDateSorted.find((syllabusItem) => {
      const syllabusDateInSeconds = getSyllabusDateInSeconds(syllabusItem)
      return syllabusDateInSeconds <= cohortStartDate
    })

    if (matchedSyllabusWithDate) return matchedSyllabusWithDate
  }

  if (!syllabusesWithDate.length && !matchedCohortSyllabus) return syllabus[syllabus.length - 1]
  if (!matchedCohortSyllabus) return syllabus[0]

  return matchedCohortSyllabus
}

function findCohortSyllabusByType (syllabus, cohortDuration) {
  const isCohortStandard = isStandardCohort(cohortDuration)
  const isIntensiveCohort = cohortDuration <= MAX_INTENSIVE_COHORT_DURATION

  return syllabus.find((syllabusItem) => {
    const isStandard = syllabusItem.slug.includes('standard')
    const isIntensive = syllabusItem.slug.includes('intensive')

    if (isCohortStandard && isStandard) return true
    return !!(isIntensiveCohort && isIntensive)
  })
}

function getSyllabusWithDate (syllabus) {
  const filteredSyllabus = syllabus.filter(({ slug }) => {
    const dateRegex = /\d{2}-\d{2}-\d{4}/
    const date = slug.match(dateRegex)
    return moment(date, 'MM-DD-YYYY', true).isValid()
  })
  return filteredSyllabus
}

function getSyllabusDateInSeconds ({ slug }) {
  const dateRegex = /\d{2}-\d{2}-\d{4}/
  const date = slug.match(dateRegex)[0]
  const syllabusDateInSecs = moment(date, 'MM-DD-YYYY').toDate()
  return dateToSecondsSinceEpoch(syllabusDateInSecs)
}

function getCourseSyllabusUrl ({
  syllabus = [],
  toolkitSyllabus = [],
  isGGUCohort,
  cohortDuration,
  cohortStartDate
}) {
  // The order is important here, the toolkit syllabus should come first
  const allSyllabuses = [...(isGGUCohort ? toolkitSyllabus : []), ...syllabus]
  const cohortSyllabus = getCohortSyllabus(allSyllabuses, cohortDuration, cohortStartDate)

  return getSyllabusUrl(cohortSyllabus)
}

function getNumberOfExamsFromCohortSyllabus (syllabus) {
  if (!syllabus?.syllabusData) return 0

  const { syllabusData } = syllabus

  let examNumber = 0

  syllabusData.forEach(week => {
    const { sections = [] } = week

    sections.forEach(section => {
      const { assignmentType } = section
      if (assignmentType !== 'exam') return

      examNumber += 1
    })
  })

  return examNumber
}

function addExamNumbersToCohortSyllabus (syllabus) {
  if (!syllabus?.syllabusData) return syllabus

  const { syllabusData } = syllabus

  let examNumber = 0
  const numberOfExams = getNumberOfExamsFromCohortSyllabus(syllabus)

  const updatedSyllabusData = syllabusData.map(week => {
    const { sections = [] } = week

    const updatedSections = sections.map(section => {
      const { assignmentType } = section
      if (assignmentType !== 'exam') return section

      examNumber += 1

      return {
        ...section,
        examNumber,
        // We do not want to treat the last exam as final exam if the course
        // has no final exam
        isFinalExam: config.course.hasFinalExam && examNumber === numberOfExams
      }
    })

    return {
      ...week,
      sections: updatedSections
    }
  })

  return {
    ...syllabus,
    syllabusData: updatedSyllabusData
  }
}

function getCourseVersionByUrl (url, courseName) {
  if (!url || !courseName) return null
  const regex = new RegExp(`${courseName}\\.(\\w+)\\.outlier\\.org`)
  const match = url.match(regex)
  return match ? match[1] : null
}

function getCourseNameByHost (hostName) {
  const courseNameRegex = /(.+?)(?:-pwa)?(\.ext|\.beta|\.firstedition)?\.outlier\.org/
  const [, courseName] = courseNameRegex.exec(hostName) || []
  return courseName || null
}

function is39WHSCourse () {
  return window.location.hostname.endsWith(`.${EXT_DOMAIN}`) ||
    config.courseName.endsWith('.ext')
}

function checkIs39WeekCohort ({ officialCourseName = '', duration }) {
  return is39WHSCourse() || duration === 39 || officialCourseName?.includes('EXT')
}

function addExamNumbersToCourseData (courseData) {
  if (!courseData?.chapters) return courseData

  const { chapters } = courseData
  if (!chapters?.length) return courseData

  let examNumber = 0

  const realExamsLength = chapters.filter(isRealExam).length

  const chaptersWithExamNumbers = chapters.map(chapter => {
    if (!isRealExam(chapter)) return chapter

    examNumber += 1

    return {
      ...chapter,
      examNumber,
      // We do not want to treat the last exam as final exam if the course
      // has no final exam
      isFinalExam: config.course.hasFinalExam && examNumber === realExamsLength
    }
  })

  return {
    ...courseData,
    chapters: chaptersWithExamNumbers
  }
}

function isInProgressBeforeCutOffDate (params) {
  const {
    dateStart, finalExamEndTime, cohortEndTime, duration, officialCourseName
  } = params || {}
  const isExtendedCohort = checkIs39WeekCohort({ officialCourseName, duration })
  if (isExtendedCohort) return false

  const isStartingAfterCutOffDate =
    dateToSecondsSinceEpoch(new Date(dateStart)) >= config.hideQuizForAuditorsCutOffDate
  if (isStartingAfterCutOffDate) return false

  return isCohortInProgress(dateStart, finalExamEndTime, cohortEndTime)
}

function isCohortInProgress (dateStart, finalExamEndTime, cohortEndTime) {
  if (!dateStart || !finalExamEndTime) return false

  const currentDate = Date.now()
  const startDate = new Date(dateStart).getTime()
  if (currentDate < startDate) return false

  const cohortEndDate = cohortEndTime ? new Date(cohortEndTime).getTime() : 0
  const extendedEndDate = new Date(finalExamEndTime).getTime()
  const maxEndDate = Math.max(cohortEndDate, extendedEndDate)
  return currentDate <= maxEndDate
}

function isProfessionalCertificateCourseFromCourseName () {
  return window.location.hostname.endsWith(`.${PROF_CERT_DOMAIN}`) ||
    config.courseName.endsWith('.cert')
}

function isProfessionalCertificateCourse (course) {
  return course?.profCert
}

function isFreeCourse (courseId) {
  return config.freeCourseIds.includes(courseId)
}

function getPaidCourses (courses) {
  return courses?.filter(course => !isFreeCourse(course.id))
}

function areAllCoursesProfessionalCertificate (courses) {
  const paidCourses = getPaidCourses(courses)
  if (!paidCourses?.length) return false

  return paidCourses.every(isProfessionalCertificateCourse)
}
