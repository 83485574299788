import React, { Component } from 'react'
import {
  ACTIVE_LEARNING,
  EXAM,
  GUESSWORK,
  PRACTICE_EXERCISES,
  QUIZ
} from '../../Constants/sectionType'
import { TabContent } from 'reactstrap'
import { isClickable } from '../../utilities'
import Context from '../Context/Context'
import InstructorQuestionSet from '../InstructorQuestionSet/InstructorQuestionSet'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import { emitter } from '../Emitter/Emitter'

import { TRUE_FALSE } from '../../Constants/questionType'
import { getUniqueInstructorsFromQuestionSet } from './utils/helpers'
import { ON_INSTRUCTOR_SWITCH, ON_NAVIGATE_TO } from '../../Constants/emitterKeys'
import isEqual from 'lodash/isEqual'
import { getProblemSetQuestions } from '../../utilities/problemBankUtils'
import { startProctorioRecord, isExamRetakeType } from '../../utilities/examUtils'
import { isPracticeExam } from '../../utilities/chapterUtils'
import { EXAM_COMPLETE, EXAM_SECTION } from '../../Constants/studentContext'
import ExitModal from './ExitModal'
import { QUESTION_PROBLEM_BANK } from '../../Constants/screenChildTypes'
import {
  isAnswersLessThan70Per
} from '../../utilities/globalInstructorSwitchUtils'
import { isOutlierAccount } from '../../utilities/userUtils'
import api from '../../api'
import { EXCLUDED_COURSES } from '../../Constants/courseNames'
import AIViolationModal from './AIViolationModal'
import classNames from 'classnames'
import AIWarning from '../ExamKeysPage/AIWarninng/AIWarning'

class GlobalInstructorSwitch extends Component {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.handleReviewMode = this.handleReviewMode.bind(this)
    this.isRelevantQuestionType = this.isRelevantQuestionType.bind(this)
    this.setShowExitModal = this.setShowExitModal.bind(this)
    this.setShowAIViolationModal = this.setShowAIViolationModal.bind(this)
    this.setDisableAIViolationModal = this.setDisableAIViolationModal.bind(this)
    this.clickOutsideHandler = this.clickOutsideHandler.bind(this)
    this.wrapperRef = React.createRef()

    this.state = {
      outlierTabsOpen: 0,
      activeTab: 0,
      currentInstructor: null,
      showExitModal: false,
      showAIViolationModal: false,
      numberOfAIViolations: 0,
      disableAIViolationModal: false,
      key: 0,
      shouldShowAIWarning: true
    }
    this.reviewMode = false
    this.eventStore = null
    this.target = null
  }

  setShowExitModal (showExitModal) {
    this.setState({ showExitModal: showExitModal })
  }

  setShowAIViolationModal (showAIViolationModal) {
    this.setState({ showAIViolationModal: showAIViolationModal })
    const isSecondAIViolation = this.state.numberOfAIViolations === 2
    // reload the page to land on exam locked page
    if (isSecondAIViolation) { this.setState({ key: Math.random() }) }
  }

  setDisableAIViolationModal (disableAIViolationModal) {
    this.setState({ disableAIViolationModal: disableAIViolationModal })
    if (disableAIViolationModal === false) {
      this.setState({ numberOfAIViolations: 0 })
    }
  }

  handleStay = () => {
    document.addEventListener('click', this.clickOutsideHandler, true)
    this.setShowExitModal(false)
  }

  handleLeave = () => {
    this.state.target.dispatchEvent(this.state.eventStore)
  }

  handleReviewMode (questionType) {
    if (questionType !== QUIZ && questionType !== EXAM) return
    const uuid = this.getExamVersionUUID(questionType)
    if (!uuid) return

    let sectionData = []
    const isExamPractice = isPracticeExam(uuid)
    const examKey = isExamPractice ? EXAM_COMPLETE : EXAM_SECTION
    const section = questionType === QUIZ ? 'quiz-section' : examKey
    sectionData = this.context.studentData[section]
    if (!sectionData) return
    const percentageExist = uuid in sectionData
    this.reviewMode = percentageExist
      ? { percentage: sectionData[uuid] } : false
  }

  getExamVersionUUID (questionType) {
    const { completeScreenData, currentQuestion: { practiceUUID } } = this.props
    const { examRetake, cohortData } = this.context
    const isRetakeAllowed = isExamRetakeType({
      examRetake,
      cohortId: cohortData.cohortID,
      chapter: completeScreenData?.chapter
    })
    if (questionType !== EXAM || !isRetakeAllowed) return practiceUUID

    const {
      completeScreenData: { data: { versionUUID } }
    } = this.props
    return versionUUID
  }

  handleContextMenu = (event) => {
    const { isAdmin, isSavvasAndHasAdminAccess } = this.context
    if (!isAdmin && !isSavvasAndHasAdminAccess) {
      event.preventDefault()
    }
  }

  handleMouseDown = (event) => {
    if (event.button === 2) {
      event.preventDefault()
    }
  }

  handleKeyDown = (event) => {
    // Prevent Ctrl+C copy on Windows and Cmd+C on Mac
    if ((navigator.platform === 'Win32' || navigator.platform === 'Win64') && event.ctrlKey && event.key === 'c') {
      event.preventDefault()
    } else if (navigator.platform === 'MacIntel' && event.metaKey && event.key === 'c') {
      event.preventDefault()
    }
  }

  lockExam = async (examId) => {
    await api.setStudentSectionProgress(
      'exam-locked',
      { [examId]: true }
    )
  }

  handleTabVisibilityChange = async () => {
    if (!document.hidden || this.state.outlierTabsOpen > 0) return

    const activeElement = document.activeElement
    if (activeElement && activeElement.tagName === 'IFRAME') return

    const {
      activeCourse: { displayName } = {},
      cohortData,
      isSavvasAndHasAdminAccess,
      isVIPCourse,
      isAdmin
    } = this.context
    const { completeScreenData: { uuid: examId } } = this.props || {}

    const {
      relationship: { fields: { liveProctoring } = {} } = {}
    } = cohortData || {}

    if (EXCLUDED_COURSES.includes(displayName)) return

    const excludeFromAIViolations = isSavvasAndHasAdminAccess ||
        isAdmin || isVIPCourse

    if (!this.state.disableAIViolationModal && !excludeFromAIViolations) {
      const isFirstAIViolation = this.state.numberOfAIViolations === 0
      const isSecondAIViolation = this.state.numberOfAIViolations === 1 &&
      liveProctoring
      if (isFirstAIViolation || isSecondAIViolation) {
        if (isSecondAIViolation) {
          await this.lockExam(examId)
          // since we are locking the exam, we remove ongoing-exam from localStorage
          localStorage.removeItem('ongoing-exam')
        }
        this.setShowAIViolationModal(true)
      }
      this.setState({ numberOfAIViolations: this.state.numberOfAIViolations + 1 })
      this.alertSuccess('new-tab')
    }
  }

  alertSuccess = async (type) => {
    const { completeScreenData: { title: examName } } = this.props || {}
    const { cohortData } = this.context || {}
    const {
      cohortID,
      relationship: { fields: { liveProctoring } = {} } = {}
    } = cohortData || {}
    const flagType = (this.state.numberOfAIViolations === 2 && liveProctoring)
      ? 'Removal' : 'Warning'
    const payload = {
      cohortId: cohortID,
      examName: examName,
      timestamp: new Date().toISOString(),
      type: type,
      flagType: flagType
    }

    await api.alertExamViolation(payload)
    if (type === 'new-outlier-tab') this.setState({ outlierTabsOpen: 0 })
  }

  handleStorageChange = () => {
    const outlierTabsOpen = Object.keys(localStorage)
      .filter(key => key.startsWith('tabID')).length
    this.setState({ outlierTabsOpen })

    if (outlierTabsOpen > 0) {
      this.alertSuccess('new-outlier-tab')
    }
  }

  handleUnload = () => {
    localStorage.removeItem('ongoing-exam')
  }

  updateExamActivityTimestamp = () => {
    localStorage.setItem('examActivityTimestamp', Date.now().toString())
  };

  async componentDidMount () {
    // Initial activity timestamp update
    this.updateExamActivityTimestamp()

    setInterval(this.updateExamActivityTimestamp, 5000)

    const {
      isVIP,
      cohortData
    } = this.context
    const {
      relationship: { fields: { liveProctoring } = {} } = {}
    } = cohortData || {}

    const { currentQuestion } = this.props

    const { chapterUUID } = currentQuestion
    const { studentData } = this.context

    const sectionData = studentData?.[EXAM_SECTION]
    const isReviewMode = sectionData && chapterUUID in sectionData

    const {
      questionSet,
      currentQuestion: {
        forcePopQuestionUUID,
        quest_type: questType,
        type,
        data: {
          problemBank,
          currentProblemSetUUID,
          courseSections
        }
      },
      activeSectionUUID
    } = this.props
    const isExam = questType === EXAM
    const isProblemBank = type === QUESTION_PROBLEM_BANK

    if (isExam && !isReviewMode) {
      localStorage.setItem('ongoing-exam', 'true')
      window.addEventListener('beforeunload', this.handleUnload)
      document.addEventListener('visibilitychange', this.handleTabVisibilityChange)
      window.addEventListener('storage', this.handleStorageChange)
      document.addEventListener('contextmenu', this.handleContextMenu)
      if (liveProctoring) {
        document.addEventListener('mousedown', this.handleMouseDown)
        document.addEventListener('keydown', this.handleKeyDown)
      }
    }

    let currentQuestionSet = questionSet &&
      questionSet.length > 0 && [...questionSet]

    if (isVIP && isExam) return emitter.emit(ON_NAVIGATE_TO, '/')
    if (isExam) startProctorioRecord()

    if (isProblemBank && !currentQuestionSet) {
      currentQuestionSet = await getProblemSetQuestions(
        this.context,
        { problemBank,
          currentProblemSetUUID,
          forcePopQuestionUUID,
          forceQuestionUUID: currentQuestion.questionUUID },
        { activeSectionUUID, courseSections })
      if (!currentQuestionSet || !currentQuestionSet.length) {
        emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/practice_exercises')
        return
      }
    }

    if (!currentQuestionSet) return

    if (questType === PRACTICE_EXERCISES) {
      currentQuestionSet = this.updateQuestionType(currentQuestionSet)
    }
    this.setState({
      questionSet: currentQuestionSet,
      activeTab: this.context.course['currentInstructor'] || 0,
      currentInstructor: this.context.course['currentInstructor'] || null
    })

    if (!currentQuestion) return
    const { quest_type: questionType } = currentQuestion
    if (questionType !== GUESSWORK &&
      questionType !== QUIZ &&
      questionType !== EXAM) {
      const activeQuestionset = currentQuestionSet.find((question) =>
        question.Question_uuid === currentQuestion.uuid
      )
      const { instructor_uuid: instructorUuid } = activeQuestionset?.instructor || {}
      const { studentData, user: { email } } = this.context
      if (activeQuestionset) {
        this.toggle(instructorUuid)
        const { type: chapterType } = this.props.completeScreenData.chapter
        const isAnswerLessThan70 = isAnswersLessThan70Per(
          studentData,
          questionSet,
          instructorUuid,
          chapterType
        )
        const isActiveLearning = this.isActiveLearning()
        const shouldAddEventListener = isOutlierAccount(email) ||
          isAnswerLessThan70
        if (isActiveLearning && shouldAddEventListener) {
          document.addEventListener(
            'click',
            this.clickOutsideHandler,
            true)
        }
      }
    }
    this.handleReviewMode(questionType)
  }

  componentDidUpdate (prevProps) {
    const { questionSet: currentQuestionSet } = this.props
    const { questionSet: previousQuestionSet } = prevProps
    const { studentData, user: { email } = {} } = this.context
    if (this.isActiveLearning()) {
      const { type: chapterType } = this.props.completeScreenData.chapter
      const currentInstructor = this.state.currentInstructor
      const isLessThan70Percent = isAnswersLessThan70Per(
        studentData,
        currentQuestionSet,
        currentInstructor,
        chapterType
      )
      if (!isLessThan70Percent || isOutlierAccount(email)) {
        document.removeEventListener('click', this.clickOutsideHandler, true)
      }
    }
    if (!isEqual(previousQuestionSet, currentQuestionSet)) {
      this.setState({ questionSet: currentQuestionSet })
    }
  }

  componentWillUnmount () {
    localStorage.removeItem('ongoing-exam')
    window.removeEventListener('beforeunload', this.handleUnload)
    window.removeEventListener('storage', this.handleStorageChange)
    document.removeEventListener('visibilitychange', this.handleTabVisibilityChange)

    document.removeEventListener('contextmenu', this.handleContextMenu)
    document.removeEventListener('mousedown', this.handleMouseDown)
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('click', this.clickOutsideHandler, true)
  }

  isChildComponent = event => {
    const parent = this.wrapperRef.current
    const child = event.target

    if (parent && parent.contains(child)) return true

    const children = [
      'btn-confirm',
      'btn-next',
      'btn-previous',
      'img-close',
      'btn-stay',
      'btn-lets-try',
      'btn-leave'
    ]
    const targetId = child.getAttribute('data-testid')
    return children.includes(targetId)
  }

  clickOutsideHandler (e) {
    const event = new e.constructor(e.type, e)
    const { target } = e
    this.setState({
      eventStore: event,
      target
    })
    const isNotBreadcrumbLink = !(e.target.id === 'breadcrumb-wrapper')
    const isDatoLink = e.target.id === 'dato-link'
    if (typeof target.checked === 'boolean') {
      document.removeEventListener('click', this.clickOutsideHandler, true)
      target.dispatchEvent(event)
      document.addEventListener('click', this.clickOutsideHandler, true)
    }
    if (isNotBreadcrumbLink || isDatoLink) {
      if (this.isChildComponent(e)) return
      if (!isClickable(e.target)) return
    }
    e.stopPropagation()
    document.removeEventListener('click', this.clickOutsideHandler, true)
    this.setShowExitModal(true)
  }

  toggle (tab) {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        currentInstructor: tab
      })
      emitter.emit(ON_INSTRUCTOR_SWITCH, tab)
    }
  }

  isActiveLearning () {
    const { quest_type: currentQuestType } = this.props.currentQuestion
    return currentQuestType === ACTIVE_LEARNING
  }

  isRelevantQuestionType (questionType) {
    return questionType === GUESSWORK ||
      questionType === QUIZ ||
      questionType === EXAM ||
      questionType === PRACTICE_EXERCISES
  }

  updateQuestionType (questionSet) {
    return questionSet.map(obj => {
      const { question_text: questionText } = obj
      const isTrueOrFalse = questionText.includes('True or False')
      const isYesOrNo = questionText.includes('"yes" or "no"') || questionText.includes('Yes or no')
      if (questionText && (isTrueOrFalse || isYesOrNo)) {
        obj.question_type = TRUE_FALSE
        if (isTrueOrFalse) obj.options = ['True', 'False']
        if (isYesOrNo) obj.options = ['Yes', 'No']
        obj.only_allow_one = true
      }
      return obj
    })
  }

  handleShowAIWarningChange = () => {
    this.setState({ shouldShowAIWarning: false })
  }

  getMainComponent (isRelevantQuestionType) {
    const { course: { instructors } = {}, showFinish, cohortData, examsUnlockedWithKeys } = this.context
    const {
      questionSet,
      activeTab,
      showExitModal,
      currentInstructor,
      showAIViolationModal,
      numberOfAIViolations,
      key,
      shouldShowAIWarning
    } = this.state
    const { wrapperRef } = this
    const {
      questionSet: currentQuestionSet,
      activeSectionUUID,
      currentQuestion,
      currentProblemSetUUID,
      problemBank,
      completeScreenData,
      activeChildrenIndex,
      breadCrumb,
      reviewMode
    } = this.props

    const {
      relationship: { fields: { liveProctoring } = {} } = {}
    } = cohortData || {}

    const isExam = currentQuestion.quest_type === EXAM

    const { section_ending_uuid: sectionEndingUUID, quest_type: sectionType,
      view_lesson: viewLesson } = currentQuestion
    const showKickMessage = numberOfAIViolations === 2 && liveProctoring

    const showAIWarning = liveProctoring &&
      isExam &&
      !reviewMode && shouldShowAIWarning &&
      examsUnlockedWithKeys?.[activeSectionUUID]

    if (showAIWarning) {
      return (
        <AIWarning
          showAIWarning={showAIWarning}
          setShowAIWarning={this.handleShowAIWarningChange}
        />
      )
    }

    return (
      <div
        ref={wrapperRef}
        className={classNames('position-relative lect-video-sec mt-0 pt-0', {
          'padding-bottom': showFinish,
          'disable-print': isExam
        })}
      >
        <TabContent {...(!isRelevantQuestionType ? { activeTab } : {})} key={key}>
          <InstructorQuestionSet
            setDisableAIViolationModal={this.setDisableAIViolationModal}
            completeScreenData={completeScreenData}
            activeChildrenIndex={activeChildrenIndex}
            questionSet={
              questionSet.length ? questionSet : currentQuestionSet
            }
            section_ending_uuid={sectionEndingUUID}
            currentQuestion={currentQuestion}
            currentProblemSetUUID={currentProblemSetUUID}
            problemBank={problemBank}
            breadCrumb={breadCrumb}
            sectionUUID={activeSectionUUID}
            activeSectionUUID={activeSectionUUID}
            type_of_question={sectionType}
            Instructors={isRelevantQuestionType ? ''
              : instructors}
            currentInstructor={isRelevantQuestionType ? ''
              : currentInstructor}
            {...(isRelevantQuestionType ? {
              review_mode: this.reviewMode,
              view_lesson: viewLesson
            } : {})}
          />
        </TabContent>
        {this.isActiveLearning() && (<ExitModal
          show={showExitModal}
          handleLeave={this.handleLeave}
          handleStay={this.handleStay} />
        )}
        <AIViolationModal
          showAIViolationModal={showAIViolationModal}
          setShowAIViolationModal={this.setShowAIViolationModal}
          showKickMessage={showKickMessage}
        />
      </div>
    )
  }

  render () {
    const { questionSet } = this.state
    let isRelevantQuestionType = true
    if (!questionSet) return <LoadingSpinner />
    const { currentQuestion } = this.props
    const { activeTab } = this.state
    const { quest_type: questionType } = currentQuestion
    if (!this.isRelevantQuestionType(questionType)) {
      const instructors = getUniqueInstructorsFromQuestionSet(questionSet)
      this.context.course['instructors'] = instructors

      const { instructor: { instructor_uuid: instructorUuid } = {} } =
        questionSet[0] || {}
      if (!activeTab && instructorUuid) this.toggle(instructorUuid)
      isRelevantQuestionType = false
    }
    return this.getMainComponent(isRelevantQuestionType)
  }
}

GlobalInstructorSwitch.contextType = Context
export default GlobalInstructorSwitch
