import React, { useEffect, useState } from 'react'
import api from '../../api'
import {
  getActiveSession,
  getSessionsArray
} from '../../utilities/studyBlocks'
import StudyBlockStarting from './Notifications/StudyBlockStarting'
import EditStudyBlocks from './Notifications/EditStudyBlocks'
import AdjustStudyBlocks from './Notifications/AdjustStudyBlocks'

function StudyBlocks () {
  const [studentCourses, setStudentCourses] = useState([])
  const [studyBlocks, setStudyBlocks] = useState({})
  const [studyBlocksMetadata, setStudyBlocksMetadata] = useState({})
  const studyBlocksArray = getSessionsArray(studyBlocks)

  useEffect(() => {
    const getStudentCourses = async () => {
      const { courses } = await api.getStudentCourses(false, undefined, true) || {}
      setStudentCourses(courses)
    }

    const getSavedStudyBlocks = async () => {
      const { studyBlocks, metadata } = await api.getStudyBlocks()
      setStudyBlocks(studyBlocks)
      setStudyBlocksMetadata(metadata)
    }

    getStudentCourses()
    getSavedStudyBlocks()
  }, [])

  useEffect(() => {
    const attendanceIntervalId = setInterval(async () => {
      const activeStudyBlock = getActiveSession(studyBlocksArray)
      if (!activeStudyBlock || activeStudyBlock?.isAttended) return

      const { success } = await api.saveStudyBlocksAttendance(activeStudyBlock?.id)
      if (!success) return

      setStudyBlocks(studyBlocks => ({
        ...studyBlocks,
        [activeStudyBlock?.id]: {
          ...(studyBlocks?.[activeStudyBlock?.id] || {}),
          isAttended: true
        }
      }))
    }, 1000 * 60 * 5)

    return () => {
      clearInterval(attendanceIntervalId)
    }
  }, [studyBlocksArray])

  return (
    <>
      <StudyBlockStarting studyBlocks={studyBlocks} />
      {studyBlocks && <EditStudyBlocks
        studyBlocks={studyBlocks}
        metadata={studyBlocksMetadata}
      />}
      {studyBlocksMetadata && <AdjustStudyBlocks
        studentCourses={studentCourses}
        metadata={studyBlocksMetadata}
      />}
    </>
  )
}

StudyBlocks.displayName = 'StudyBlocks'
export default StudyBlocks
