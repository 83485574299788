import { useCallback } from 'react'
import debounce from 'lodash/debounce'
import qs from 'qs'
import { dateToSecondsSinceEpoch } from './dateTimeUtils'
import {
  COURSE_NAME_DOMAIN_REGEX,
  GUARDIAN_PERMISSION_PATH,
  PROF_CERT_GUARDIAN_PERMISSION_PATH
} from '../Constants'

export default {
  getLinkTextAndUrlFromMarkdown,
  loginTimestamp,
  validateEmail,
  handleKeyPress,
  parseBooleanObject
}

export const publicRoutes = [
  GUARDIAN_PERMISSION_PATH,
  PROF_CERT_GUARDIAN_PERMISSION_PATH,
  '/java-compiler',
  '/login'
]

export const isPublicRoute = (pathName) => {
  if (!pathName) {
    pathName = window.location.hash.split('#')[1]
  }

  return publicRoutes.includes(pathName)
}

export const isAuthRequired = (activePath) => !publicRoutes.includes(activePath)

export function useDebounce (callback, delay) {
  const debouncedFn = useCallback(
    debounce((content) => callback(content), delay),
    [delay] // will recreate if delay changes
  )
  return debouncedFn
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * @param {Object} obj
 * @returns {Object} parsed boolean object from string or false
 */
function parseBooleanObject (obj) {
  if (!obj) return false
  const booleanObj = {}
  Object.entries(obj).forEach(([name, value]) => {
    booleanObj[name] = value.toLowerCase() === 'true'
  })

  return booleanObj
}

function loginTimestamp () {
  const KEY = 'LOGIN_TIME_STAMP'

  /**
   * sets the login time stamp in local storage
   * @param {number} timestamp timestamp in seconds
   */
  const set = timestamp => {
    if (!timestamp) return
    localStorage.setItem(KEY, timestamp)
  }

  /**
   * @returns {number} stored timestamp as a number
   */
  const get = () => {
    const storedTimestamp = localStorage.getItem(KEY)
    if (!storedTimestamp) return
    return parseInt(storedTimestamp)
  }

  /**
   * @param {number} now now in seconds
   * @returns {boolean} true if stored login timestamp is above 48 hours from now.
   */
  const isSessionExpired = now => {
    const storedTimestamp = get()
    if (!storedTimestamp) return false
    const storedDate = new Date(storedTimestamp * 1000)
    storedDate.setHours(storedDate.getHours() + 48)
    return now >= dateToSecondsSinceEpoch(storedDate)
  }

  const remove = () => {
    localStorage.removeItem(KEY)
  }

  return { set, get, isSessionExpired, remove }
}

// Simple extract util to get text and url from markdown
// [Google](https://google.com) format
function getLinkTextAndUrlFromMarkdown (markdown) {
  if (!markdown) return []
  const MARKDOWN_LINK_EXTRACT_REGEX = /\[([^\]]+)\]\s*\(([^)]+)\)/g

  const result = []
  let match

  while ((match = MARKDOWN_LINK_EXTRACT_REGEX.exec(markdown))) {
    const [, title, url] = match
    result.push({ title: title.trim(), url: url.trim() })
  }

  return result
}

function validateEmail (email) {
  const validEmailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return validEmailPattern.test(email)
}

export const addHttpsIfSchemeNotExist = url => {
  if (!url) return ''

  const hasValidScheme = /^https?:\/\//.test(url)
  if (hasValidScheme) return url

  return 'https://' + url
}

function handleKeyPress (event, callback) {
  if (event.key === 'Enter') {
    callback()
  }
}

export const replaceHTMLWhiteSpaceWithSpace = text => {
  if (!text) return ''

  return text.replace(/&nbsp;/g, '')
}

export const getCourseNameFromHostname = hostName => {
  const [, courseName] = COURSE_NAME_DOMAIN_REGEX.exec(hostName) || []
  return courseName || null
}

export const stripHtml = (html) => {
  if (!html) return ''

  const tmp = document.createElement('div')
  tmp.innerHTML = html.replace(/<!--.*?-->/g, '')
  return tmp.textContent || tmp.innerText || ''
}

export const getSrcUrl = html => {
  if (!html) return ''

  const matchedArr = html.match(/src="(.*?)"/)
  if (!matchedArr) return ''

  return matchedArr[1]
}

export const isClickable = (element) => {
  return element instanceof HTMLButtonElement ||
    element instanceof HTMLAnchorElement ||
    element.parentElement instanceof HTMLAnchorElement
}

export const formatBoldTags = (text) => {
  return addStylePropertyForTags(text, ['strong', 'b'], 'font-weight: 600')
}

export const addStylePropertyForTags = (text, tags, property) => {
  if (!text) return ''

  const properties = (Array.isArray(property) ? property : [property]).filter(Boolean)
  if (!properties.length) return text

  tags.forEach(tag => {
    const tagRegex = new RegExp(`<${tag}\\s*(.*?)>((.|\n)*?)</${tag}>`, 'gi')
    text = text.replace(tagRegex, (_match, g1, g2) => {
      return `<${tag} ` + addPropertyInStyleAttribute(g1, properties) + '>' + g2 + `</${tag}>`
    })
  })

  return text
}

export const addPropertyInStyleAttribute = (attributes, styleProperties) => {
  const stylePropertiesText = styleProperties.join(';')
  if (!attributes.trim()) return `style="${stylePropertiesText}"`

  const styleRegex = /style\s*="([^"]*)"/gi

  const hasStyleAttribute = styleRegex.test(attributes)
  if (!hasStyleAttribute) return attributes + ` style="${stylePropertiesText}"`

  return attributes.replace(styleRegex, (_match, g1) => {
    if (!g1?.trim()) return `style="${stylePropertiesText}"`

    const lastSemiColor = g1[g1.length - 1] === ';'
    return `style="${g1}${lastSemiColor ? '' : ';'}${stylePropertiesText}"`
  })
}

export const getIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org/?format=json')
    const { ip } = await response.json()

    return ip
  } catch (error) {
    return 'Ip Not Found'
  }
}

export const getUTMParametersFromUrl = () => {
  const allowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const params = qs.parse(window.location.search.slice(1))
  const utmPropertiesArray = Object.keys(params)
    .filter(key => allowedKeys.includes(key))
    .map(key => ([key, params[key]]))
  const utmProperties = Object.fromEntries(utmPropertiesArray)

  return utmProperties
}

export const getIdFromUrl = (url) => {
  if (!url) return ''

  const matchedArr = url.match(/\/([^/]+)$/)
  if (!matchedArr) return ''

  return matchedArr[1]
}

// It's not possible to replace with regex when there are nested elements,
// So we prefer to replace elements content through DOM parser
export const replaceElementContent = ({ html, element, searchValue, replaceValue }) => {
  if (!html) return html

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const elements = [...doc.getElementsByTagName(element)]
  if (!elements.length) return html

  elements.forEach(element => {
    element.innerHTML = element.innerHTML.replace(searchValue, replaceValue)
  })

  return doc.body.innerHTML
}

export const replaceBlockquoteDoubleQuotation = (html) => {
  return replaceElementContent({
    html,
    element: 'blockquote',
    searchValue: /“|”/g,
    replaceValue: '"'
  })
}

export const setAsyncTimeout = (cb, timeout = 0) =>
  new Promise(resolve => {
    setTimeout(() => {
      cb()
      resolve()
    }, timeout)
  })

/**
 * @param {Array} array
 * @returns {Number} the index of the middle element
 *  for even arrays it returns the index of the first one
 */
export const getArrayMidIndex = (array) => {
  if (!array?.length) return

  const isEvenArray = array.length % 2 === 0
  const midArrayIndex = isEvenArray
    ? Math.floor((array.length - 1) / 2)
    : Math.floor(array.length / 2)

  return midArrayIndex
}

export const getQueryParameters = () => {
  if (!window.location.search) return null

  return qs.parse(window.location.search.slice(1)) || {}
}

export const getPercentageAchieved = (percentages, fullValue, currentValue, cutoff) => {
  if (!percentages?.length || !fullValue || !currentValue) return null

  const targetedDurations = percentages.map(
    percentage => ({ percentage, value: (fullValue * percentage) / 100 })
  )
  const percentageAchieved = targetedDurations.find(
    percentageData => Math.abs(currentValue - percentageData.value) <= (cutoff || 1)
  )

  return percentageAchieved?.percentage || null
}
