import React, { useState, useEffect, useContext } from 'react'
import { TabPane } from 'reactstrap'
import KalturaEmbed from '../KalturaEmbed/KalturaEmbed'
import config from '../../config'
import Context from '../Context/Context'
import { areAllKalturaEmbedCodesValid } from '../../utilities/sectionUtils'
import { isOutlierAccount } from '../../utilities/userUtils'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'

export default function LectureVideo (props) {
  let { LectureVideos } = props
  if (!LectureVideos) LectureVideos = [{}]
  const {
    sectionUUID,
    nextChildUUID,
    hasActiveLearning,
    multipleVideos,
    breadcrumbArr,
    activeTab,
    isLastVideo,
    shouldSplitLecturePage
  } = props
  const {
    course: { currentInstructor }
  } = useContext(Context)
  const { userStore: { user: { email } } } = useContext(Auth0Context)
  const hasKalturaVideo = isOutlierAccount(email) || config.isPreviewCourse ||
    areAllKalturaEmbedCodesValid(LectureVideos)
  const [isKalturaLoading, setIsKalturaLoading] = useState(hasKalturaVideo)

  useEffect(() => {
    if (!hasKalturaVideo) return
    if (document.getElementById('kaltura_script')) {
      setIsKalturaLoading(false)
      return
    }
    const { kalturaSetting, kalturaV7Setting, course } = config
    const { scriptUrl } = course.useKalturaV7 ? kalturaV7Setting : kalturaSetting
    const kalturaScript = document.createElement('script')
    kalturaScript.id = 'kaltura_script'
    kalturaScript.type = 'text/javascript'
    kalturaScript.src = scriptUrl
    kalturaScript.onload = () => {
      setIsKalturaLoading(false)
    }
    document.body.appendChild(kalturaScript)
  }, [hasKalturaVideo])

  if (isKalturaLoading) return null
  if (!hasKalturaVideo) return null

  if (LectureVideos.length === 1) {
    const {
      instructor: {
        instructor_uuid: instructorUUID
      },
      kaltura_embed_code: kalturaEmbedCode
    } = LectureVideos[0]
    return (
      <>
        <KalturaEmbed
          hashedId={kalturaEmbedCode}
          watchedVideo={sectionUUID}
          nextChildUUID={nextChildUUID}
          breadcrumbArr={breadcrumbArr}
          currentInstructorID={currentInstructor}
          hasActiveLearning={hasActiveLearning}
          isLastVideo={isLastVideo}
          isActive={currentInstructor === instructorUUID}
          multipleVideos={multipleVideos}
          lectureVideos={LectureVideos}
        />
      </>
    )
  }
  return LectureVideos.map((video, Id) => {
    const {
      instructor: {
        instructor_uuid: instructorUUID
      },
      kaltura_embed_code: kalturaEmbedCode
    } = video
    return (
      <TabPane tabId={instructorUUID} key={Id}>
        {
          activeTab === instructorUUID && (
            <>
              <KalturaEmbed
                hashedId={kalturaEmbedCode}
                watchedVideo={sectionUUID}
                nextChildUUID={nextChildUUID}
                breadcrumbArr={breadcrumbArr}
                currentInstructorID={currentInstructor}
                hasActiveLearning={hasActiveLearning}
                isLastVideo={isLastVideo}
                isActive={currentInstructor === instructorUUID}
                multipleVideos={multipleVideos}
                lectureVideos={LectureVideos}
                shouldSplitLecturePage={shouldSplitLecturePage}
              />
            </>
          )
        }
      </TabPane>
    )
  })
}
