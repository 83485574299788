import React from 'react'
import { useAuthStore } from '@outlier-org/lst-auth-react'
import { isPublicRoute } from '../../utilities'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import ErrorBoundary from '../ErrorBoundary'
import config from '../../config'

const AppGuard = ({ children }) => {
  const [isAuthenticated, login, isLoading, isAuthenticationFailed] = useAuthStore((state) => [state.isAuthenticated, state.login, state.isLoading, state.isAuthenticationFailed])
  const { isPreviewCourse } = config

  if (isPublicRoute() || isAuthenticated || isPreviewCourse) {
    return children
  }

  if (!isLoading) {
    if (isAuthenticationFailed) {
      return <ErrorBoundary />
    }
    login()
  }

  return <LoadingSpinner />
}

export default AppGuard
