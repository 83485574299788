import React, { useState, useContext } from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import {
  Container,
  Header,
  Label,
  Input,
  Content
} from './styles'
import CourseButton from '../CourseButton/CourseButton'
import utils from '../../utilities'
import api from '../../api'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import { useStudentDataContext } from '../Context/Context'
import {
  getGuardianPermissionKeys,
  getPermissionRequiredTexts
} from './GuardianPermission.utils'
import StudentSuccessLink from './StudentSuccessLink'
import { PERMISSION_REQUESTED } from './permissionStates'

const PermissionRequired = ({
  email,
  setEmail,
  setPermissionState
}) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { userStore: { user: { email: studentEmail } } } = useContext(Auth0Context)
  const {
    isProfessionalCertificateCourse,
    showProfCertGuardianPermission
  } = useStudentDataContext()

  const {
    guardianPermissionRequestedKey
  } = getGuardianPermissionKeys(showProfCertGuardianPermission)

  const sendEmail = async () => {
    setError('')

    const isValidEmail = utils.validateEmail(email) &&
      email !== studentEmail
    if (!isValidEmail) return setError('Enter valid email')

    localStorage.removeItem(guardianPermissionRequestedKey)

    setIsLoading(true)
    const { success, message } = await api.requestGuardianPermission(
      email,
      isProfessionalCertificateCourse
    )
    setIsLoading(false)

    if (!success) return setError(message)

    localStorage.setItem(guardianPermissionRequestedKey, 'true')
    setPermissionState(PERMISSION_REQUESTED)
  }

  if (isLoading) return <LoadingSpinner />

  const {
    description
  } = getPermissionRequiredTexts(showProfCertGuardianPermission)

  return (
    <Container>
      <div className='content'>
        <Header>Permission needed to continue</Header>
        <Content>
          {description}
        </Content>
        <Content>
          If it is not possible to reach your parent or guardian this way or if you
          need additional assistance, please reach out to {' '}
          <StudentSuccessLink
            showProfCertGuardianPermission={showProfCertGuardianPermission}
          />.
        </Content>
        <Label>Parent or guardian’s email address</Label>
        {error
          ? (
            <span data-testid='error' className='error'>
              {error}
            </span>
          )
          : null}
        <Input
          hasError={!!error}
          className='fs-exclude'
          type='email'
          data-testid='email'
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
        <CourseButton
          className='btn btn-primary'
          onClick={sendEmail}
        >
            Submit
        </CourseButton>
      </div>
    </Container>
  )
}

export default PermissionRequired
