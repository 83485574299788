import React from 'react'
import find from 'lodash/find'
import CourseButton from '../CourseButton/CourseButton'
import '../../App.css'
import Context from '../Context/Context'
import './css/interstitial.css'
import { emitter } from '../Emitter/Emitter'
import ConfettiCanvasComponent
from '../ConfettiCanvasComponent/ConfettiCanvasComponent'
import {
  ON_SECTION_DATA_PROGRESS,
  ON_QUIZ_SECTION,
  ON_EXAM_SECTION,
  ON_SECTION_PROGRESS,
  ON_NAVIGATE_TO,
  ON_SKIP_SECTION,
  ON_MINIMUM_SECTION_PROGRESS,
  ON_REVIEW_MODE,
  ON_TRACK_STUDENT_EVENT,
  ON_EXAM_COMPLETE
} from '../../Constants/emitterKeys'
import {
  QUIZ_COMPLETE,
  QUIZ_SECTION,
  EXAM_PERCENTAGE,
  EXAM_COMPLETE,
  EXAM_STATUS
} from '../../Constants/studentContext'
import {
  EXAM,
  FINAL_ESSAY,
  QUIZ
} from '../../Constants/sectionType'
import { FINAL_EXAM_PART_I, PRACTICE_EXAM } from '../../Constants/examType'
import LoadingSpinner from '../LoadingSpinner/LoadingAnimation'
import {
  endProctorioSession,
  formatTag,
  isExamRetakeType,
  getLocalStorageKey,
  updateStudentContext,
  isExamDone
} from '../../utilities/examUtils'
import config from '../../config'
import api from '../../api'
import {
  getQuizNumber,
  getNumberFromString,
  isMidExam
} from '../../utilities/chapterUtils'
import {
  ScorePageUI,
  MainWrapper,
  Score,
  ButtonDiv
} from './style'
import {
  FINAL_EXAM_COMPLETED,
  MIDTERM_EXAM_COMPLETED,
  QUIZ_COMPLETED
} from '../../Constants/eventTypes'
import { MULTIPLE } from '../../Constants/frequency'
import { ReviewActionLink } from './styles'
import shouldUnlockQuiz from '../../utilities/quizLockLogic'
import {
  getSectionGuessworkUUID,
  isContentGatingUnlocked
  , getAnswerForExamQuestions } from '../../utilities/sectionUtils'
import {
  isGuessworkCompleteForSection
} from '../../utilities/studentProgressUtils'

import { getStudentAnswersKey } from '../../utilities/contextKeys'
import { CODING_QUESTION } from '../../Constants/questionType'
import {
  getCodegradeToken,
  getSubmissionGrades,
  getSubmissionsAndAutotests
} from '../../utilities/codegradeUtils'
import { setAsyncTimeout } from '../../utilities'
import { CODING_ASSIGNMENT, ORIENTATION, WRITING_ASSIGNMENT } from '../../Constants/chapterType'
import { isHighSchoolCourse, isWelcomePsychologyCourse } from '../../utilities/courseUtils'
import { CONFETTI_PASSING_GRADE, EXAM_STATUSES } from '../../Constants'
import { OUTLIER_STUDENT_DASHBOARD_URL } from '../../Constants/domains'
import { getExternalLinkUrl } from '@outlier-org/lst-auth-react'

export function getClass (assessment, index) {
  if (!assessment && index !== 1) {
    return { 'btn-primary': true, 'w-100': true }
  }

  if (!assessment && index !== 0) {
    return { 'btn-primary': true, 'w-100': true, 'mt-2': true }
  }

  if (index === 1) return { 'btn-secondary': true }

  return { 'btn-primary': true }
}

class QuestionSetInterstitialPractices extends React.Component {
  constructor (props) {
    super(props)
    this.handleQuizSection = this.handleQuizSection.bind(this)
    this.handleExamSection = this.handleExamSection.bind(this)
    this.handleAllQuizCompleted = this.handleAllQuizCompleted.bind(this)
    this.getSectionPercentage = this.getSectionPercentage.bind(this)
    this.showConfetti = this.showConfetti.bind(this)

    this.state = {
      QuestionSet: [],
      percentage: null,
      text: undefined,
      currentDate: Date.now() / 1000 | 0
    }
  };

  componentDidMount () {
    const { completeScreenData, screenData } = this.props
    const {
      isAssessmentQuizExam,
      updateContext
    } = this.context
    if (!isAssessmentQuizExam) {
      const isAssessmentQuizOrExam =
        (screenData?.worktype === QUIZ || completeScreenData?.type === 'exam')
      updateContext({ isAssessmentQuizExam: isAssessmentQuizOrExam })
    }
    const asynDidMount = async () => {
      const {
        QuestionSetdata,
        completeScreenData,
        intertitial_type: interstitialType
      } = this.props
      const { title } = completeScreenData

      this.setState({
        QuestionSet: QuestionSetdata
      })

      if (interstitialType === 'end_content_intertitial_writing') return
      if (interstitialType === 'end_content_intertitial_practices') {
        await this.handleQuizSection()
      } else if (interstitialType === 'end_content_intertitial_exam') {
        await this.handleExamSection()

        if (config.insideProctorio) {
          const tag = formatTag(title)
          await api.trackProctorioExams(tag)
        }

        // end proctorio session even in page refresh
        window.onbeforeunload = () => endProctorioSession()
      }
    }
    if (screenData?.worktype === QUIZ) this.showQuizCompletionModal()
    if (completeScreenData?.type === EXAM?.toLowerCase()) this.showExamCompletionModal()
    if (
      [WRITING_ASSIGNMENT, CODING_ASSIGNMENT].includes(completeScreenData?.type)
    ) this.showAssignmentCompletionModal()
    asynDidMount()
  }

  shouldUnlockItem () {
    const { sectionUUID, completeScreenData } = this.props
    const {
      chapterExamUnlockDate,
      finalExamLockDate,
      finalExamUnlockDate,
      data: section
    } = completeScreenData
    const sectionQuizzes = completeScreenData.data.section_exe.quiz
    const currentSectionIndex = sectionQuizzes.findIndex(
      section => section.question_set_uuid === sectionUUID)
    const {
      isAuditor,
      isStudioCohort,
      isAdmin,
      isVIPGradedContent,
      isNoAssessments,
      studentData,
      isContentGatingEnabled,
      courseData: { chapters },
      isCohortEndedForStudent
    } = this.context
    const isContentUnlocked = isContentGatingUnlocked({
      section,
      studentData,
      isContentGatingEnabled
    })
    const guessworkUUID = getSectionGuessworkUUID(section, chapters)
    const showButton = shouldUnlockQuiz({
      isAuditor,
      isStudioCohort,
      isAdmin,
      isVIPGradedContent,
      isCohortEndedForStudent,
      quizNumber: currentSectionIndex + 1,
      isGuessworkComplete: !guessworkUUID ||
        isGuessworkCompleteForSection(studentData, guessworkUUID),
      chapterExamUnlockDate,
      finalExamUnlockDate,
      finalExamLockDate,
      isNoAssessments,
      isContentUnlocked,
      currentDate: this.state.currentDate
    })
    return showButton
  }

  showQuizCompletionModal () {
    const nextSection = getNextSection.call(this)
    const isLastSectionNotAttempt = isLastSection.call(this) && isLastSectionBeforeExam.call(this)
    if (this.props.intertitial_type === 'end_content_intertitial_practices') {
      if (isLastSection.call(this) && noMoreQuizes.call(this)) {
        this.setState({
          text: `You can return to the course home to prepare
         for the exam or review the answers in the quiz.`
        })
        this.showCompletionModal('The exam is next, but take some time to prepare first.', {
          primary: {
            text: 'return home',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/')
            }
          }
        })
      } else if (noMoreQuizes.call(this)) {
        this.setState({ text: 'Continue to the next section.' })
        this.showCompletionModal('Let’s move on to the next section.', {
          primary: {
            text: 'continue',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/' + nextSection)
            }
          }
        })
      } else {
        this.setState({
          text: `Continue to the next section,
          review the answers in the quiz, or take another quiz attempt.`
        })
        const showButton = this.shouldUnlockItem()
        const completetionModalText = isLastSectionNotAttempt
          ? 'Return to the course home or take another quiz attempt.'
          : showButton
            ? 'Move on to the next section or take another quiz attempt.'
            : 'Let’s move on to the next section..'

        this.showCompletionModal(completetionModalText, {
          primary: {
            text: isLastSectionNotAttempt ? 'Return Home' : 'continue',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO,
                isLastSectionNotAttempt ? '/' : '/' + nextSection)
            }
          },
          secondary: showButton && {
            text: 'take another',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/' +
                this.props.activeSectionUUID + '/' +
                getAnotherQuizAttempt.call(this))
            }
          }
        })
      }
    }

    function isLastSectionBeforeExam () {
      const { courseData, currentChapter: currChapter } = this.context
      const { completeScreenData } = this.props
      const currentChapter = courseData.chapters.find(
        chapter => chapter.chapter_uuid === currChapter.uuid)
      const currentSectionIndex = currentChapter.sections.findIndex(
        section => section.section_uuid === completeScreenData.uuid)
      return currentChapter.sections.length - 1 === currentSectionIndex
    }

    function isLastSection () {
      const { courseData, currentChapter: currChapter } = this.context
      const currentSectionIndex = courseData.chapters.findIndex(
        chapter => chapter.chapter_uuid === currChapter.uuid)
      const lastSection = courseData.chapters[currentSectionIndex + 1]
      return lastSection?.type === 'exam'
    }

    function noMoreQuizes () {
      const { completeScreenData, sectionUUID } = this.props
      const { studentData } = this.context
      const sectionQuizzes = completeScreenData
        .data.section_exe.quiz
      const completedQuizzes = studentData[QUIZ_SECTION]
      const showButton = this.shouldUnlockItem()

      return !showButton ? true : sectionQuizzes
        .filter(quiz => quiz['question_set_uuid'] !== sectionUUID)
        .every(quiz => quiz['question_set_uuid'] in completedQuizzes)
    }

    function getNextSection () {
      const { courseData, currentChapter: currChapter } = this.context
      const CurrentSectionUUID = window?.location?.hash.split('/')[1]
      const currentChapter = courseData.chapters.find(
        chapter => chapter.chapter_uuid === currChapter.uuid)
      const currentSectionIndex = currentChapter.sections.findIndex((section) =>
        section?.['section_uuid'] === CurrentSectionUUID
      )
      const nextChapter = courseData.chapters.find((chapter) =>
        chapter.chapterNumber === currentChapter.chapterNumber + 1
      )
      const nextChapterSectionUUID = nextChapter?.sections?.[0]?.['section_uuid']
      const nextSectionUUID = currentChapter?.sections?.[currentSectionIndex + 1]?.['section_uuid']
      return nextSectionUUID || nextChapterSectionUUID
    }

    function getAnotherQuizAttempt () {
      const { completeScreenData } = this.props
      const { studentData } = this.context
      const sectionQuizzes = completeScreenData
        .data.section_exe.quiz
      const completedQuizzes = studentData[QUIZ_SECTION]
      return sectionQuizzes
        .filter(quiz => quiz['question_set_uuid'] !== this.props.sectionUUID)
        .find(quiz => !(quiz['question_set_uuid'] in completedQuizzes))?.['question_set_uuid']
    }
  }

  showExamCompletionModal () {
    const isHSCourse = isHighSchoolCourse(this.context?.activeCourse)
    const nextSection = getNextSection.call(this)
    if (this.props.intertitial_type === 'end_content_intertitial_exam') {
      if (isFinalExam.call(this)) {
        this.setState({
          text: `You can return to the course homepage or review the answers in the exam.`
        })
        this.showCompletionModal('Let’s keep it going...', {
          primary: {
            text: 'return home',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/')
            }
          }
        })
      } else if (isMoreSection.call(this)) {
        this.setState({
          text: isHSCourse
            ? 'Continue to the next section.'
            : 'Continue to the next section or review the answers in the exam.'
        })
        this.showCompletionModal('Let’s keep it going...', {
          primary: {
            text: 'continue',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/' + nextSection)
            }
          }
        })
      }
    }

    function getNextSection () {
      const { courseData, currentChapter: currChapter } = this.context
      const CurrentSectionUUID = window?.location?.hash.split('/')[1]
      const currentChapter = courseData.chapters.findIndex(chapter =>
        chapter.chapter_uuid === currChapter.uuid
      )
      const currentSection = courseData.chapters[currentChapter + 1]
      const currentSectionIndex = currentSection?.sections?.findIndex((section) =>
        section?.['section_uuid'] === CurrentSectionUUID
      )
      const nextChapter = courseData.chapters.find((chapter) =>
        chapter.chapterNumber === currentSection?.chapterNumber + 1
      )
      const nextChapterSectionUUID = nextChapter?.sections[0]?.['section_uuid']
      if (!isNaN(currentSectionIndex)) {
        const nextSectionUUID = currentSection?.sections[currentSectionIndex + 1]?.['section_uuid']
        return nextSectionUUID
      }
      return nextChapterSectionUUID
    }
    function isMoreSection () {
      const { courseData, currentChapter: currChapter } = this.context
      const currentSectionIndex = courseData?.chapters.findIndex(
        chapter => chapter?.['chapter_uuid'] === currChapter?.uuid)
      const lastSection = courseData?.chapters[currentSectionIndex + 1]
      return lastSection?.type === 'chapter'
    }
    function isFinalExam () {
      const { currentChapter: currChapter } = this.context
      return currChapter?.isFinalExam
    }
  }

  showAssignmentCompletionModal () {
    const { isAssignmentResubmitted } = this.context
    if (isAssignmentResubmitted) return
    const nextSection = getNextSection.call(this)
    if (this.props.intertitial_type === 'end_content_intertitial_writing') {
      if (isMoreSection.call(this)) {
        this.setState({
          text: `Keep a look out for your grade in the coming days. Continue to the next section.`
        })
        this.showCompletionModal('Let’s keep it going...', {
          primary: {
            text: 'Continue',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/' + nextSection)
            }
          }
        })
      } else {
        this.setState({
          text: `Keep a look out for your grade in the coming days. You can return to the course homepage..`
        })
        this.showCompletionModal('Let’s keep it going...', {
          primary: {
            text: 'return home',
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/')
            }
          }
        })
      }
    }
    function getNextSection () {
      const { courseData } = this.context
      const CurrentSectionUUID = window?.location?.hash.split('/')[1]
      const currentSectionIndex = courseData.chapters?.findIndex(
        (chapter) => chapter?.['chapter_uuid'] === CurrentSectionUUID
      )
      const nextChapter = courseData.chapters[currentSectionIndex + 1]
      const nextChapterSectionUUID = nextChapter?.sections?.[0]?.['section_uuid']
      const nextSectionUUID = nextChapter?.sections?.[currentSectionIndex + 1]?.['section_uuid']
      return nextSectionUUID || nextChapterSectionUUID
    }
    function isMoreSection () {
      const { courseData } = this.context
      const CurrentSectionUUID = window?.location?.hash.split('/')[1]
      const currentSectionIndex = courseData.chapters?.findIndex(
        (chapter) => chapter?.['chapter_uuid'] === CurrentSectionUUID
      )
      const lastSection = courseData?.chapters[currentSectionIndex + 1]
      return lastSection?.type === ORIENTATION || lastSection?.sections?.length
    }
  }

  componentWillUnmount () {
    const { intertitial_type: interstitialType } = this.props
    const { updateContext } = this.context
    const isExam = interstitialType === 'end_content_intertitial_exam'

    updateContext({
      isAssessmentQuizExam: false,
      modalData: {
        isCompletionModalOpen: false,
        type: undefined
      }
    })

    if (isExam) endProctorioSession()
  }

  updateQuizProgress = ({ percentage, isQuizCompleted }) => {
    const {
      sectionUUID,
      activeSectionUUID,
      completeScreenData: { breadcrumbArr },
      completeScreenData,
      activeChildrenIndex,
      QuestionSetdata
    } = this.props
    const {
      isSubmittingAnswers,
      cohortData: { duration, name: cohort },
      updateContext,
      studentData
    } = this.context
    this.setState({ percentage })
    if (isSubmittingAnswers) updateContext({ isSubmittingAnswers: false })

    if (isQuizCompleted) return

    //  quiz-section
    const quizSectionUpdate = {
      sectionUUID,
      percentage,
      activeSectionUUID
    }
    emitter.emit(ON_QUIZ_SECTION, quizSectionUpdate)
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: QUIZ_COMPLETE,
        sectionUUID: activeSectionUUID,
        value: false
      }
    )
    // check if quiz percentage is same as the one in studentData
    const correctAnswersLength = QuestionSetdata.questions?.map((question) => {
      return find(studentData.studentAnswers, { uuid: question.Question_uuid }).correct
    }).filter(Boolean).length
    if (
      parseFloat(
        ((correctAnswersLength * 100) / QuestionSetdata.questions?.length).toFixed(2)
      ) !== studentData['quiz-section'][sectionUUID]
    ) {
      api.logError({
        message: 'Quiz percentage mismatch',
        url: window.location.href
      })
    }

    const {
      courseQuizNumber,
      sectionQuizNumber
    } = getQuizNumber(completeScreenData, activeChildrenIndex)
    const eventData = {
      properties: {
        course: breadcrumbArr[0][0],
        cohort,
        cohort_length: duration,
        sub_chapter: breadcrumbArr[2][0],
        chapter: breadcrumbArr[1][0],
        quiz_score: percentage,
        course_quiz_number: courseQuizNumber,
        section_quiz_number: sectionQuizNumber,
        time_stamp: new Date().getTime()
      },
      event: QUIZ_COMPLETED,
      frequency: MULTIPLE
    }

    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    emitter.emit(ON_MINIMUM_SECTION_PROGRESS, activeSectionUUID)
    /*
    * Checking if all quiz has been completed
    * Get all quizzes for the current section
    */
    this.handleAllQuizCompleted()
  }

  async handleQuizSection () {
    const {
      sectionUUID,
      completeScreenData,
      activeChildrenIndex,
      QuestionSetdata: { questions }
    } = this.props
    const {
      studentData,
      isSubmittingAnswers,
      user: { email: userEmail }
    } = this.context
    if (!questions) return

    const completedQuizSectionUUIDs = Object.keys(studentData[QUIZ_SECTION])
    const isQuizCompleted = completedQuizSectionUUIDs.includes(sectionUUID)

    let percentage = this.getSectionPercentage()
    if (percentage === 0 && !isQuizCompleted && !isSubmittingAnswers) {
      const { children } = completeScreenData
      const quizEndSection = children[activeChildrenIndex]
      const { breadcrumb: quizTitleKey = '' } = quizEndSection || {}
      const localStorageKey = getLocalStorageKey(
        userEmail,
        config.courseName,
        quizTitleKey
      )
      updateStudentContext(this.context, localStorageKey, questions)
      const { studentData: updatedStudentData, currentChapter: type } = this.context
      const studentAnswersKey = getStudentAnswersKey(type)
      const studentChapterAnswers = updatedStudentData[studentAnswersKey]
      const examAnswers = getAnswerForExamQuestions(
        studentChapterAnswers,
        questions
      )

      const hasCodingQuestion = questions.some(question => {
        return question.question_type === CODING_QUESTION
      })
      if (hasCodingQuestion) await getCodegradeToken()
      this.setState({ isSavingAnswers: true })
      const submissions = await getSubmissionsAndAutotests(examAnswers || [])
      if (!submissions?.length) return this.setState({ isSavingAnswers: false })

      await setAsyncTimeout(async () => {
        const submissionsWithType = submissions.map(submission => {
          return { ...submission, type: 'quiz' }
        })
        const examAnswerList = await getSubmissionGrades(submissionsWithType)
        this.setState({ isSavingAnswers: false })
        emitter.emit(ON_EXAM_COMPLETE, { examAnswerList })
        percentage = this.getSectionPercentage()
        this.updateQuizProgress({ percentage, isQuizCompleted })
      }, hasCodingQuestion ? 30000 : 0)
    } else {
      this.updateQuizProgress({ percentage, isQuizCompleted })
    }
  }

  updateExamProgress = ({ percentage, currentSectionUUID, isRetakeAllowed }) => {
    const {
      completeScreenData: { chapter, breadcrumbArr, data: { versionUUID } = {} },
      sectionUUID
    } = this.props
    const {
      isSubmittingAnswers,
      cohortData: { duration, name: cohort },
      updateContext
    } = this.context

    const isFinalExamPartI = breadcrumbArr[1][0] === FINAL_EXAM_PART_I
    const isFinalExam = chapter?.isFinalExam
    const isPracticeExam = breadcrumbArr[1][0] === PRACTICE_EXAM
    this.setState({ percentage })
    if (isSubmittingAnswers) updateContext({ isSubmittingAnswers: false })

    const uuid = isRetakeAllowed ? versionUUID : sectionUUID

    const eventName = isFinalExamPartI || isFinalExam
      ? FINAL_EXAM_COMPLETED
      : isPracticeExam
        ? `${breadcrumbArr[1][0]} Completed`
        : MIDTERM_EXAM_COMPLETED

    const eventData = {
      properties: {
        course: breadcrumbArr[0][0],
        cohort_length: duration,
        cohort,
        ...isFinalExamPartI || isFinalExam
          ? { final_exam_name: breadcrumbArr[1][0] } : {},
        time_stamp: new Date().getTime(),
        ...!(isFinalExam || isFinalExamPartI || isPracticeExam)
          ? {
            midterm_number: getNumberFromString(breadcrumbArr[1][0]),
            midterm_score: percentage,
            midterm_exam_name: breadcrumbArr[1][0]
          }
          : {
            final_score: percentage
          }
      },
      event: eventName,
      frequency: MULTIPLE
    }
    //  exam-section
    const examSectionUpdate = {
      sectionUUID: uuid,
      percentage,
      activeSectionUUID: currentSectionUUID
    }
    emitter.emit(ON_TRACK_STUDENT_EVENT, eventData)
    emitter.emit(ON_EXAM_SECTION, examSectionUpdate)
  }

  async handleExamSection () {
    const {
      activeSectionUUID,
      QuestionSetdata: { questions },
      completeScreenData: { breadcrumbArr, chapter, data: { versionUUID } = {} },
      sectionUUID
    } = this.props
    const {
      studentData,
      examRetake,
      cohortData,
      user: { email: userEmail },
      isSubmittingAnswers,
      currentChapter,
      currentChapter: title
    } = this.context
    const isRetakeAllowed = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter
    })
    const currentSectionUUID = isRetakeAllowed
      ? versionUUID : activeSectionUUID

    const examPercentage = studentData[EXAM_PERCENTAGE]
    if (currentSectionUUID in examPercentage) {
      const percentage = examPercentage[currentSectionUUID]
      this.setState({ percentage })
      return
    }

    if (!questions) return
    let percentage = this.getSectionPercentage()
    if (percentage === 0 && !isSubmittingAnswers) {
      const [, [examTitlekey = '']] = breadcrumbArr || {}
      const localStorageKey = getLocalStorageKey(
        userEmail,
        config.courseName,
        examTitlekey
      )

      updateStudentContext(this.context, localStorageKey, questions)
      const { studentData: updatedStudentData, currentChapter: type } = this.context
      const studentAnswersKey = getStudentAnswersKey(type)
      const studentChapterAnswers = updatedStudentData[studentAnswersKey]
      const examAnswers = getAnswerForExamQuestions(
        studentChapterAnswers,
        questions
      )
      const hasCodingQuestion = questions.some(question => {
        return question.question_type === CODING_QUESTION
      })
      if (hasCodingQuestion) await getCodegradeToken()
      this.setState({ isSavingAnswers: true })
      const submissions = await getSubmissionsAndAutotests(examAnswers || [])
      if (!submissions?.length) return this.setState({ isSavingAnswers: false })

      await setAsyncTimeout(async () => {
        const submissionsWithType = submissions.map(submission => {
          return { ...submission, type: 'exam' }
        })
        const examAnswerList = await getSubmissionGrades(submissionsWithType)
        this.setState({ isSavingAnswers: false })
        emitter.emit(ON_EXAM_COMPLETE, { examAnswerList })
        const isExamCompleted = isExamDone(this.context, activeSectionUUID)
        if (!isExamCompleted) {
          emitter.emit(
            ON_SECTION_DATA_PROGRESS,
            {
              key: EXAM_COMPLETE,
              sectionUUID,
              value: true,
              title,
              isMidExam: isMidExam(currentChapter)
            }
          )
          emitter.emit(
            ON_SECTION_DATA_PROGRESS,
            {
              key: EXAM_STATUS,
              sectionUUID,
              value: EXAM_STATUSES.COMPLETE
            }
          )
          const { examLockEvent, updateContext } = this.context
          examLockEvent && examLockEvent.close()
          updateContext({ examLockEvent: null })
        }
        percentage = this.getSectionPercentage()
        this.updateExamProgress({ percentage, currentSectionUUID, isRetakeAllowed })
      }, hasCodingQuestion ? 30000 : 0)
    } else {
      this.updateExamProgress({ percentage, currentSectionUUID, isRetakeAllowed })
    }
  }

  handleAllQuizCompleted () {
    const {
      activeSectionUUID,
      completeScreenData
    } = this.props
    const { studentData } = this.context
    const sectionQuizzes = completeScreenData
      .data.section_exe.quiz
    const completedQuizzes = studentData[QUIZ_SECTION]
    const isSectionQuizzesSolved = sectionQuizzes.every(quiz =>
      quiz['question_set_uuid'] in completedQuizzes)
    if (!isSectionQuizzesSolved) return
    emitter.emit(
      ON_SECTION_DATA_PROGRESS,
      {
        key: QUIZ_COMPLETE,
        sectionUUID: activeSectionUUID,
        value: true
      }
    )
    emitter.emit(ON_SECTION_PROGRESS, activeSectionUUID)

    if (config.isLesson0Flag() && isWelcomePsychologyCourse()) {
      window.parent.location.href = getExternalLinkUrl(`${OUTLIER_STUDENT_DASHBOARD_URL}/?flag-lesson0=true&lesson0Viewed=true`)
    }
  }

  getSectionPercentage () {
    const {
      QuestionSetdata: questionSetData,
      intertitial_type: interstitialType,
      completeScreenData: { chapter, data: { Question: retakeQuestions } }
    } = this.props
    const { examRetake, cohortData } = this.context
    const isRetakeAllowed = isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter
    })
    const isExam = interstitialType === 'end_content_intertitial_exam'
    const { questions } = questionSetData
    const questionData = isExam && isRetakeAllowed ? retakeQuestions : questions
    const { studentAnswers } = this.context.studentData
    let correctAns = 0

    questionData.forEach(question => {
      if (!question.Question_uuid) return
      const answer =
        studentAnswers.find(ans => ans.uuid === question.Question_uuid)
      if (answer && answer.correct === true) correctAns = correctAns + 1
    })

    const percentage =
      Math.round((correctAns * 100 / questionData.length) * 100) / 100
    return percentage
  }

  showConfetti () {
    const {
      intertitial_type: interstitialType,
      QuestionSetdata: questionSetData,
      completeScreenData,
      screenData
    } = this.props
    const {
      percentage,
      QuestionSet: {
        section_ending_uuid: sectionEndingUUID
      }
    } = this.state

    const isBelowPassingGrade = percentage < CONFETTI_PASSING_GRADE
    if (!questionSetData || isBelowPassingGrade) return false
    const confettiAllowed = ['end_content_intertitial_active', 'end_content_intertitial_exam']

    const newScreenData = screenData || {}
    const { worktype: workType } = newScreenData
    const isQuizUUID = completeScreenData?.children?.find(
      (quiz) => quiz?.uuid === sectionEndingUUID
    )
    const showQuizConfetti = workType === QUIZ || isQuizUUID
    return confettiAllowed.includes(interstitialType) || showQuizConfetti
  }

  showCompletionModal = (text, buttons) => {
    this.context.updateContext({
      modalData: {
        isCompletionModalOpen: true,
        type: 'quiz',
        text: text,
        buttons: buttons
      }
    })
  }

  renderButtons (nextPracticeQuestions) {
    const {
      intertitial_type: interstitialType,
      completeScreenData,
      sectionUUID,
      QuestionSetdata,
      activeSectionUUID
    } = this.props
    const {
      activeCourse,
      cohortData,
      isAssessmentQuizExam, examRetake, currentChapter: {
        type
      },
      isAssignmentResubmitted
    } = this.context
    const isExam = type === 'exam'
    const isRetakeAllowed = isExam && isExamRetakeType({
      examRetake,
      cohortId: cohortData?.cohortID,
      chapter: completeScreenData?.chapter
    })
    const { Question_uuid: retakeFirstQuestionUUID } = isRetakeAllowed &&
    completeScreenData?.data?.Question[0]
    const examFirstQuestionUUID = isRetakeAllowed && retakeFirstQuestionUUID
      ? retakeFirstQuestionUUID
      : QuestionSetdata.first_quiz_question

    if (!interstitialType) {
      return null
    }

    const buttons = []
    switch (interstitialType) {
      case 'end_content_intertitial_practices':
        if (QuestionSetdata.first_quiz_question) {
          return <ReviewActionLink
            dataCypress='viewQuizwithSolutionButton'
            onClick={() => {
              emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' +
                QuestionSetdata.first_quiz_question)
              emitter.emit(ON_REVIEW_MODE, true)
              window.outlierLog('Click on', isAssessmentQuizExam
                ? 'Review Quiz Answers' : 'View Quiz With Solutions')
            }}
          >
            {isAssessmentQuizExam
              ? 'Review Quiz Answers'
              : 'View Quiz With Solutions'}
          </ReviewActionLink>
        } else {
          if (nextPracticeQuestions.length) {
            buttons.push({
              onClick: () => {
                emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' +
                  nextPracticeQuestions[0])
                window.outlierLog('Click on', 'Start Another Set')
              },
              text: 'Start Another Set'
            })
          } else {
            buttons.push({
              onClick: () => {
                emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID + '/' +
                  sectionUUID + '/questions')
                window.outlierLog('Click on', 'Practice Set List')
              },
              text: 'Practice Sets'
            })
          }
          buttons.push({
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID)
              window.outlierLog('Click on', 'Section Home')
            },
            text: 'Section Home'
          })
        }
        break
      case 'end_content_intertitial_guesswork':
        buttons.push({
          dataCypress: 'sectionHomeButton',
          onClick: () => {
            emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID)
            window.outlierLog('Click on', 'Section Home')
          },
          text: 'Section Home'
        })
        break
      case 'end_content_intertitial_active':
        if (isWelcomePsychologyCourse()) return null
        buttons.push({
          dataCypress: 'sectionHomeButton',
          onClick: () => {
            emitter.emit(ON_NAVIGATE_TO, '/' + activeSectionUUID)
            window.outlierLog('Click on', 'Section Home')
          },
          text: 'Section Home'
        })
        break
      case 'end_content_intertitial_writing':
        if (isAssignmentResubmitted) {
          buttons.push({
            onClick: () => {
              emitter.emit(ON_NAVIGATE_TO, '/')
            },
            text: 'COURSE HOME'
          })
        }
        break
      case 'end_content_intertitial_exam':
        if (isHighSchoolCourse(activeCourse)) return null
        if (completeScreenData?.type === EXAM?.toLowerCase()) {
          return <ReviewActionLink
            dataCypress='viewExamwithSolutionButton'
            onClick={() => {
              emitter.emit(
                ON_NAVIGATE_TO,
                '/' + activeSectionUUID + '/' + examFirstQuestionUUID
              )
              emitter.emit(ON_REVIEW_MODE, true)
              window.outlierLog(
                'Click on',
                isAssessmentQuizExam ? 'Review Exam answers' : 'View Exam With Solutions'
              )
            }}
          >
            {isAssessmentQuizExam ? 'Review Exam answers' : 'View Exam With Solutions'}
          </ReviewActionLink>
        }
        if (!completeScreenData?.chapter?.isFinalExam && !isAssessmentQuizExam) {
          buttons.push({
            dataCypress: 'nextButton',
            onClick: () => {
              emitter.emit(ON_SKIP_SECTION, activeSectionUUID)
              window.outlierLog('Click on', 'Next Chapter')
            },
            text: 'Next'
          })
        }
        break
      default:
        break
    }

    return buttons.map(({ dataCypress, onClick, text }, i) => (
      <div key={i}>
        <CourseButton
          className={getClass(isAssessmentQuizExam, i)}
          dataCypress={dataCypress}
          onClick={onClick}
        >
          {text}
        </CourseButton>
      </div>
    ))
  }

  render () {
    const {
      intertitial_type: interstitialType,
      completeScreenData,
      sectionUUID,
      useFor
    } = this.props
    const {
      QuestionSet,
      isSavingAnswers,
      percentage
    } = this.state
    const {
      isAuditor, studentData, isStudentProgressBusy,
      isAssessmentQuizExam,
      isAssignmentResubmitted
    } = this.context

    const isActiveLearning =
      interstitialType === 'end_content_intertitial_active'

    if (isStudentProgressBusy || isSavingAnswers) return <LoadingSpinner />

    const nextPracticeQuestions = []
    if (interstitialType === 'end_content_intertitial_practices' && completeScreenData) {
      const fSectionUUID = sectionUUID + '/questions'

      if (useFor !== 'pretest') {
        const filterPracticeSet = completeScreenData.children.filter(function (el) {
          return el.uuid === fSectionUUID
        })
        if (filterPracticeSet && filterPracticeSet[0] && filterPracticeSet[0].data.length > 0) {
          const StudentContext = studentData.studentAnswers
          filterPracticeSet[0].data.forEach((ffquestion, index) => {
            const found = StudentContext.some(el => el.uuid === ffquestion['Question_uuid'])
            if (!found) {
              nextPracticeQuestions.push(ffquestion['Question_uuid'])
            }
          })
        }
      }
    }
    if (!QuestionSet) return (<div className='text-center'>Loading...</div>)
    const showConfetti = this.showConfetti()
    const isExam = interstitialType === 'end_content_intertitial_exam'
    const isWritingAssignment = interstitialType === 'end_content_intertitial_writing'
    const isFinalEssay = completeScreenData?.title === FINAL_ESSAY

    return (
      <section className='section guess-work quiz-page'>
        <div className='guess__work-main'>
          {(!isAssessmentQuizExam || isActiveLearning || isAssignmentResubmitted)
            ? <div className='guess__work-main-mid'>
              <div className='container-fluid'>
                <div className='guess__work-block mb-1'>
                  <div width='441' height='213'
                    style={{ position: 'relative', textAlign: 'center' }}>
                    {QuestionSet.intro_image &&
                      <img className='img-fluid'
                        src={QuestionSet.intro_image}
                        alt='Interstitial Intro' />}
                    {!isAuditor && (percentage || percentage === 0) &&
                      <h2 className='percentage__center'>
                        <span style={{ color: '#050f0f' }}>{'SCORE:'}</span>
                        <span className='ml-1' style={{ color: '#77bcb8' }}>
                          {percentage + '%'}
                        </span>
                      </h2>}
                  </div>
                  <div className='component__backdrop backdrop__bottom-rounded'>
                    {QuestionSet.worktype && (
                      <h2 className='guess__work-block-title'>
                        {QuestionSet.worktype}
                      </h2>
                    )}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: isAssignmentResubmitted
                          ? 'Resubmission complete! Let’s keep going...'
                          : (QuestionSet.intro_text)
                      }} />
                    <div className='text-center'>
                      {this.renderButtons(nextPracticeQuestions)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : <MainWrapper isAssessmentQuizExam={isAssessmentQuizExam}>
              <ScorePageUI isAssessmentQuizExam={isAssessmentQuizExam}>
                <Score isAssessmentQuizExam={isAssessmentQuizExam}>
                  {isFinalEssay ? 'Final Essay completed' : isWritingAssignment && 'Assignment completed'}
                  {completeScreenData?.type === EXAM?.toLowerCase() && 'Grade: '}
                  {!isAuditor && (percentage || percentage === 0) &&
                    percentage + '%'}
                </Score>
                {this.state.text
                  ? <div>{this.state.text}</div>
                  : <div>
                    You did the thing! You can return to this assessment any
                    time during the semester to use it as a study guide.
                  </div>}
                <ButtonDiv isExam={isExam} >
                  {this.renderButtons(nextPracticeQuestions)}
                </ButtonDiv>
              </ScorePageUI>
            </MainWrapper>}
          {showConfetti &&
            <div id='end_content_canvas'>
              <ConfettiCanvasComponent />
            </div>
          }
        </div>
      </section>
    )
  }
}

QuestionSetInterstitialPractices.contextType = Context
export default QuestionSetInterstitialPractices
