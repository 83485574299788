import { redirectUserToExternalLink } from '@outlier-org/lst-auth-react'
import { FIRST_EDITION_SUBDOMAIN } from '../Constants'
import { OUTLIER_BASE_DOMAIN, OUTLIER_STUDENT_DASHBOARD_URL } from '../Constants/domains'
import config from '../config'
import {
  getCourseNameByHost,
  getCourseVersionByUrl,
  isGGUCourse
} from './courseUtils'

export {
  redirectToDashboard,
  setTimeoutPromise,
  getGGUCourseLessonText,
  measureText,
  removeTableWidthProperty,
  copyWidthToFirstRowCells,
  hasTableWithUniformTdWidth,
  hasTableWithWidthPx,
  hasTableWithDifferentTdCountAndDefinedWidth,
  formatChapterLinkTags
}

// Given a string and a list of class names, it returns the height and width the
// supplied text uses in the DOM layout.
// Example usage:
// const { width } = measureText('some text', 'some-css-class other-class')
// Since styles from classes are taken into consideration, things like
// font size, margins, and padding will be included in the returned dimensions.
// Note: this cannot be tested with Jest since Jest uses jsdom which doesn't
// calculate layout. So for now there will be no tests.
function measureText (text, classNames) {
  const tempDiv = document.createElement('div')
  tempDiv.className = classNames
  tempDiv.style['visibility'] = 'hidden'
  tempDiv.style['white-space'] = 'nowrap'
  const divText = document.createTextNode(text)
  tempDiv.appendChild(divText)
  document.body.appendChild(tempDiv)

  const { height, width } = tempDiv.getBoundingClientRect()

  document.body.removeChild(tempDiv)

  return { height, width }
}

// returns a promise to wait until the state gets updated in tests
function setTimeoutPromise () {
  return new Promise(resolve => {
    setTimeout(resolve, 0)
  })
}

function redirectToDashboard () {
  redirectUserToExternalLink(`${OUTLIER_STUDENT_DASHBOARD_URL}/?courseId=${config.courseId}`)
}

function getGGUCourseLessonText ({
  lessonText, questions, sectionUUID
}) {
  const parser = new DOMParser()
  const parsedText = parser.parseFromString(lessonText, 'text/html')
  const links = parsedText.querySelectorAll('a')

  if (!links?.length) return lessonText

  links.forEach(node => {
    const isBetaLinkOfSameCourse = node.host === `${config.courseName.replace('.plus', '')}.beta.${OUTLIER_BASE_DOMAIN}`
    if (!isBetaLinkOfSameCourse) return

    if (!isGGUCourse()) {
      const newLink = `https://${config.courseName}.${FIRST_EDITION_SUBDOMAIN}.${OUTLIER_BASE_DOMAIN}/${node.hash}`
      node.setAttribute('href', newLink)
      return
    }

    const questionUUID = (node.hash?.split('/') || [])?.[2]
    const isValidQuestion = questions?.some(question => question.Question_uuid === questionUUID)
    if (!isValidQuestion) return node.removeAttribute('href')

    const newLink = `https://${config.courseName}.${OUTLIER_BASE_DOMAIN}/#/${sectionUUID}/${questionUUID}`
    node.setAttribute('href', newLink)
  })

  return parsedText.body.innerHTML
}

/**
 * @param {*} htmlString
 * @returns true
   ~ if table contains tr having equal number of td
   ~ and, all the tr are enclosed under same html element
   ~ and, alteast one of td has inline width CSS property
 */
function hasTableWithUniformTdWidth (s) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(s, 'text/html')
  const tables = doc.getElementsByTagName('table')

  // Check if there is only one table element in the document
  if (tables.length !== 1) {
    return false
  }

  const table = tables[0]
  const trs = table.getElementsByTagName('tr')
  const tdCount = trs[0].getElementsByTagName('td').length

  // Check if all tr elements are enclosed under a single parent and has same number of td elements
  const parent = trs[0].parentNode
  for (let i = 1; i < trs.length; i++) {
    if (trs[i].parentNode !== parent ||
      trs[i].getElementsByTagName('td').length !== tdCount) {
      return false
    }
  }

  // Check if at least one td element has an inline width CSS property
  for (let i = 0; i < trs.length; i++) {
    const tds = trs[i].getElementsByTagName('td')
    for (let j = 0; j < tds.length; j++) {
      if (tds[j].style.width) {
        return true
      }
    }
  }

  return false
}

/**
 * @param {*} htmlString
 * @returns modified htmlString
   ~ if table contains tr having equal number of td
   ~ and, all the tr are enclosed under same html element
   ~ and, alteast one of td has inline width CSS property
   ~ copies width property to td's of first tr
 */
function copyWidthToFirstRowCells (htmlString) {
  if (!hasTableWithUniformTdWidth(htmlString)) return htmlString

  const div = document.createElement('div')
  div.innerHTML = htmlString.trim()

  const table = div.querySelector('table')
  const rows = table.querySelectorAll('tr')
  const firstRowCells = rows[0].querySelectorAll('td')

  const tdWidths = []
  for (let i = 1; i < rows.length; i++) {
    const tds = rows[i].querySelectorAll('td')
    if (tds.length !== firstRowCells.length) {
      console.error('Error: Rows do not have the same number of cells')
      return htmlString
    }

    for (let j = 0; j < tds.length; j++) {
      const td = tds[j]
      const width = td.style.width
      if (width) {
        tdWidths[j] = width
      }
    }
  }

  for (let i = 0; i < firstRowCells.length; i++) {
    const width = tdWidths[i]
    if (width) {
      firstRowCells[i].style.width = width
    }
  }

  return div.innerHTML
}

/**
 * @param {*} htmlString
 * @returns true
   ~ if html string contains table having inline css width property in px
 */
function hasTableWithWidthPx (htmlString) {
  const regex = /<table[^>]*style="[^"]*width:\s*\d+px[^"]*"[^>]*>.*?<\/table>/is
  return regex.test(htmlString)
}

/**
 * @param {*} htmlString
 * @returns return true
   ~ if table contains tr having different number of td
   ~ and, there should be one tr which have all the td with defined width
 */
function hasTableWithDifferentTdCountAndDefinedWidth (html) {
  if (!hasTableWithWidthPx(html)) return false

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const tables = doc.getElementsByTagName('table')

  if (tables.length !== 1) {
    return false
  }

  const rows = tables[0].getElementsByTagName('tr')
  let hasDifferentCellCount = false
  let hasCellsWidthProperty = false
  let previousColumnsCount

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const columns = row.querySelectorAll('td')
    const columnsCount = columns.length

    if (previousColumnsCount && previousColumnsCount !== columnsCount) {
      hasDifferentCellCount = true
    }

    previousColumnsCount = columnsCount

    let widthCount = 0
    for (let j = 0; j < columnsCount; j++) {
      const column = columns[j]
      const style = column.getAttribute('style')

      if (style && style.includes('width')) {
        widthCount += 1
      }
    }

    if (widthCount === columnsCount) {
      hasCellsWidthProperty = true
    }

    if (hasDifferentCellCount && hasCellsWidthProperty) {
      return true
    }
  }

  return false
}

/**
 * @param {*} htmlString
 * @returns modified htmlString
   ~ if a htmlString contains table with width and,
   returns true for hasTableWithDifferentTdCountAndDefinedWidth
   than remove the inline css width property from the table
 */
function removeTableWidthProperty (htmlString) {
  if (!hasTableWithDifferentTdCountAndDefinedWidth(htmlString)) return htmlString

  const regex = /(<table[^>]*style="[^"]*?)width:\s*\d+px([^"]*"[^>]*>.*?<\/table>)/gis
  const replaceString = '$1$2'
  return htmlString.replace(regex, replaceString)
}

function formatChapterLinkTags (lessonText, guessworkComplete) {
  const parser = new DOMParser()
  const parsedText = parser.parseFromString(lessonText, 'text/html')
  const links = parsedText.querySelectorAll('a')

  if (!links?.length) return lessonText

  links.forEach(link => {
    const isChapter = link.innerHTML?.includes('Chapter')
    if (!isChapter) return

    const chapterUuid = link.hash.split('/')[1]
    const guessworkProgress = Object.keys(guessworkComplete)
    if (guessworkProgress.includes(chapterUuid)) return

    const newSpan = document.createElement('span')
    newSpan.textContent = link.innerHTML
    link.replaceWith(newSpan)
  })
  return parsedText.body.innerHTML
}

export function getTableColumnRowSpanStatus (tableElement) {
  const firstColumnTdCells = tableElement.querySelectorAll('td:first-child')
  const firstRowColumnTdCells = tableElement.querySelectorAll('tbody:first-child tr:first-child td')

  const firstColumnThCells = tableElement.querySelectorAll('th:first-child')
  const firstRowColumnThCells = tableElement.querySelectorAll('tr:first-child th')

  const firstColumnCells = [...firstColumnTdCells, ...firstColumnThCells]
  const firstRowColumnCells = [...firstRowColumnTdCells, ...firstRowColumnThCells]

  const firstRowSpanSingleColumn = Array.from(firstRowColumnCells).every((cell) => {
    return cell.colSpan === 1
  })

  const firstColumnSpanSingleRow = Array.from(firstColumnCells).every((cell) => {
    return cell.rowSpan === 1
  })

  return {
    firstRowSpanSingleColumn,
    firstColumnSpanSingleRow
  }
}

export const replaceInternalLinks = (htmlString) => {
  if (!htmlString) return htmlString

  const currentUrl = window.location.host // 'test.ext.outlier.org'
  const currentCourseName = getCourseNameByHost(currentUrl)
  const currentCourseVersion =
    getCourseVersionByUrl(currentUrl, currentCourseName)

  const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=("|')(.*?)\1/gi

  const updatedHtml = htmlString.replace(anchorRegex, (match, quote, href) => {
    const parsedHref = new URL(href, window.location.href)
    const courseName = getCourseNameByHost(parsedHref.hostname)
    if (courseName !== currentCourseName) return match // Not an internal link, return the original anchor tag

    const courseVersion = getCourseVersionByUrl(parsedHref.href, courseName)
    if (courseVersion === currentCourseVersion) return match // Same course version, return the original anchor tag

    const newHref = parsedHref.href.replace(courseVersion, currentCourseVersion)
    return match.replace(href, newHref)
  })

  return updatedHtml
}

export const removeLinksFromOtherSections = (htmlString, currentSection) => {
  if (!htmlString) return htmlString
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const links = doc.querySelectorAll('a')
  if (!links?.length) return htmlString

  const currentUrl = window.location.host // 'test.ext.outlier.org'
  const currentCourseName = getCourseNameByHost(currentUrl)

  links.forEach(link => {
    const linkCourseName = getCourseNameByHost(link.host)
    if (linkCourseName !== currentCourseName) return

    const linkSection = link.hash.split('/')[1]
    if (linkSection === currentSection) return

    const newSpan = document.createElement('span')
    newSpan.textContent = link.innerHTML
    link.replaceWith(newSpan)
  })

  return doc.body.innerHTML
}

export const appendQueryParamsToLink = inputParams => {
  const {
    htmlString, host = 'www.coursera.org', queryParams
  } = inputParams || {}
  if (!htmlString) return htmlString

  const parser = new DOMParser()
  const parsedText = parser.parseFromString(htmlString, 'text/html')
  const links = parsedText.querySelectorAll('a')

  if (!links?.length) return htmlString

  links.forEach(node => {
    const { host: nodeHost, origin, pathname, search } = node || {}
    if (nodeHost !== host) return

    const params = new URLSearchParams(search)
    Object.entries(queryParams || {})
      .forEach(([key, value]) => {
        params.append(key, value)
      })
    node.setAttribute('href', `${origin}${pathname}?${params.toString()}`)
  })

  return parsedText.body.innerHTML
}

export const isMobile = () => window.matchMedia('(max-width: 575px)').matches
